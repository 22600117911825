<template>
  <div>
    <Header-Back pageTitle="Create Admin"> </Header-Back>

    <adminPermissionFrom :form="form"></adminPermissionFrom>
    <el-button type="success" @click="onCreate">Create</el-button>
  </div>
</template>

<script>
import { addAdmins } from "@/api/user";

import adminPermissionFrom from "./components/adminPermissionFrom";
export default {
  components: { adminPermissionFrom },
  data() {
    return {
      form: {
        name: null,
        email: null,
        roleId: null,
        newPassword: null,
        confirmNewPassword: null,
        sectionIds: [],
      },
    };
  },
  methods: {
    onCreate(){
      this.addAdmins()
    },
    addAdmins() {
      if(this.form.roleId == 1){
        this.form.sectionIds = []
      }
      addAdmins(this.form).then(() => {
         this.$message({
          message: 'add success!',
          type: 'success'
        })
        this.$router.push({path:'/adminPermission'})
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>