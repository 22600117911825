<template>
  <div class="content">
    <div class="security-box">
      <p class="work-text">Work Safety Alert</p>
      <p class="backend-text">Backend Portal</p>
      <el-form
        label-position="top"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        :model="formLabelAlign"
      > 
        <p class="resetPassword-text">Forgot Password</p>
        <div class="description-text">Please enter your email address so we can send you an email to reset your password. </div>
        <el-form-item label="">
          <el-input v-model="formLabelAlign.email" placeholder="Email"></el-input>
        </el-form-item>
       
        <el-form-item>
          <el-button type="primary" @click="onresetPasswordButtonClick">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
    <loadingDialog :center-dialog-visible="centerDialogVisible"></loadingDialog>
  </div>
</template>

<script>
import { resetPassword } from "@/api/security";
import loadingDialog from "@/components/loadingDialog/index";
export default {
  components: { loadingDialog },
  data() {
    return {
      centerDialogVisible: false,
      formLabelAlign: {
        email: "",
      },
      rules: {},
    };
  },

  methods: {
    onresetPasswordButtonClick() {
      this.resetPassword();
    },
    resetPassword() {
      this.centerDialogVisible = true;
      let form = {
        email: this.formLabelAlign.email,
      };
      resetPassword(form)
        .then(() => {
         this.$router.push({ path: "/overview" });
        })
        .finally(() => {
          this.centerDialogVisible = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  min-height: 100% !important;
  background-color: #f1f8f9;
}
.security-box {
  width: 600px;
}
.security-box p {
  text-align: center;
}
.el-form {
  width: 100%;
  display: block;
  background-color: #fff;
  padding: 20px;
}
.el-form-item {
  text-align: center;
  color: #fff !important;
}
::v-deep {
  .el-form--label-top .el-form-item__label {
    color: #fff !important;
  }
  .el-button--primary {
    width: 100%;
  }
}

.logo-img {
  display: block;
  width: 368px;
  margin-left: 174px;
}
.work-text{
color: var(--Main, #124B80);
text-align: center;
/* H1 */
font-family: Arial;
font-size: 34px;
font-style: normal;
font-weight: 700;
}
.backend-text{
  color: var(--black, #000);
text-align: center;
/* H2 */
font-family: Arial;
font-size: 24px;
font-style: normal;
font-weight: 700;
}
.resetPassword-text{
  color: var(--black, #000);
text-align: center;
/* H1 */
font-family: Arial;
font-size: 34px;
font-style: normal;
font-weight: 700;
}
.forgot-text{
display: inline-block;
color: var(--Secondary, #1A74B0);
/* Link */
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
margin-bottom: 20px;
border-bottom: 1px solid var(--Secondary, #1A74B0);
cursor: pointer;
}
.description-text{
    color: var(--Black, #000);
/* P */
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
text-align: center;
}
</style>