<template>
  <div class="import-edit">
    <Header-Back :pageTitle="ruleForm.title_en">
      <div class="last-updated">Last Updated {{ ruleForm.updated_at }}</div>
    </Header-Back>

    <div class="tab-nav">
      <div :class="['nav', importCate == 0 && 'select-nav']" @click="importCate = 0">Content</div>
      <div :class="['nav', importCate == 1 && 'select-nav']" @click="importCate = 1">Gallery</div>
    </div>

    <Content v-if="importCate == 0 && ruleForm.id" :form="ruleForm" ref="content" ></Content>
    <Gallery v-if="importCate == 1 && ruleForm.id" :obj="ruleForm.galleries" ref="gallery"></Gallery>
  </div>
</template>

<script>
import Content from './components/detail/content.vue'
import Gallery from './components/detail/gallery.vue'
import { getImportantAnnouncementsDetail } from '@/api/material'

export default {
  components: {
    Content,
    Gallery
  },
  data() {
    return {
      importCate: 0,

      ruleForm: {}
    }
  },
  methods: {
    getData() {
      let id = this.$route.params.id
      getImportantAnnouncementsDetail(id).then(v => {
        let data = v.data
        this.ruleForm = {
          id: data.id, 
          title_en: data.title.en,
          title_zhHant: data.title.zhHant,
          title_zhCn: data.title.zhCn,
          date: data.date,
          content_en: data.content.en,
          content_zhHant: data.content.zhHant,
          content_zhCn: data.content.zhCn,
          push_message: data.isPushToUser,
          push_message_en: data.pushMessage.en,
          push_message_zhHant: data.pushMessage.zhHant,
          push_message_zhCn: data.pushMessage.zhCn,
          updated_at: new Date(data.updatedAt).format('yyyy-MM-dd hh:mm:ss'),
          documents: [...data.documents.map(its => {
            return {
              type: its.type,
              link_en: its.link.en,
              link_zhHant: its.link.zhHant,
              link_zhCn: its.link.zhCn,
            }
          })],
          galleries: []
        }

        let newArr = [...data.galleryVideos, ...data.galleryPhotos]
        .sort((a, b) => a.displaySort - b.displaySort)
        newArr.filter(its => {
          if(its.videoLink != undefined) {
            this.ruleForm.galleries.push({
              image_url_en: its.imageUrl.en,
              image_url_zhHant: its.imageUrl.zhHant,
              image_url_zhCn: its.imageUrl.zhCn,
              link_en: its.videoLink.en,
              link_zhHant: its.videoLink.zhHant,
              link_zhCn: its.videoLink.zhCn,
              transcript_en: its.transcriptUrl.en,
              transcript_zhHant: its.transcriptUrl.zhHant,
              transcript_zhCn: its.transcriptUrl.zhCn,
              description_en: its.description.en,
              description_zhHant: its.description.zhHant,
              description_zhCn: its.description.zhCn,
              display_sort: its.displaySort,
              type: 'video'
            })
          } else {
            this.ruleForm.galleries.push({
              image_url_en: its.imageUrl.en,
              image_url_zhHant: its.imageUrl.zhHant,
              image_url_zhCn: its.imageUrl.zhCn,
              description_en: its.description.en,
              description_zhHant: its.description.zhHant,
              description_zhCn: its.description.zhCn,
              display_sort: its.displaySort,
              type: 'photo'
            })
          }
        })
        console.log(this.ruleForm)
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.tab-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
  
  .nav {
    width: calc(50% - .3125rem);
    padding: .625rem;
    text-align: center;
    border-bottom: 3px solid #F0F0EF;
    font-size: 22px;
    font-weight: 700;
    color: #6D6D6D;
    cursor: pointer;
  }

  .select-nav {
    border-bottom-color: #124B80;
    color: #124B80;
  }
}
</style>