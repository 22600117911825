import http from '@/utils/http'

export function getPages(){
    return http.get('/pages')
}
export function getPagesDetail(id){
    return http.get(`/pages/${id}`)
}
export function editPages(id,data){
    return http.put(`/pages/${id}`,data)
}
