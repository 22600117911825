<template>
  <div>
      <div class="page-label">{{newValItem.type | interstitialAdsType}}</div>
      <div class="page-content">
        <el-row type="flex" align="middle" :gutter="10">
          <el-col :span="4" class="page-content-col">
             <div class="image-slot-box">
              <el-image :src="newValItem.imageUrl"> 
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <upload v-if="isEdit" style="margin-right: 20px; margin-top:20px;" @childByValue="childByValueImage" :isIcon="true"></upload>
          </el-col>
          <el-col :span="20">
            <div class="page-content-navigate">ZIP file:</div>
            <div  class="automatic">{{newValItem.link | getZipFileName}}</div>
            <div class="page-content-button">
              <upload v-if="isEdit" style="margin-right: 20px;" @childByValue="childByValue" :isIcon="true"></upload>
            </div>
            <div class="button-list textAr">
                 <el-button type="primary" v-if="!isEdit"  @click="onEdit">Edit</el-button>
                 <el-button type="success" v-if="!isEdit"  @click="onPreview(newValItem.link)">Preview</el-button>
                 <el-button type="danger"  v-if="isEdit" @click="onClear">Clear</el-button>
                 <el-button type="info" v-if="newValItem.status==0" @click="onDisable">Disable</el-button>
                 <el-button type="primary" v-else @click="onDisable">Enable</el-button>
                 <el-button type="success" v-if="isEdit" @click="onSave">Save</el-button>
                 <el-button v-if="isEdit" @click="onCancel">Cancel</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
import {editInterstitialAds,disableInterstitialAds,enableInterstitialAds} from "@/api/interstitialAds";
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
  props:{
    item:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    item:{
      handler(newVal){
        if(newVal){
          this.newValItem = newVal
        }
      },
      deep:true,
      immediate:true
    }
  },
  data() {
    return {
      newValItem:{},
      isEdit:false,
    };
  },
  methods:{
    onClear(){
      // this.newValItem ={
      //   imageUrl:"",
      //   link:"",
      // }
       this.newValItem.imageUrl = ''
       this.newValItem.link = ''
    },
    onPreview(link){
      window.open(link)
    },
    onDisable(){  
      if(this.newValItem.status == 0){
       this.disableInterstitialAds()
      } else {
       this.enableInterstitialAds()
      }
    },
    childByValueImage(value){
      this.newValItem.imageUrl = value
      this.$forceUpdate()
    },
    childByValue(value){
      this.newValItem.link = value
      this.$forceUpdate()
    },
    onEdit(){
      this.isEdit = true;
    },
    onCancel(){
      this.isEdit = false;
      this.$emit('Cancel')
    },
    onSave(){
       this.editInterstitialAds()
    },
    editInterstitialAds(){
      editInterstitialAds(this.newValItem.id,this.newValItem).then(()=>{
        this.$message({
          message: "edit successfully",
          type: "success",
        });
        this.isEdit = false;
        this.$emit('Cancel')
      })
    },
    disableInterstitialAds(){
      disableInterstitialAds(this.newValItem.id).then(()=>{
        this.$message({
          message: " disable successfully",
          type: "success",
        });
        this.$emit('Cancel')
      })
    },
    enableInterstitialAds(){
      enableInterstitialAds(this.newValItem.id).then(()=>{
      this.$message({
                message: "enable successfully",
                type: "success",
              });
        this.$emit('Cancel')
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-list {
  margin: 40px 0;
}
.page-label {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
  
}
.page-content {
  background: #f5f5f5;
  padding: 20px;
}
.image-slot {
  display: flex;
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #6d6d6d;
  background: #6d6d6d;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.page-content-navigate{
color: #000;
font-size: 18px;
font-style: normal;
font-weight: 700;
margin-bottom: 10px;
}
.page-content-lint{
color: #1A74B0;
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
}
.page-content-button{
  display: flex;
  margin-top: 40px;
}
.page-content-image{
  width: 100%;
}
.page-content-col{
position: relative;
}
.page-content-img{
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.button-list{
  margin-top: 20px;
}
.textAr{
  text-align: right;
}
.image-slot-box{
  display: flex;
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #6d6d6d;
  background: #6d6d6d;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
</style>