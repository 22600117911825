<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Notices - Accessibility Statement">
      
    </Header-Back>
    <div class="disclaimer-box">
      <div class="column-title">Content</div>
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
      <el-form-item label="English" style="width: 85%">
          <Tinymce v-model="formLabelAlign.name"></Tinymce>
      </el-form-item>
      <el-form-item label="Traditional Chinese" style="width: 85%">
          <Tinymce v-model="formLabelAlign.region"></Tinymce>
      </el-form-item>
      <el-form-item label="Simplified Chinese" style="width: 85%">
          <Tinymce v-model="formLabelAlign.type"></Tinymce>
      </el-form-item>
      </el-form>
    </div>
    <el-button  type="success">Save</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
       formLabelAlign: {
          name: 'Artificial light, night shifts and social jet lag can disrupt our body clocks, affecting our mood, sleep, alertness and appetite.',
          region: '人造光、夜班和社會時差會擾亂我們的生理時鐘，影響我們的情緒、睡眠、警覺性和食慾。',
          type: '人造光、夜班和社会时差会扰乱我们的生物钟，影响我们的情绪、睡眠、警觉性和食欲。'
      },
      formInline: {
        name: "",
        region: "",
        type: "",
      },
    };
  },
}
</script>

<style lang="scss" scoped>
.disclaimer-box{
  padding-top:30px;
}
.page-img-button{
  margin: 0 1px;
}
.el-pagination {
  padding-top: 20px;
  padding-block: 20px;
  text-align: center; 
}
.save-button {
  padding: 20px 150px;
  background-color: #184c84;
}
.input-class {
  width: 490px;
}
</style>