<template>
  <div class="print">
    <form method="post" name="form1">
      <table border="1" cellspacing="0" align="center" valign="center">
        <tr>
          <td colspan="2" align="center"><h3>(ID: {{ form.id }}) WSA-Text Version</h3></td>
        </tr>
        <tr>
          <td colspan="2" align="center">
            <input type="button" @click="print" value="Print This Page">
          </td>
        </tr>

        <!-- title -->
        <tr id="module-name">
          <td colspan="2"> Title </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value"> {{ form.titleEn }} </td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value"> {{ form.titleZhHant }} </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value"> {{ form.titleZhCn }} </td>
        </tr>

        <!-- Date of Accident -->
        <tr id="module-name">
          <td colspan="2"> Date of Accident </td>
        </tr>
        <tr>
          <td class="label"> Date of Accident :</td>
          <td class="value"> {{ form.accidentDate.substring(0, 7) }} </td>
        </tr>

        <!-- Place of Accident -->
        <tr id="module-name">
          <td colspan="2"> Place of Accident </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value"> {{ form.placeOfAccidentEn }} </td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value"> {{ form.placeOfAccidentZhHant }} </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value"> {{ form.placeOfAccidentZhCn }} </td>
        </tr>

        <!-- Summary -->
        <tr id="module-name">
          <td colspan="2"> Summary </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value" v-html="form.summaryEn"></td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value" v-html="form.summaryZhHant"></td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value" v-html="form.summaryZhCn"></td>
        </tr>

        <!-- Work Safety Alert for Contractors/Proprietors/Employers -->
        <tr id="module-name" style="--color: #fde0d0">
          <td colspan="2"> Work Safety Alert for Contractors/Proprietors/Employers </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value" v-html="form.wsaEn"></td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value" v-html="form.wsaZhHant"></td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value" v-html="form.wsaZhCn"></td>
        </tr>

        <!-- Reference -->
        <tr id="module-name" style="--color: #d8e7f4">
          <td colspan="2"> Reference </td>
        </tr>
        <tbody v-for="(its, i) in form.references" :key="i">
          <tr id="module-name" v-if="i != 0">
            <td colspan="2"></td>
          </tr>
          <tr>
            <td class="label"> Title - EN :</td>
            <td class="value"> {{ its.titleEn }} </td>
          </tr>
          <tr>
            <td class="label"> Title - TC :</td>
            <td class="value"> {{ its.titleZhHant }} </td>
          </tr>
          <tr>
            <td class="label"> Title - SC :</td>
            <td class="value"> {{ its.titleZhCn }} </td>
          </tr>
          <tr>
            <td class="label"> Link - EN :</td>
            <td class="value"> {{ its.linkEn }} </td>
          </tr>
          <tr>
            <td class="label"> Link - TC :</td>
            <td class="value"> {{ its.linkZhHant }} </td>
          </tr>
          <tr>
            <td class="label"> Link - SC :</td>
            <td class="value"> {{ its.linkZhCn }} </td>
          </tr>
          <tr>
            <td class="label"> Sorting Order :</td>
            <td class="value"> {{ i + 1 }} </td>
          </tr>
        </tbody>

        <!-- Push Notification and Share Message -->
        <tr id="module-name" style="--color: #ddecd5">
          <td colspan="2"> Push Notification and Share Message </td>
        </tr>
        <tr>
          <td class="label"> Push to user :</td>
          <td class="value">
            <label>
              <input type="checkbox" id="checkbox1">
              <span class="custom-checkbox" :style="`--cte: ${form.isPushToUser ? '\'\\2713\'' : ''}`"></span> 
            </label>
          </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value"> {{ form.pushMessageEn }} </td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value"> {{ form.pushMessageZhHant }} </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value"> {{ form.pushMessageZhCn }} </td>
        </tr>

        <!-- Links -->
        <tr id="module-name" style="--color: #fff0c7">
          <td colspan="2"> Link(s) </td>
        </tr>
        <tbody v-for="(its, index) in form.link" :key="index">
          <tr>
            <td class="label"> Type of Link :</td>
            <td class="value"> {{ types[its.type] }} </td>
          </tr>
          <tr>
            <td class="label"> EN :</td>
            <td class="value"> {{ its.en }} </td>
          </tr>
          <tr>
            <td class="label"> TC :</td>
            <td class="value"> {{ its.zhHant }} </td>
          </tr>
          <tr>
            <td class="label"> SC :</td>
            <td class="value"> {{ its.zhCn }} </td>
          </tr>
        </tbody>

      </table>
        <div style="width: 854px; text-align: right; margin: auto">Print date and time: {{ time }} </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      types: ['PDF', 'External Link', 'Photo', 'Video'],
      form: {
        title: {},
        summary: {}
      },

      time: ''
    }
  },
  methods: {
    getCurTime() {
      let date = new Date(),
      Y = date.getFullYear(),
      M = date.getMonth() + 1,
      D = date.getDate(),
      h = date.getHours(),
      m = date.getMinutes()

      M = this.singleConvertDouble(M)
      D = this.singleConvertDouble(D)
      h = this.singleConvertDouble(h)
      m = this.singleConvertDouble(m)

      this.time = `${D}/${M}/${Y} ${h}:${m}`;
    },
    listenerTime() {
      setInterval(() => {
        this.getCurTime()
      }, 1000)
    },
    singleConvertDouble(char) {
      char = String(char)
      if(char.length == 1) {
        return '0' + char
      }
      return char
    },
    print() {
      window.print()
    }
  },
  created() {
    this.form = JSON.parse(localStorage.getItem('printData'))
  },
  mounted() {
    this.getCurTime()
    this.listenerTime()
  }
}
</script>

<style lang="scss" scoped>
.print {
  padding-bottom: 50px;
}
table {
  margin-top: 40px; 
  margin-bottom:20px;
  width: 854px;
}
#module-name {
  background-color: var(--color, #c7c2c2);
}

.label {
  width: 150px;
}

.colon {
  width: 10px;
}

.value {
  width: 717px;
  overflow-wrap: anywhere;
}

.order {
  color: #f00;
}

/* 隐藏默认复选框 */
input[type="checkbox"] {
  display: none;
}

/* 自定义复选框的外观 */
.custom-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  // border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  box-sizing: border-box;
  text-align: center;
}

/* 复选框选中状态的样式 */
.custom-checkbox.checked {
  background-color: #007bff;
}

/* 复选框内的勾选标记 */
.custom-checkbox::after {
  content: var(--cte);
  font-size: 16px;
  color: #333;
  display: block;
  text-align: center;
  line-height: 18px;
}

.hide-icon:after {
  content: '';
}
</style>