<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Account">
     
    </Header-Back>
    <div class="column-title">Profile</div>
    <div class="box">
      <el-form ref="form" label-position="top" :model="userInfo" label-width="80px">
        <el-form-item label="Name"> {{userInfo.username}} </el-form-item>
        <el-form-item label="Email"> {{userInfo.email}} </el-form-item>
        <el-form-item label="Role">
          <p v-for="(item,index) in userInfo.positions" :key="index">
            {{item | position}}</p>
        </el-form-item>
      </el-form>
    </div>
    <div class="column-title">Change Password</div>
    <div class="box">
      <el-form ref="form" label-position="top" :model="form"  label-width="80px">
        <el-form-item label="Current Password">
          <el-input v-model="form.currentPassword" placeholder="Please enter current password"></el-input>
        </el-form-item>
        <el-form-item label="New Password">
          <el-input show-password auto-complete="new-password" v-model="form.newPassword" placeholder="Please enter new password"></el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password">
          <el-input show-password auto-complete="new-password" v-model="form.confirmNewPassword" placeholder="Please re-enter new password"></el-input>
        </el-form-item>
        <el-button type="success" @click="onSubmit">Submit</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/utils/auth";
import {changePassword} from '@/api/user'
export default {
  data() {
    return {
      userInfo:{},
      form: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
    };
  },
  created(){
    this.userInfo = getUserInfo('userInfo')
  },
  methods: {
    onSubmit() {
      this.changePassword()
    },
    changePassword(){
      changePassword(getUserInfo('userInfo').id,this.form).then(()=>{
         this.$message({
          type: 'success',
          message: 'change Successfully !'
        });
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  padding: 30px;
  background: #f5f5f5;
  margin-bottom: 40px;
}
.column-title {
  margin-bottom: 15px;
}

</style>