<template>
  <div class="headerBack">
    <div class="back" :style="{visibility: hideBack && 'hidden'}">
      <img src="@/assets/images/header-back.png" alt="" @click="backPage">
      <span @click="backPage">{{ backText }}</span>
    </div>

    <div class="page-title">
      <div class="">{{ pageTitle }}</div>
      <div class="func">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backText: {
      type: String,
      default: () => 'Back'
    },
    hideBack: {
      type: Boolean,
      default: () => false
    },
    pageTitle: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    backPage() {
      if(!this.hideBack) this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.headerBack {
  .back {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .page-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #000;
      font-size: 34px;
      font-weight: 700;
    }

    .func {
      display: flex;
      align-items: center;
      
      & > * {
        margin-left: 10px;
      }
    }
  }
}
</style>