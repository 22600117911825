
import Cookies from 'js-cookie'

const key = 'adminAuthorization'
// const storagekey = 'admin_user_info'

export function setUserInfo(storagekey, userinfo) {
  return localStorage.setItem(storagekey, JSON.stringify(userinfo))
}

export function getUserInfo(storagekey) {
  return JSON.parse(localStorage.getItem(storagekey))
}

export function removeUserInfo(storagekey) {
  return localStorage.removeItem(storagekey)
}
export function removeAll() {
  return localStorage.clear();
}

export function setToken(token) {
  return Cookies.set(key, token)
}

export function getToken() {
  return Cookies.get(key)
}

export function removeToken() {
  return Cookies.remove(key)
}
