<template>
  <div>
    <Header-Back pageTitle="New"></Header-Back>

    <Form ref="form" :form="ruleForm"></Form>

    <div class="page-button">
      <el-button type="primary" class="save-button-size" @click="save">Save</el-button>
    </div>
  </div>
</template>

<script>
import Form from './components/form.vue';
import { addSystemicSafetyAlerts } from "@/api/material.js";

export default {
  components: {
    Form
  },
  data() {
    return {
      ruleForm: {
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        document_number: '',
        date: "",
        push_message: false,
        push_message_en: '[Systemic Safety Alert]',
        push_message_zhHant: '[系統性的安全警示]',
        push_message_zhCn: '[系统性的安全警示]',
        pdf_en: '',
        pdf_zhHant: '',
        pdf_zhCn: '',
      }
    };
  },

  methods: {
    save() {
      let child = this.$refs.form,
      { ruleForm } = child,
      // let { ruleForm } = this.$refs.form
      form = {
        // page_id: Number(this.$$router.params.pageId),
        "page_id": this.$route.params.pageId,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn,
        },
        "document_number": ruleForm.document_number,
        "date": new Date(ruleForm.date).format('yyyy-MM-dd'),
        "is_push_to_user": ruleForm.push_message,
        "push_message": {
          "en": ruleForm.push_message_en,
          "zh_hant": ruleForm.push_message_zhHant,
          "zh_cn": ruleForm.push_message_zhCn,
        },
        "pdf": {
          "en": ruleForm.pdf_en,
          "zh_hant": ruleForm.pdf_zhHant,
          "zh_cn": ruleForm.pdf_zhCn,
        },
      }
      // console.log(ruleForm.push_message)

      console.log(form)
      child.$refs['ruleForm'].validate((valid) => {
        if(valid) {
          addSystemicSafetyAlerts(form).then(() => {
            this.$router.go(-1)
          })
        }
      })
      // addSystemicSafetyAlerts(form).then(() => {
      //   this.$router.go(-1)
      // })
    }
  },
  
  mounted() {
    console.log( this.$route.params.pageId)
  }
}
</script>

<style lang="scss" scoped>

</style>