<template>
  <div>
    <el-form ref="ruleForm" label-position="top"
      :model="ruleForm" :rules="rules" label-width="80px">
      <div class="column-title">Title</div>
        <template v-for="(item, index) in languages">
          <el-form-item :label="setLabel(item)" :key="index" :prop="item">
            <el-input type="textarea" :rows="6"
              v-model="ruleForm[item]"></el-input>
          </el-form-item>
        </template>
      <el-divider></el-divider>
      <div class="column-title">Thumbnail</div>
      <el-form-item prop="thumbnail">
        <div class="logo">
          <img :src="ruleForm.thumbnail" class="image" v-if="ruleForm.thumbnail" alt="">
          <div class="image" v-else></div>
          <div class="logo-info">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 282px(H) x 504px(W)</span>
            <!--  v-if="ruleForm.img" -->
            <el-button type="primary" @click="isUpdateFile">Browse</el-button>
          </div>
        </div>
        
        <div class="browse" v-if="browse_switch && ruleForm.thumbnail" @click="browse_switch = false">
          <img :src="ruleForm.thumbnail" alt="">
        </div>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Video Upload</div>
      <div class="div-item">
        <el-form-item label="English" prop="english">
          <el-input v-model="ruleForm.english"></el-input>
        </el-form-item>
      </div>
      <div class="div-item">
        <el-form-item label="Chinese" prop="chinese">
          <el-input v-model="ruleForm.chinese"></el-input>
        </el-form-item>
      </div>
      <div class="div-item">
        <el-form-item label="Hindi" prop="hindi">
          <el-input v-model="ruleForm.hindi"></el-input>
        </el-form-item>
      </div>
      <div class="div-item">
        <el-form-item label="Nepali" prop="nepali">
          <el-input v-model="ruleForm.nepali"></el-input>
        </el-form-item>
      </div>
      <div class="div-item">
        <el-form-item label="Tagalog" prop="tagalog">
          <el-input v-model="ruleForm.tagalog"></el-input>
        </el-form-item>
      </div>
      <div class="div-item">
        <el-form-item label="Urdu" prop="urdu">
          <el-input v-model="ruleForm.urdu"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'

export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      ruleForm: {
      },
      rules: {
        'en': [
          { required: true, message: 'Please Enter  English', trigger: 'blur' },
        ],
        'zhHant': [
          { required: true, message: 'Please Enter Traditional Chinese', trigger: 'blur' },
        ],
        'zhCn': [
          { required: true, message: ' Please Enter Simplified Chinese', trigger: 'blur' },
        ],
        // 'thumbnail': [
        //   { required: true, message: 'Please choose  Thumbnail', trigger: 'blur' },
        // ],
        // 'english': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
        // 'chinese': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
        // 'hindi': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
        // 'nepali': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
        // 'tagalog': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
        // 'urdu': [
        //   {type: 'url', message: `Please enter the correct one`, trigger: 'blur'},
        // ],
      },

      browse_switch: false
    }
  },
  methods: {
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
      if(checked)
        this.ruleForm.test.push(data.label)
      else 
        this.ruleForm.test.splice(0, 1)
    },
    setLabel(lan) {
      switch (lan) {
        case "en":
          return "English";
        case "zhHant":
          return "Traditional Chinese";
        case "zhCn":
          return "Simplified Chinese";
        default:
          return "";
      }
    },
    isUpdateFile() {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = { width: 504, height: 282 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        this.ruleForm['thumbnail'] = res
      })
    }
  },
  created() {
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
    console.log(this.ruleForm)
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
}
.logo {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .image {
    width: 284px;
    height: 188px;
    // border: 1px solid #6D6D6D;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    .icon {
      width: 2.5rem;
      height: auto;
      transform: rotateZ(45deg);
    }
  }

  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}

.browse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .4);

  img {
    max-height: 50%;
  }
}
</style>