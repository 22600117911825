<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="What’s New">
      <exportExcel path="news" :tableLabels="tableLabels" fileName="What’s New" :labels="exportNews" buttonTitle="Export"></exportExcel>
      <el-button type="primary" @click="goToAdd" icon="el-icon-plus">New</el-button>
    </Header-Back>

    <el-form
      label-position="top"
      :model="formLabelAlign"
      class="demo-form-inline"
    > 
     <el-row type="flex" :gutter="20">
      <el-col :span="18"> 
        <el-form-item label="Search">
        <el-input v-model="formLabelAlign.likeKeywords" placeholder="Keywords"></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="10">
         <el-form-item label="Date">
          <el-date-picker
            v-model="formLabelAlign.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Select date">
          </el-date-picker>
         </el-form-item>
      </el-col>
     </el-row>
    </el-form>
    <el-table :data="news" style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column width="500" prop="title" label="Title">
        <template slot-scope="scope">
          <span v-if="scope.row.title">{{scope.row.title.en}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="schedulePublishDateTime" label="Date & Time"> 
        <template slot-scope="scope">
          <span>{{scope.row.schedulePublishDateTime | formatDateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="Push to user">
           <template slot-scope="scope">
            <span>{{scope.row.isPushToUser | isPushToUser}}</span>
           </template>
      </el-table-column>
      <el-table-column  label="Preview">
        <template slot-scope="{row}">
         <el-button type="text" @click="goToDetail(row.id)"> <img src="@/assets/images/preview.png"> </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Edit"> 
         <template slot-scope="{row}">
          <div class="button-img"><el-button type="text" @click="goToEdit(row.id)"> <img src="@/assets/images/Edit - large.png"> </el-button></div>
        </template>
      </el-table-column>
      <el-table-column  label="Delete" > 
         <template slot-scope="{row}">
          <div class="button-img"><el-button type="text" @click="onDelete(row.id)"> <img src="@/assets/images/mingcute_delete-2-fill.png"> </el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="total > 0"
      :total="total"
      :page.sync="formLabelAlign._start"
      :limit.sync="formLabelAlign._limit"
      @pagination="getNews"
    />
  </div>
</template>

<script>
import exportExcel from "@/components/exportExcel"
import {getNews,deleteNews,getExportNews} from "@/api/news";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
    exportExcel
  },
  filters: {
    isPushToUser(status) {
       return status === true ? "Yes" : "No";
    }
  },
  data() {
    return {
      tableLabels:[
        {
          label:'ID',
          prop:'id'
        },
        {
          label:'Title_EN',
          prop:'titleEn'
        },
        {
          label:'Title_TC',
          prop:'titleZhHant'
        },
        {
          label:'Title_SC',
          prop:'titleZhCn'
        },
        {
          label:'Date (YYYY-MM-DD HH:MM:SS)',
          prop:'updatedAt'
        },
        {
          label:'Content_EN',
          prop:'contentEn'
        },
        {
          label:'Content_TC',
          prop:'contentZhHant'
        },
        {
          label:'Content_SC',
          prop:'contentZhCn'
        },
        {
          label:'Push to user',
          prop:'isPushToUser'
        },
        {
          label:'Push message_EN',
          prop:'pushMessageEn'
        },
        {
          label:'Push message_TC',
          prop:'pushMessageZhHant'
        },
        {
          label:'Push message_SC',
          prop:'pushMessageZhCn'
        },
        {
          label:'Link Type',
          prop:'type'
        },
        {
          label:'Link_EN',
          prop:'linkEn'
        },
        {
          label:'Link_TC',
          prop:'linkZhHant'
        },
        {
          label:'Link_SC',
          prop:'linkZhCn'
        },
      ],
      total:0,
      formLabelAlign: {
          likeKeywords: null,
          date: null,
          _start:1,
          _limit:10
      },
      news:[],
      exportNews:[],
    };
  },
  watch:{
    formLabelAlign:{
       handler(v){
         if(v){
           this.getNews()
           this.getExportNews()
         }
       },
       deep:true 
    }
  },
  created() {
    this.getNews()
    this.getExportNews()
  },
  methods:{
    onDelete(id){
      //彈窗
      this.$confirm('Confirm to delete?', '',
      {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(() => {
        this.deleteNews(id)
      })
    },
    goToDetail(id){
      this.$router.push({path:'/new/'+id} )
    },
    goToEdit(id){
      this.$router.push({path:'/new/'+id+'/edit'} )
    },
    goToAdd(){
      this.$router.push({path:'/new/newAdd'} )
    },
    deleteNews(id){
      deleteNews(id).then(()=>{
         this.$message({
          type: 'success',
          message: 'Successfully deleted!'
        });
         this.getNews()
      })
    },
    getNews(){
      let from ={
        likeKeywords: this.formLabelAlign.likeKeywords,
        date: this.formLabelAlign.date,
        _start: this.formLabelAlign._start-1,
        _limit: this.formLabelAlign._limit
      }
       getNews(from).then((res)=>{
         this.news = res.data
         this.total = Number(res.headers["x-total-count"]);
       })
    },
    getExportNews(){
      let from ={
        likeKeywords: this.formLabelAlign.likeKeywords,
        date: this.formLabelAlign.date,
        _start: 0,
        _limit: 999999999999999
      }
       getExportNews(from).then((res)=>{
         this.exportNews = res.data
        //  this.total = Number(res.headers["x-total-count"]);
       })
    },
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box{
    padding-top:30px;
    border-top: 1px solid #D9D9D9;
}

.button-img img {
  width: 30px;
  height: auto;
}

</style>