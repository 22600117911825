<template>
  <div>
      <el-button  type="primary" icon="el-icon-upload2"  @click="onButtonTitleButton">{{buttonTitle}}</el-button>
  </div>
</template>

<script>
import moment from 'moment'
import {isPushedToUser,historyType,newType,linkCheckStatus,adminPermissionStatus} from '@/filters'
import { export_json_to_excel } from "@/excel/Export2Excel";
export default {
   props: {
    buttonTitle:{
        type: String,
        default: ''
    },
    path:{
        type: String,
        default: ''
    },
    fileName:{
        type: String,
        default: ''
    },
    tableLabels: {
      type: Array,
      default: function(){},
    },
    labels: {
      type: Array,
      default: function(){},
    },
  },
  methods: {
    onButtonTitleButton(){
        this.exportExcel()
    },
    exportExcel() {
      require.ensure([], () => {
        const tHeader = this.tableLabels.map((v) => {
          return v.label;
        }); // Excel的表头
        const filterVal = this.tableLabels.map((v) => {
          return v.prop;
        }); // 数据对应的字段
        let list=''
        if(this.path == 'report'){
            list = this.labels.map((v)=>{ 
                  v.updatedAt = `="${moment(v.updatedAt).format('YYYY-MM-DD HH:mm:ss')}"`  
                  v.isPush =  isPushedToUser(v.isPush)
                  v.type =  historyType(v.type)
                  v.materialTitleEn = v.materialTitle.en
                  v.materialTitleZhCn = v.materialTitle.zhCn
                  v.materialTitleZhHant = v.materialTitle.zhHant
                  return v
            })
        } else if(this.path == 'news'){
            list = this.labels.map((v)=>{ 
                  v.updatedAt = `="${moment(v.updatedAt).format('YYYY-MM-DD HH:mm:ss')}"`  
                  v.isPushToUser =  isPushedToUser(v.isPushToUser)
                  v.type =  newType(v.type)
                  v.titleEn = v.title.en
                  v.titleZhCn = v.title.zhCn
                  v.titleZhHant = v.title.zhHant

                  v.contentEn = v.content.en
                  v.contentZhCn = v.content.zhCn
                  v.contentZhHant = v.content.zhHant

                  v.pushMessageEn = v.pushMessage.en
                  v.pushMessageZhCn = v.pushMessage.zhCn
                  v.pushMessageZhHant = v.pushMessage.zhHant

                  v.linkEn = v.link.en
                  v.linkZhCn = v.link.zhCn
                  v.linkZhHant = v.link.zhHant
                  return v
            })
             
        }else if(this.path == 'Link Check') {
          list = this.labels.map((j)=>{
            j.status = linkCheckStatus(j.status) 
            j.checkDatetime = `="${moment(j.checkDatetime).format('YYYY-MM-DD HH:mm:ss')}"`
            return j
          })
        } else if(this.path == 'Admin Permission'){
           list = this.labels.map((j)=>{
            j.status = adminPermissionStatus(j.status) 
            j.sections = this.getOP(j.sections)
            return j
          })
        }
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, this.fileName);
      });
    },
    formatJson(filterVal, jsonData) {
      if(jsonData.length>0){
       return jsonData.map((v) => filterVal.map((j) => v[j]));
      } else {
        return []
      }
      
    },
    getOP(sections){
       return sections.map((h)=>{
         return '\n' + h.title.en
       })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>