import http from '@/utils/http'
export function file(uploadUrl, data) {
    return http.post(uploadUrl, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        unformat: true
    })
}
export function importFile(url,data) {
    return http.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        unformat: true
    })
}