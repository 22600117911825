<template>
  <div class="new-section">
    <Header-Back pageTitle="Adding New Section"></Header-Back>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="top" class="demo-ruleForm">
      <el-form-item prop="icon_url">
        <div class="icon">
          <div class="img">
            <img :src="ruleForm.icon_url" v-if="ruleForm.icon_url" alt="">
            <img src="@/assets/images/edit.png" class="edit" alt="" @click="update">
          </div>

          <div class="icon-description">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 240px(H) x 240px(W)</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Title - English" prop="en">
        <el-input v-model="ruleForm.en" autocomplete="off" placeholder="Please enter title"></el-input>
      </el-form-item>
      <el-form-item label="Title - Traditional Chinese" prop="zh_hant">
        <el-input v-model="ruleForm.zh_hant" autocomplete="off" placeholder="Please enter 頁面名稱"></el-input>
      </el-form-item>
      <el-form-item label="Title - Simplified Chinese" prop="zh_cn">
        <el-input v-model="ruleForm.zh_cn" autocomplete="off" placeholder="Please enter 頁面名稱"></el-input>
      </el-form-item>
      <el-form-item label="Template" prop="template_ids">
        <el-select v-model="ruleForm.template_ids" multiple
         placeholder="Please select template" style="width: 100%">
          <el-option :disabled="item.disable"
            v-for="(item, idx) in tempTemplate" :key="idx"
            :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Path" prop="path">
        <el-input v-model="ruleForm.path" autocomplete="off" placeholder="Please enter path"></el-input>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="detail">Create</el-button> 
  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'
import { addSections,  } from '@/api/section.js'
import { getPages } from '@/api/pages.js'
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      options: [],
      ruleForm: {
        icon_url: '',
        en: '',
        "zh_hant": "",
        "zh_cn": "",
        template_ids: [],
        path: ''
      },
      rules: {
        'icon_url': [
          { required: true, message: 'Please select Image', trigger: 'blur' },
        ],
        'en': [
          { required: true, message: 'Please enter Title - English', trigger: 'blur' },
        ],
        'zh_hant': [
          { required: true, message: 'Please enter Title - Traditional Chinese', trigger: 'blur' },
        ],
        'zh_cn': [
          { required: true, message: 'Please enter Title - Simplified Chinese', trigger: 'blur' },
        ],
        'template_ids': [
          { required: true, message: 'Please select Template', trigger: 'blur' },
        ],
        'path': [
          { required: true, message: 'Please enter Path', trigger: 'blur' },
        ],
      }
      
    }
  },
  computed: {
    ...mapState(['Template']),
    tempTemplate: function() {
      let templates = JSON.parse(JSON.stringify(this.Template)).map(its => ({...its, disable: false})), 
      [id] = this.ruleForm.template_ids

      if(id == undefined) return templates

      let [filterChoice] = templates.filter(its => its.id == id && its)
      if(filterChoice.type == 0) {
        templates.map(its => {
          if(its.id != id && its.id != (id + 1))
            its.disable = true
        })
      } else if(filterChoice.type == 1) {
        templates.map(its => {
          if(its.id != id && its.id != (id - 1))
            its.disable = true
        })
      } else {
        templates.map(its => its.id != id && (its.disable = true))
      }
      
      return templates
    }
  },
  methods: {
    ...mapMutations(['setSection', 'setPages']),
    update() {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = {width: 50, height: 50}, maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        this.ruleForm.icon_url = res
      })
    },
    detail() {
      let {ruleForm} = this, form = {
        "icon_url": ruleForm.icon_url,
        "title": {
          "en": ruleForm.en,
          "zh_hant": ruleForm.zh_hant,
          "zh_cn": ruleForm.zh_cn
        },
        "template_ids": ruleForm.template_ids,
        "link": {
          "en": '',
          "zh_hant": '',
          "zh_cn": ''
        },
        "path": ruleForm.path
      }

      console.log(form)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addSections(form).then(v => {
            this.getSection()
            this.getPages()
            // 更新管理员权限
            this.getAdminRole()
            this.$router.push(`/overview/secondRowSection/newSectionDetail?id=${v.data.id}`)
          })
        }
      })
    },
    getPages() {
      getPages().then(v => {
        this.setPages(v.data)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .img {
    width: 100px;
    height: 100%;
    position: relative;
    background-color: #EBEBEB;
    margin-right: 40px;

    img {
      width: 100%;
      height: 100%;
    }

    .edit {
      width: 24px;
      height: auto;
      position: absolute;
      bottom: -3px;
      right: -3px;
      cursor: pointer;
    }
  }

  .icon-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    * {
      display: block;
    }
  }
}
</style>