<template>
  <div>
    <Header-Back  pageTitle="Preview">
      <el-button
          icon="el-icon-printer"
          @click="onPrint"
          type="primary"
          >Print</el-button
        >
    </Header-Back>
    <el-form
      ref="form"
      id="print-container"
      label-position="top"
      label-width="80px"
    >
      <div class="column-title">Title</div>
      <el-form-item label="English">
        <span v-if="form.title">{{ form.title.en }}</span>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <span v-if="form.title">{{ form.title.zhHant }}</span>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <span v-if="form.title">{{ form.title.zhCn }}</span>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Thumbnail</div>
        <template v-for="(item,index) in languages ">
          <el-form-item :label="setLabel(item)" :key="index+'thumbnail'">
              <el-row type="flex" :gutter="20">
                  <el-col :span="6">
                    <el-image :src="form[`thumbnail`][`${item}`]">
                        <div slot="placeholder" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                  </el-col>
              </el-row>
          </el-form-item>
        </template>
      <el-divider></el-divider>
      <div class="column-title">Schedule Publish Date & Time</div>
      <el-form-item label="">
        <span>{{ form.schedulePublishDateTime }}</span>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Content</div>
      <el-form-item label="English">
        <span v-if="form.content" class="span-text" v-html="form.content.en"></span>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <span v-if="form.content" class="span-text" v-html="form.content.zhHant"></span>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <span v-if="form.content" class="span-text" v-html="form.content.zhCn"></span>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Push Notification and Share Message</div>
      <el-form-item label="">
        <i class="el-icon-check" v-if="form.isPushToUser"></i
        ><span>Push to user (Send push notification to user)</span>
      </el-form-item>
      <el-form-item label="English">
        <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.en"></span>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.zhHant"></span>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.zhCn"></span>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">link（s）</div>
        <div class="div-item" v-for="(item,index) in form.links" :key="index+'links'">
        <el-form-item label="">
          <span>{{item.type | typeFilters}}</span>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item label="English" :prop="`link[${index}].en`">
              <edit-div :canEdit="false" v-model="item.link.en"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Traditional Chinese" :prop="`link[${index}].zhHant`">
              <edit-div :canEdit="false" v-model="item.link.zhHant"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Simplified Chinese" :prop="`link[${index}].zhCn`">
              <edit-div :canEdit="false" v-model="item.link.zhCn"></edit-div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="btn-group">
    <el-button type="success" @click="goToEdit">Edit</el-button>
    <el-button
      icon="el-icon-printer"
      type="primary" @click="onPrint"
      >Print</el-button
    >
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/news";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        title: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        thumbnail: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        schedulePublishDateTime: null,
        isInstant: null,
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        isPushToUser: null,
        pushMessage: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        link: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
      },
    };
  },
  created(){
    this.getNewsDetail();
  },
  methods: {
    onPrint(){
      localStorage.setItem('printNew', JSON.stringify(this.form))
      let a = document.createElement('a')
      a.href = location.pathname + '#/printNew'
      a.setAttribute('target', '_blank')
      a.click()
    },
    setLabel(lan){

      switch(lan){
        case 'en':
          return 'English';
        case 'zhHant':
          return 'Traditional Chinese';
        case 'zhCn':
          return 'Simplified Chinese';
        default:
          return '';
      }
    },
    goToEdit() {
      this.$router.push({ path: "/new/" + this.id + "/edit" });
    },
    onBrowse() {},
    getNewsDetail() {
      getNewsDetail(this.id).then((res) => {
        this.form = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
}
.el-image {
  width: 100%;
}
.image-slot {
  display: flex;
  width: 100%;
  height: 187px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #6d6d6d;
  background: #6d6d6d;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.column-title {
  margin-bottom: 20px;
}
.col-list {
  margin-bottom: 10px;
  color: var(--black, #000);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.el-icon-check {
  color: var(--Main, #124b80);
  /* H2 */
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-right: 10px;
}
.span-text{
  //css换行
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;

}
.btn-group{
  margin-top: 20px;
}
</style>