export default {
  'titleEn': [
    { required: true, message: 'Please enter  English', trigger: 'blur' },
  ],
  'titleZhHant': [
    { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  ],
  'titleZhCn': [
    { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  ],
  'accidentDate': [
    { required: true, message: 'Please choose  Date of Accident', trigger: 'blur' },
  ],
  // 'placeOfAccidentEn': [
  //   { required: true, message: 'Please enter  English', trigger: 'blur' },
  // ],
  // 'placeOfAccidentZhHant': [
  //   { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  // ],
  // 'placeOfAccidentZhCn': [
  //   { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  // ],
  // 'summaryEn': [
  //   { required: true, message: 'Please enter  English', trigger: 'blur' },
  // ],
  // 'summaryZhHant': [
  //   { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  // ],
  // 'summaryZhCn': [
  //   { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  // ],
  // 'wsaEn': [
  //   { required: true, message: 'Please enter  English', trigger: 'blur' },
  // ],
  // 'wsaZhHant': [
  //   { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  // ],
  // 'wsaZhCn': [
  //   { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  // ],
  // 'pushMessageEn': [
  //   { required: true, message: 'Please enter  English', trigger: 'blur' },
  // ],
  // 'pushMessageZhHant': [
  //   { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  // ],
  // 'pushMessageZhCn': [
  //   { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  // ],
  'references[0].titleEn': [
    { required: true, message: 'Please enter  English', trigger: 'blur' },
  ],
  'references[0].titleZhHant': [
    { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
  ],
  'references[0].titleZhCn': [
    { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
  ],
  // 'references[0].linkEn': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
  // 'references[0].linkZhHant': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
  // 'references[0].linkZhCn': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
  // 'link[0].en': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
  // 'link[0].zhHant': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
  // 'link[0].zhCn': [
  //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
  //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
  // ],
}