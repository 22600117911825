<template>
  <div>
    <el-dialog
      custom-class="loading-box"
      :visible.sync="centerDialogVisible"
      width="20%"
      center
      :show-close="false"
    >
      <div class="loading-icon">
        <i class="el-icon-loading" />
        <div class="loading-text">Now Loading</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    centerDialogVisible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .loading-box {
    border-radius: 10px;
  }
}
.loading-icon {
  font-size: 55px;
  text-align: center;
}
.loading-text {
  font-size: 20px;
}
</style>
