import http from '@/utils/http'

export function getSections(){
  return http.get('/sections')
}
export function addSections(data){
  return http.post('/sections', data)
}
export function getSectionsDetail(id){
  return http.get(`/sections/${id}`)
}
export function editSections(data){
  return http.put(`/sections`, data)
}
export function deleteSections(id){
  return http.delete(`/sections/${id}`)
}
export function disableSections(id){
  return http.post(`/sections/${id}/disable`)
}
export function enableSections(id){
  return http.post(`/sections/${id}/enable`)
}