<template>
  <div class="header" :style="isUat && uatStyle">
    <div class="header-text" :style="isUat && uatStyle">OSH 2.0 Backend Portal{{ isUat && '(UAT)' }}</div>
    <div class="userinfo">
      <span class="userinfo-name">{{userInfo.username}}</span>
      <div class="userinfo-cursor userinfo-button" @click="onLogoutClick">Log out</div>
       <loadingDialog :centerDialogVisible="centerDialogVisible"></loadingDialog>
    </div>
   
  </div>
</template>

<script>
import loadingDialog from "@/components/loadingDialog";
import { logout } from "@/api/security";
import { removeToken, removeAll, getUserInfo } from "@/utils/auth";
export default {
  components:{
    loadingDialog
  },
  data() {
    return {
      centerDialogVisible:false,
      userInfo: {},

      uatStyle: {
        backgroundColor: '#FFA115',
        color: '#333'
      },
      isUat: false
    }
  },
  created(){
    let userInfo = getUserInfo('userInfo');
    if(userInfo){
      this.userInfo = userInfo;
   }

   console.log(process.env)
   if(process.env.VUE_APP_BASE_API == 'https://wsa-sraa.kanhan.com/api/portal/v1') {
      this.isUat = true
    }
  },
  methods: {    
    onLogoutClick() {
      this.centerDialogVisible = true;
      this.logout();
    },
    logout() {
      logout()
      .finally(() => {
          removeToken();
          removeAll();
          location.reload();
          this.centerDialogVisible = false;
        })
    },
  },
  mounted() {

  }
};
</script>

<style lang="scss"  scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 20px;
  background-color: #124B80;
  color: #fff;
}
.header-text{
color: #FFF;
font-family: Arial;
font-size: 24px;
font-style: normal;
font-weight: 700;
}
.logo {
  display: flex;
  align-items: center;
}
.logo-img {
  width: 40px;
  height: 40px;
}
.logo-text {
  font-size: 20px;
  font-weight: bold;
}
.userinfo {
  display: flex;
  font-size: 20px;
  align-items: center;
  height: 100%;
}
.userinfo-divider,
.userinfo-text {
  margin: 0 20px;
}
.userinfo-button {
 background: #6D6D6D;
 height: 100%;
 padding: 0 40px;
 display: flex;
 align-items: center;
 margin-left: 20px;
}
.userinfo-cursor {
  cursor: pointer;
  color: #fff;
}
</style>
