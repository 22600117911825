import Vue from 'vue'

Vue.directive('paste', {
  bind: function(el) {
    el.addEventListener('paste', function(e) {
        e.preventDefault();
        var text = (e.clipboardData || window.clipboardData).getData('text/plain');
        document.execCommand('insertHTML', false, text);
    });
  }
})