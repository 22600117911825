<template>
  <div>
    <el-form ref="ruleForm" :rules="rules" label-position="top" :model="ruleForm" label-width="80px">
      <div class="column-title">Title</div>
      <el-form-item label="English" prop="titleEn">
        <el-input v-model="ruleForm.titleEn"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="titleZhHant">
        <el-input v-model="ruleForm.titleZhHant"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="titleZhCn">
        <el-input v-model="ruleForm.titleZhCn"></el-input>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title"><span class="span-label-text">*</span>Date of Accident</div>
      <el-form-item label="" prop="accidentDate" :rules="[{ required: true, message: 'Please select the date of accident', trigger: 'blur' }]">
        <el-date-picker
          v-model="ruleForm.accidentDate"
          type="month"
          value-format="yyyy-MM"
          placeholder="">
        </el-date-picker>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Place of Accident</div>
      <el-form-item label="English" prop="placeOfAccidentEn">
        <el-input v-model="ruleForm.placeOfAccidentEn"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="placeOfAccidentZhHant">
        <el-input v-model="ruleForm.placeOfAccidentZhHant"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="placeOfAccidentZhCn">
        <el-input v-model="ruleForm.placeOfAccidentZhCn"></el-input>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Summary</div>
      <el-form-item label="English" prop="summaryEn">
        <Tinymce v-model="ruleForm.summaryEn"></Tinymce>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="summaryZhHant">
        <Tinymce v-model="ruleForm.summaryZhHant"></Tinymce>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="summaryZhCn">
        <Tinymce v-model="ruleForm.summaryZhCn"></Tinymce>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">
        Work Safety Alert for Contractors/Proprietors/Employers
      </div>
      <el-form-item label="English" prop="wsaEn">
        <Tinymce v-model="ruleForm.wsaEn"></Tinymce>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="wsaZhHant">
        <Tinymce v-model="ruleForm.wsaZhHant"></Tinymce>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="wsaZhCn">
        <Tinymce v-model="ruleForm.wsaZhCn"></Tinymce>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Reference</div>
      <template v-for="(item, index) in ruleForm.references">
        <div :key="index - 100" class="div-item">
          <i class="el-icon-close" @click="onIconClose(index)"></i>
          <el-form-item label="Titile - English" :prop="`references[${index}].titleEn`">
            <el-input v-model="item.titleEn"></el-input>
          </el-form-item>
          <el-form-item label="Titile - Traditional Chinese" :prop="`references[${index}].titleZhHant`">
            <el-input v-model="item.titleZhHant"></el-input>
          </el-form-item>
          <el-form-item label="Titile - Simplified Chinese" :prop="`references[${index}].titleZhCn`">
            <el-input v-model="item.titleZhCn"></el-input>
          </el-form-item>
          <el-row type="flex" :gutter="20">
            <el-col :span="8">
              <el-form-item label="Link -English" :prop="`references[${index}].linkEn`">
                <edit-div v-model="item.linkEn"></edit-div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Link -Traditional Chinese" :prop="`references[${index}].linkZhHant`">
                <edit-div v-model="item.linkZhHant"></edit-div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Link -Simplified Chinese" :prop="`references[${index}].linkZhCn`">
                <edit-div v-model="item.linkZhCn"></edit-div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Sorting Order">
            <el-input v-model.number="item.sortingOrder"></el-input>
          </el-form-item>
        </div>
      </template>
      <div class="add">
        <div class="add-btn" @click="add">
          <img src="@/assets/images/add.png" alt="" />
          <span>Add</span>
        </div>
      </div>

      <el-divider></el-divider>
      <div class="column-title">Push Notification and Share Message</div>
      <el-form-item label="">
        <el-checkbox v-model="ruleForm.isPushToUser">Push to user (Send push notification to user)</el-checkbox>
      </el-form-item>
      <el-form-item label="English" prop="pushMessageEn">
        <el-input type="textarea" rows="6" v-model="ruleForm.pushMessageEn" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="pushMessageZhHant">
        <el-input type="textarea" rows="6" v-model="ruleForm.pushMessageZhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="pushMessageZhCn">
        <el-input type="textarea" rows="6" v-model="ruleForm.pushMessageZhCn" autocomplete="off"></el-input>
      </el-form-item>

      <el-divider></el-divider>
      <div class="column-title">Link(s)</div>
      <div class="div-item" v-for="(its, idx) in ruleForm.link" :key="idx">
        <i class="el-icon-close" @click="onIconRemove(idx)"></i>
        <el-row type="flex" :gutter="20">
          <el-col :span="20">
            <el-form-item>
              <el-radio v-model="its.type" :label="0">PDF</el-radio>
              <el-radio v-model="its.type" :label="1">External Link</el-radio>
              <el-radio v-model="its.type" :label="2">Photo</el-radio>
              <el-radio v-model="its.type" :label="3">Video</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item label="English" :prop="`link[${idx}].en`">
              <edit-div v-model="its.en"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Traditional Chinese" :prop="`link[${idx}].zhHant`">
              <edit-div v-model="its.zhHant"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Simplified Chinese" :prop="`link[${idx}].zhCn`">
              <edit-div v-model="its.zhCn"></edit-div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="add">
        <div class="add-btn" @click="addlink">
          <img src="@/assets/images/add.png" alt="" />
          <span>Add</span>
        </div>
      </div>

    </el-form>
  </div>
</template>

<script>
import rules from './rules'

export default {
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      ruleForm: {},
      rules
    }
  },
  methods: {
    onIconClose(index){
      //數組刪除
      this.ruleForm.references.splice(index,1)
    },
    add() {
      let value = {
        titleEn: null,
        titleZhHant: null,
        titleZhCn: null,
        linkEn: null,
        linkZhHant: null,
        linkZhCn: null,
        sortingOrder: null,
      }
      this.ruleForm.references.push(value);

      this.verifyLink()
    },
    verifyLink() {
      let references = this.ruleForm.references.length
      // , link = this.ruleForm.link.length
      for(let i = 0; i < references; i++) {
        this.rules[`references[${i}].titleEn`] = [
          { required: true, message: 'Please enter  English', trigger: 'blur' },
        ]
        this.rules[`references[${i}].titleZhHant`] = [
          { required: true, message: 'Please enter  Traditional Chinese', trigger: 'blur' },
        ]
        this.rules[`references[${i}].titleZhCn`] = [
          { required: true, message: 'Please enter  Simplified Chinese', trigger: 'blur' },
        ]
        // this.rules[`references[${i}].linkEn`] = [
        //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
        // ]
        // this.rules[`references[${i}].linkZhHant`] = [
        //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
        // ]
        // this.rules[`references[${i}].linkZhCn`] = [
        //   {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
        //   // { required: true, message: 'Please enter Link', trigger: 'blur' },
        // ]
      }

      // for(let i = 0; i < link; i++) {
      //   this.rules[`link[${i}].en`] = [
      //     {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
      //     // { required: true, message: 'Please enter Link', trigger: 'blur' },
      //   ]
      //   this.rules[`link[${i}].zhHant`] = [
      //     {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
      //     // { required: true, message: 'Please enter Link', trigger: 'blur' },
      //   ]
      //   this.rules[`link[${i}].zhCn`] = [
      //     {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
      //     // { required: true, message: 'Please enter Link', trigger: 'blur' },
      //   ]
      // }
    },
    onIconRemove(index){
      //數組刪除
      this.ruleForm.link.splice(index,1)
    },
    addlink() {
      let value = {
        type: 0,
        en: null,
        zhHant: null,
        zhCn: null,
      };
      this.ruleForm.link.push(value);
      this.verifyLink()
    }
  },
  mounted() {
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
    this.verifyLink()
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  position: relative;
  padding: 30px;
  background: #f5f5f5;
  margin: 20px 0;
  .el-icon-close{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}

.add-btn {
  width: 120px;
  height: 44px;
  border: 1px solid #1a74b0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;

  img {
    width: 24px;
    height: auto;
    margin-right: 10px;
  }
}
</style>