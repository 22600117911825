<template>
  <div class="">
    <Header-Back pageTitle="New"></Header-Back>

    <el-form :model="ruleForm" label-position="top" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="module">Title</div>
      <el-form-item label="English">
        <el-input v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <el-input v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <el-input v-model="ruleForm.pass" autocomplete="off"></el-input>
      </el-form-item>

      <div class="module">Date</div>
      <el-form-item>
        <el-date-picker v-model="ruleForm.date" type="date"
          style="width: 50%"></el-date-picker>
      </el-form-item>

      <div class="module">Content</div>
      <el-form-item label="English">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>

      <div class="module">Push Notification and Share Message</div>
      <el-form-item >
        <el-checkbox v-model="ruleForm.pass">Push to user (Send push notification to user)</el-checkbox>
      </el-form-item>
      <el-form-item label="English">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <el-input type="textarea" v-model="ruleForm.pass" rows="10"></el-input>
      </el-form-item>

      <div class="module">Push Notification and Share Message</div>
      <el-form-item :label="`Document ${idx + 1}`" v-for="(its, idx) in ruleForm.doc" :key="idx">
        <div class="document">
          <div class="box">
            <div class="label">Link - English</div>
            <div class="value">
              <el-input v-model="its.english" placeholder="External link"></el-input>
            </div>
          </div>
          <div class="box">
            <div class="label">Link - Traditional Chinese</div>
            <div class="value">
              <el-input v-model="its.traditional" placeholder="External link"></el-input>
            </div>
          </div>
          <div class="box">
            <div class="label">Link - Simplified Chinese</div>
            <div class="value">
              <el-input v-model="its.simplified" placeholder="External link"></el-input>
            </div>
          </div>
        </div>
      </el-form-item>

      <div class="add">
        <div class="add-btn" @click="add">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>
    </el-form>

    <el-button type="primary"> Save </el-button> 
    <el-button type="danger"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        doc: [
          {
            english: '',
            traditional: '',
            simplified: '',
          }
        ]
      }
    }
  },
  methods: {
    add() {
      this.ruleForm.doc.push({
        english: '',
        traditional: '',
        simplified: '',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
  padding-top: 20px;
  font-size: 18px;
  color: #124B80;
  font-weight: 700;
  margin-bottom: 20px;
  border-top: 1px solid #D9D9D9;
}

.module:nth-of-type(1) {
  border: none;
  padding-top: 0;
}

.document {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;

  .box {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;  
    margin: 0 5px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}

.add {
  width: 100%;
  margin-bottom: 40px;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}
</style>