import exportToExcel from "@/utils/exportExcel";

let tHeader = [
  'ID', 
  'Title_EN', 'Title_TC', 'Title_SC',
  'Thumbnail', 
  'Video_English', 'Video_Chinese', 'Video_Hindi',
  'Video_Nepali', 'Video_Tagalog', 'Video_Urdu',
];

export default function exportExcel(tableData) {
  // 处理data
  let data = handleData(tableData),
  title = 'WSA-Animation'

  exportToExcel(data, title, tHeader, tHeader, () => {})
}

function handleData(tableData) {
  let data = []

  tableData.filter(its => {
    let obj = {
      'ID': its.id,
      'Title_EN': its.title.en,
      'Title_TC': its.title.zhHant,
      'Title_SC': its.title.zhCn,
      'Thumbnail': its.thumbnail || '-',
      'Video_English': its.videoUpload.english || '-',
      'Video_Chinese': its.videoUpload.chinese || '-',
      'Video_Hindi': its.videoUpload.hindi || '-',
      'Video_Nepali': its.videoUpload.nepali || '-',
      'Video_Tagalog': its.videoUpload.tagalog || '-',
      'Video_Urdu': its.videoUpload.urdu || '-',
    }

    data.push(obj)
  })
  console.log(data)
  return data
}