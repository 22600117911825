<template>
  <div>
    <Header-Back :hideBack="true" :pageTitle="`Notices - ${noticesType(Number($route.params.type))}`">
    </Header-Back>
    <div class="disclaimer-box">
      <div class="column-title">Content</div>
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <template v-for="(item, index) in languages">
          <el-form-item :label="setLabel(item)" :key="index">
            <Tinymce :key="$route.params.type" v-model="formLabelAlign[`content${item}`]"></Tinymce>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <el-button type="success" @click="onSave">Save</el-button>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import { getNotices, editNotices } from "@/api/setting";
export default {
   components: {
    Tinymce
  },
  data() {
    return {
      noticeList: [],
      formLabelAlign: {
        id: null,
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
      },
    };
  },
  watch:{
    '$route.params.type':{
      handler(val){
          console.log(val)
         if(val!=null){
           this.getNotices(val)
         }
      },
      deep:true,
      immediate:true
    }
  },
  methods: {
    noticesType(value) {
      switch (value) {
        case 0:
          return 'Disclaimer';
        case 1:
          return 'Copyright Notice';
        case 2:
          return 'Privacy Policy';
        case 3:
          return 'Accessibility Statement';
        default:
          break;
      }
    },
    setLabel(lan) {
      switch (lan) {
        case "en":
          return "English";
        case "zhHant":
          return "Traditional Chinese";
        case "zhCn":
          return "Simplified Chinese";
        default:
          return "";
      }
    },
    formattedForm() {
      this.languages.forEach((key) => {
        this.formLabelAlign.content[key] = this.formLabelAlign[`content${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(this.formLabelAlign, `content${key}`, this.formLabelAlign.content[key]);
      });
      this.$forceUpdate()
    },
    onSave() {
       this.editNotices()
    },
    getNotices(val) {
      getNotices().then((v) => {
        this.noticeList= v.data
        if(this.noticeList!=null || this.noticeList.length>0){
            this.formLabelAlign = this.noticeList.filter((item) => {
                return item.type ==  Number(val);
            })[0];
            this.initializeForm()
        }
      });
    },
    editNotices() {
      this.formattedForm()
      editNotices(this.formLabelAlign.id,this.formLabelAlign).then(() => {
         this.$message({
          message: "Edit successfully",
          type: "success",
        });
        this.getNotices()
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-box {
  padding-top: 30px;
}
.page-img-button {
  margin: 0 1px;
}
.el-pagination {
  padding-top: 20px;
  padding-block: 20px;
  text-align: center;
}
.save-button {
  padding: 20px 150px;
  background-color: #184c84;
}
.input-class {
  width: 490px;
}
</style>