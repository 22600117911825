<template>
  <div class="notiOfConstWork">
    <Header-Back :hideBack="true" :pageTitle="pageTitle"> </Header-Back>

    <div class="line">Document(s)</div>
    
    <el-form :model="its" label-position="top" v-for="(its, idx) in formArr" :key="idx"
     :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
     <img class="close" src="@/assets/images/close.png" alt="" @click="remove(idx)">

      <el-form-item label="Title - English" prop="title_en">
        <el-input v-model="its.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Title - Traditional Chinese" prop="title_zhHant">
        <el-input v-model="its.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Title - Simplified Chinese" prop="title_zhCn">
        <el-input v-model="its.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Subtitle - English">
        <el-input v-model="its.subtitle_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Subtitle - Traditional Chinese">
        <el-input v-model="its.subtitle_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Subtitle - Simplified Chinese">
        <el-input v-model="its.subtitle_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-radio-group v-model="its.type">
          <el-radio :label="0">PDF</el-radio>
          <el-radio :label="1">External Link</el-radio>
          <el-radio v-model="its.type" :label="2">Photo</el-radio>
          <el-radio v-model="its.type" :label="3">Video</el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="group">
        <el-form-item label="Link - English" prop="link_en">
          <edit-div v-model="its.link_en"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Traditional Chinese" prop="link_zhHant">
          <edit-div v-model="its.link_zhHant"></edit-div>
        </el-form-item>
        <el-form-item label="Link - Simplified Chinese" prop="link_zhCn">
          <edit-div v-model="its.link_zhCn"></edit-div>
        </el-form-item>
      </div>

      <el-form-item label="Sorting Order">
        <el-input v-model.number="its.sorting_order" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <div class="add">
      <div class="add-btn" @click="add">
        <img src="@/assets/images/add.png" alt="">
        <span>Add</span>
      </div>
    </div>
    
    <el-button type="success" @click="save"> Save </el-button> 
    <el-button type="danger" @click="del"> <img src="@/assets/images/delete-save.png" alt=""> Delete </el-button> 
  </div>
</template>

<script>
import { getNotifyYourWorkplaces, addNotifyYourWorkplaces, deleteNotifyYourWorkplaces } from '@/api/material.js'

export default {
  data() {
    return {
      formArr: [
        {
          title_en: '',
          title_zhHant: '',
          title_zhCn: '',
          subtitle_en: '',
          subtitle_zhHant: '',
          subtitle_zhCn: '',
          type: 0,
          link_en: '',
          link_zhHant: '',
          link_zhCn: '',
          sorting_order: 0
        }
      ],
      
      pageTitle: 'Notify Your Workplace',
      pageId: null,
      rules: {
        'title_en': [
          { required: true, message: 'Please enter  Title English', trigger: 'blur' },
        ],
        'title_zhHant': [
          { required: true, message: 'Please enter  Title Traditional Chinese', trigger: 'blur' },
        ],
        'title_zhCn': [
          { required: true, message: 'Please enter  Title Simplified Chinese Title', trigger: 'blur' },
        ],
        'link_en': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
        'link_zhHant': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
        'link_zhCn': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'},
          { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
      }
    }
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId

      this.getData()
    }
  },
  methods: {
    add() {
      this.formArr.push({
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        subtitle_en: '',
        subtitle_zhHant: '',
        subtitle_zhCn: '',
        type: 0,
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
        sorting_order: 0
      })

      this.verifyLink()
    },
    verifyLink() {
      let leng = this.formArr.length
      for(let i = 0; i < leng; i++) {
        this.rules[`documents[${i}].link_en`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'}
        ]
        this.rules[`documents[${i}].link_zhHant`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'}
        ]
        this.rules[`documents[${i}].link_zhCn`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'change'}
        ]
      }
    },
    remove(idx) {
      let formArr = this.formArr
      if(formArr.length == 1) {
        this.$message.error('At least one term exists')
      } else formArr.splice(idx, 1)
    },
    del() {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        deleteNotifyYourWorkplaces(this.pageId).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.getData()
        })
      })
    },
    async save() {
      let arrData = this.formArr.map((its) => {
        return {
          "page_id": this.pageId,
          "title": {
            "en": its.title_en,
            "zh_hant": its.title_zhHant,
            "zh_cn": its.title_zhCn
          },
          "subtitle": {
            "en": its.subtitle_en,
            "zh_hant": its.subtitle_zhHant,
            "zh_cn": its.subtitle_zhCn
          },
          "type": its.type,
          "link": {
            "en": its.link_en,
            "zh_hant": its.link_zhHant,
            "zh_cn": its.link_zhCn
          },
          "sorting_order": its.sorting_order
        }
      })

      // 表单校验
      let ruleForms = this.$refs['ruleForm'], is = true
      for(let i = 0; i < ruleForms.length; i++) {
        try {
          await ruleForms[i].validate()
        } catch {
          is = false
        }
      }
      if(is)
      addNotifyYourWorkplaces(arrData).then(() => {
        this.$message.success('save successfully!')
      })

    },
    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName

      getNotifyYourWorkplaces({page_id: this.pageId}).then(v => {
        console.log(v.data)
        let formArr = v.data.map(its => {
          return {
            title_en: its.title.en,
            title_zhHant: its.title.zhHant,
            title_zhCn: its.title.zhCn,
            subtitle_en: its.subtitle.en,
            subtitle_zhHant: its.subtitle.zhHant,
            subtitle_zhCn: its.subtitle.zhCn,
            type: its.type,
            link_en: its.link.en,
            link_zhHant: its.link.zhHant,
            link_zhCn: its.link.zhCn,
            sorting_order: its.sortingOrder
          }
        })

        this.formArr = formArr.length != 0 ? formArr : this.formArr
        this.verifyLink()
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.ruleForm {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  position: relative;

  .close {
    width: 28px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 32%;
  }
}

.add {
  width: 100%;
  margin: 30px 0 40px 0;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}
</style>