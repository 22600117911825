<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Edit History & Push Notification List ">
      <!-- <el-button type="primary" icon="el-icon-upload2">Export</el-button> -->
      <exportExcel path="report" :tableLabels="tableLabels" fileName="Edit History" :labels="tableDataAll" buttonTitle="Export"></exportExcel>
    </Header-Back>
    <el-form
      
      label-position="top"
      :model="formInline"
      class="demo-form-inline"
    >
    <el-row type="flex" :gutter="20">
      <el-col :span="6"> 
        <el-form-item label="Admin Name">
        <el-input v-model="formInline.adminNameLike" placeholder="Keywords"></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="6"> 
        <el-form-item label="Title">
        <el-input v-model="formInline.likeKeywords" placeholder="Keywords"></el-input>
      </el-form-item>
      </el-col>
      <el-col :span="6"> 
        <el-form-item label="Type">
           <el-select v-model="formInline.type" clearable placeholder="">
          <el-option v-for="(item,index) in historyType" :label="item.label" :value="item.value" :key="index"></el-option>
        </el-select>
      </el-form-item>
      </el-col>
      <el-col :span="7"> 
        <el-form-item label="Pushed to user">
           <el-select v-model="formInline.isPush" clearable placeholder="">
          <el-option v-for="(item,index) in pushedToUsers" :label="item.label" :value="item.value" :key="index"></el-option>
        </el-select>
      </el-form-item>
      </el-col>
      <el-col :span="6">
         <el-form-item label="Date">
          <el-date-picker
            v-model="formInline.updatedAt"
            clearable
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="Select date">
          </el-date-picker>
         </el-form-item>
      </el-col>
    
     </el-row>
    </el-form>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="adminName" label="Admin Name"> </el-table-column>
      <el-table-column prop="materialTitle.en" label="Title"> </el-table-column>
      <el-table-column prop="type" label="Type"> 
        <template slot-scope="{row}">
          <span>{{row.type | historyType}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="Pushed to user">
         <template slot-scope="{row}">
               <span>{{row.isPush | isPushedToUser}}</span>
         </template>
      </el-table-column>
      <el-table-column  label="Last Modified">
         <template slot-scope="{row}">
               <span>{{row.updatedAt | formatDateTime}}</span>
         </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-show="total > 0"
      :total="total"
      :page.sync="formInline._start"
      :limit.sync="formInline._limit"
      @pagination="getOperationHistories"
    />
  </div>
</template>

<script>
import exportExcel from "@/components/exportExcel"
import  {getOperationHistories} from '@/api/report'
import Pagination from "@/components/Pagination";
export default {
  filters:{
    isPushedToUser(v){
      return v?'Yes':'No'
    }
  },
  components: {
    Pagination,
    exportExcel
  },
  data() {
    return {
      tableLabels:[
        {
          label:'Admin Name',
          prop:'adminName'
        },
        {
          label:'Title_EN',
          prop:'materialTitleEn'
        },
        {
          label:'Title_TC',
          prop:'materialTitleZhHant'
        },
        {
          label:'Title_SC',
          prop:'materialTitleZhCn'
        },
        {
          label:'Type',
          prop:'type'
        },
        {
          label:'ID',
          prop:'id'
        },
        {
          label:'Pushed to user',
          prop:'isPush'
        },
        {
          label:'Last Modified',
          prop:'updatedAt'
        }
      ],
      pushedToUsers:[
        {
          label:'Yes',
          value:true
        },
        {
          label:'No',
          value:false
        },
      ],
      total:0,
      formInline: {
        updatedAt:null,
        isPush:null,
        type:null,
        likeKeywords:null,
        adminNameLike:null,
       _start:1,
       _limit:10,

      },
      tableData:[],
      tableDataAll:[],
    };
  },
  watch:{
    formInline:{
      handler(v){
        if(v!=null)
        this.getOperationHistories()
        this.getAllOperationHistories()
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    getOperationHistories(){
      let from= {
        _start:this.formInline._start-1,
        _limit:this.formInline._limit,
        updatedAt:this.formInline.updatedAt,
        adminNameLike:this.formInline.adminNameLike,
        isPush:this.formInline.isPush,
        type:this.formInline.type,
        likeKeywords:this.formInline.likeKeywords,
      }
      getOperationHistories(from).then((v)=>{
          this.tableData = v.data
          this.total = Number(v.headers["x-total-count"]);
      })
    },
    getAllOperationHistories(){
      let from= {
        _start:0,
        _limit:99999999999999,
        adminNameLike:this.formInline.adminNameLike,
        updatedAt:this.formInline.updatedAt,
        isPush:this.formInline.isPush,
        type:this.formInline.type,
        likeKeywords:this.formInline.likeKeywords,
      }
      getOperationHistories(from).then((v)=>{
          this.tableDataAll = v.data
          // this.total = Number(v.headers["x-total-count"]);
      })
    },
  }
};
</script>

<style lang="scss" scoped>
.disclaimer-box{
    padding-top:30px;
    border-top: 1px solid #D9D9D9;
}
</style>