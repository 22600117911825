<template>
  <div>
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <el-button type="primary" icon="el-icon-plus" @click="add">New</el-button>
    </Header-Back>

    <div class="search-func">
      <div class="search">
        <div class="label">Search</div>
        <div class="value">
          <el-input prefix-icon="el-icon-search" v-model="listQ.like_keywords" @input="getData" placeholder="Keywords"></el-input>
        </div>
      </div>
      <div class="date">
        <div class="label">Date</div>
        <div class="value">
          <el-date-picker v-model="listQ.date" @change="getData" type="year" style="width: 100%"></el-date-picker>
        </div>
      </div>
    </div>

    <div>
      <el-table :data="tableData" style="width: 100%" empty-text="No record">
        <el-table-column :prop="its.prop" :label="its.label" :width="its.width" 
          v-for="(its, idx) in tableCate" :key="idx">
        </el-table-column>
        <el-table-column label="Edit" width="100">
          <!-- slot-scope="scope" -->
          <template slot-scope="scope">
            <img src="@/assets/images/Edit - large.png" @click="edit(scope.row)" class="table-icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="Delete" width="100">
          <!-- slot-scope="scope" -->
          <template slot-scope="scope">
            <img src="@/assets/images/mingcute_delete-2-fill.png" @click="del(scope.row.id)" class="table-icon" alt="">
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :autoScroll='false'
        :page.sync="listQ._start"
        :limit.sync="listQ._limit"
        layout="total, prev, pager, next"
        @pagination="getData"
      />
    </div>
    <disclaimer v-if="pageId" :id="pageId" :as="false"></disclaimer>
  </div>
</template>

<script>
import disclaimer from '@/components/disclaimer'

import { getSystemicSafetyAlerts, deleteSystemicSafetyAlerts } from '@/api/material.js'

export default {
  components:{disclaimer},
  data() {
    return {
      // keyword: "",
      // date: "",
      tableCate: [
        {label: 'ID', prop: 'id', width: '100'},
        {label: 'Title', prop: 'title_en', width: ''},
        {label: 'Document No.', prop: 'documentNumber', width: '150'},
        {label: 'Date', prop: 'date', width: '140'},
        {label: 'Push to user', prop: 'isPushToUser', width: '150'},
      ],
      tableData:[
        {},
      ],
      total: 0,
      listQ:{
        page_id: this.$route.params.id,
        like_keywords: '',
        date: '',
        _start: 1,
        _limit: 20,
      },

      pageId: null,
      pageTitle: 'Systemic Safety Alert',
    };
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId
      this.listQ = {
        page_id: this.pageId,
        like_keywords: '',
        date: '',
        _start: 1,
        _limit: 20,
      }

      this.getData()  
    }
  },
  methods: {
    add() {
      this.$router.push(`/template/ssa/${this.pageId}/add`)
    },
    edit(row) {
      this.$router.push(`/template/ssa/${this.pageId}/${row.id}/edit`)
    },
    del(id) {
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }
      ).then(() => {
        //刪除
        deleteSystemicSafetyAlerts(id).then(()=>{
             this.$message({
              type: 'success',
              message: 'Successfully deleted!'
            })
            this.getData()
        })
      })
    },
    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName
      let {listQ} = this, 
      form = {
        page_id: this.pageId,
        like_keywords: listQ.like_keywords,
        date: listQ.date ? new Date(listQ.date).format('yyyy') : '',
        _start: listQ._start - 1,
        _limit: listQ._limit,
      };
      console.log(form)

      getSystemicSafetyAlerts(form).then(v => {
        v.data.filter(its => {
          its.title_en = its.title.en
          its.title_zhHant = its.title.zhHant
          its.title_zhCn = its.title.zhCn
          its.isPushToUser = its.isPushToUser ? 'Yes' : 'No'
          its.date = new Date(its.date).format('yyyy-MM')
        })

        this.tableData = v.data
        this.total = Number(v.headers['x-total-count'])
        console.log(this.tableData)
      })

    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.disclaimer-box{
  padding-top:30px;
  border-top: 1px solid #D9D9D9;
}
.page-img-button{
  margin: 0 1px;
}
.el-pagination {
  padding-top: 20px;
  padding-block: 20px;
  text-align: center; 
}
.save-button {
  padding: 20px 150px;
  background-color: #184c84;
}
.input-class {
  width: 490px;
}
.search-func {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .search {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .date {
    width: 35%;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}
</style>