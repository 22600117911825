<template>
  <div class="import-edit">
    <Header-Back pageTitle="New">
      <el-button type="primary" @click="save"> Save </el-button> 
    </Header-Back>

    <div class="tab-nav">
      <div :class="['nav', importCate == 0 && 'select-nav']" @click="importCate = 0">Content</div>
      <div :class="['nav', importCate == 1 && 'select-nav']" @click="importCate = 1">Gallery</div>
    </div>

    <Content v-if="importCate == 0" :form="ruleForm" ref="content" @unifiedData="unifiedData"></Content>
    <Gallery v-if="importCate == 1" :obj="ruleForm.galleries" @unifiedData="unifiedData" ref="gallery"></Gallery>
    <!-- <el-button type="primary" @click="save"> Save </el-button>  -->
  </div>
</template>

<script>
import Content from './components/content.vue'
import Gallery from './components/gallery.vue'
import { addImportantAnnouncements } from '@/api/material'

export default {
  components: {
    Content,
    Gallery
  },
  data() {
    return {
      importCate: 0,
      ruleForm: {
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        date: '',
        content_en: '',
        content_zhHant: '',
        content_zhCn: '',
        push_message: false,
        push_message_en: '[Important Announcement]',
        push_message_zhHant: '[重要公告]',
        push_message_zhCn: '[重要公告]',
        documents: [
          {
            type: 0,
            link_en: '',
            link_zhHant: '',
            link_zhCn: '',
          }
        ],
        galleries: [
          {
            image_url_en: '',
            image_url_zhHant: '',
            image_url_zhCn: '',
            link_en: '',
            link_zhHant: '',
            link_zhCn: '',
            transcript_en: '',
            transcript_zhHant: '',
            transcript_zhCn: '',
            description_en: '',
            description_zhHant: '',
            description_zhCn: '',
            type: 'video',
            display_sort: 0
          }, 
          {
            image_url_en: '',
            image_url_zhHant: '',
            image_url_zhCn: '',
            description_en: '',
            description_zhHant: '',
            description_zhCn: '',
            type: 'photo',
            display_sort: 1
          }
        ]
      }
    }
  },
  methods: {
    // 统一数据
    unifiedData(moduleName, data) {
      data = JSON.parse(JSON.stringify(data))
      if(moduleName == 'content') {
        data.galleries = this.ruleForm.galleries
        this.ruleForm = {
          ...data,
        }
      } else {
        this.ruleForm.galleries = data
      }
    },
    // 循环查询
    cyclicQuery(obj) {
      let whiteList = ['push_message']
      for(let i in obj) {
        if(whiteList.includes(i)) continue;
        if(obj[i] == '') {
          return false
        }
      }

      return true
    },

    async save() {
      let content = this.$refs.content,
      gallery = this.$refs.gallery

      if(this.importCate == 0)
        this.unifiedData('content', content.ruleForm)
      else {
        this.unifiedData('gallery', gallery.galleries)
      }

      let ruleForm = this.ruleForm,
      form = {
        page_id: Number(this.$route.params.pageId),
        "date": ruleForm.date ? new Date(ruleForm.date).format('yyyy-MM-dd') : '',
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "content": {
          "en": ruleForm.content_en,
          "zh_hant": ruleForm.content_zhHant,
          "zh_cn": ruleForm.content_zhCn
        },
        "is_push_to_user": ruleForm.push_message,
        "push_message": {
          "en": ruleForm.push_message_en,
          "zh_hant": ruleForm.push_message_zhHant,
          "zh_cn": ruleForm.push_message_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            type: its.type,
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            }
          }
        })],
        "gallery_photos": [],
        "gallery_videos": []
      }

      ruleForm.galleries && ruleForm.galleries.filter(its => {
        if(its.type == 'video') {
          form.gallery_videos.push({
            "image_url": {
              "en": its.image_url_en || '',
              "zh_hant": its.image_url_zhHant || '',
              "zh_cn": its.image_url_zhCn || ''
            },
            "display_sort": its.display_sort,
            "transcript_url": {
              "en": its.transcript_en,
              "zh_hant": its.transcript_zhHant,
              "zh_cn": its.transcript_zhCn
            },
            "video_link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "description": {
              "en": its.description_en,
              "zh_hant": its.description_zhHant,
              "zh_cn": its.description_zhCn
            }
          })
        } else {
          form.gallery_photos.push({
            "image_url": {
              "en": its.image_url_en || '',
              "zh_hant": its.image_url_zhHant || '',
              "zh_cn": its.image_url_zhCn || ''
            },
            "display_sort": its.display_sort,
            "description": {
              "en": its.description_en,
              "zh_hant": its.description_zhHant,
              "zh_cn": its.description_zhCn
            }
          })
        }
      })
      console.log(ruleForm.galleries)
      console.log(form)
      
      if(this.importCate == 0) {
        content.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            addImportantAnnouncements(form).then(() => {
              this.$router.go(-1)
            })
          }
        })
      } else {
        // 表单校验
        let ruleForms = gallery.$refs['ruleForm'], is = true
        for(let i = 0; i < ruleForms.length; i++) {
          try {
            await ruleForms[i].validate()
          } catch {
            is = false
          }
        }
        if(is)
        addImportantAnnouncements(form).then(() => {
          this.$router.go(-1)
        })
        
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.tab-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.875rem;
  
  .nav {
    width: calc(50% - .3125rem);
    padding: .625rem;
    text-align: center;
    border-bottom: 3px solid #F0F0EF;
    font-size: 22px;
    font-weight: 700;
    color: #6D6D6D;
    cursor: pointer;
  }

  .select-nav {
    border-bottom-color: #124B80;
    color: #124B80;
  }
}
</style>