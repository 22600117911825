<template>
  <div class="box">
    <el-button type="primary" @click="updateImg"> Browse </el-button> 

    <div class="browse" v-if="browse_switch && url" @click="browse_switch = false">
      <img :src="url" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
    },
    suffix: {
      type: Array,
      default: () => ['image/png', 'image/jpg', 'image/bmp', ]
    },

  },
  data() {
    return {
      browse_switch: false
    }
  },
}
</script>

<style lang="scss" scoped>
.browse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .4);

  img {
    max-height: 50%;
  }
}
</style>