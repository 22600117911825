<template>
  <div class="new-section">
    <Header-Back :hideBack="true" pageTitle="Adding New Section"></Header-Back>

    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" label-position="top" class="demo-ruleForm">
      <el-form-item>
        <div class="icon">
          <div class="img">
            <img :src="ruleForm.icon_url" v-if="ruleForm.icon_url" alt="">
            <img src="@/assets/images/edit.png" class="edit" alt="" @click="update">
          </div>

          <div class="icon-description">
            <span>Type: PNG, JPG or BMP</span>
            <span>Maximum file size:  5MB</span>
            <span>Dimension: at least 240px(H) x 240px(W)</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Title - English">
        {{ ruleForm.en }}
      </el-form-item>
      <el-form-item label="Title - Traditional Chinese">
        {{ ruleForm.zhHant }}
      </el-form-item>
      <el-form-item label="Title - Simplified Chinese">
        {{ ruleForm.zhCn }}
      </el-form-item>
      <el-form-item label="Template">
        <span v-for="(its, idx) in ruleForm.templates" :key="idx">{{ its.title }}, </span>
      </el-form-item>
      <el-form-item label="Path">
        <span v-for="(its, idx) in ruleForm.pages" :key="idx">{{ its.path }}, </span>
      </el-form-item>
    </el-form>

    <div class="btns">
      <div class="left">
        <el-button type="primary" @click="$router.go(-2)">Back to [Previous Page]</el-button> 
        <el-button type="primary" @click="$router.push('/overview')">Back to Overview</el-button> 
      </div>

      <el-button type="primary"  @click="toTemplatePage">Start Editing</el-button> 
    </div>
  </div>
</template>

<script>
import { updateFile } from '@/utils/upload'
import { getSectionsDetail } from '@/api/section.js'

export default {
  data() {
    return {
      options: [],
      ruleForm: {
        icon_url: '',
        en: '',
        "zh_hant": "",
        "zh_cn": "",
        template_id: 0,
        path: ''
      },
    }
  },
  methods: {
    toTemplatePage() {
      console.log(this.ruleForm)
      let {pages} = this.ruleForm,
      [page] = pages,
      type = ['textVersion', 'animation', 'ssa', 'csa', 'osh', 'ia', 'NotifyYourWorkplace'],
      route = ''
      switch(page.type) {
        case 0:
          route = `/template/wsa/${type[0]}/${page.id}?sectionName=${ this.ruleForm.en }`
          break;
        default : 
          route = `/template/${type[page.type]}/${page.id}?sectionName=${ this.ruleForm.en }`
          break;
      }
      this.$router.push(route)
    },
    update() {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/svg+xml'], 
      area = {width: 240, height: 240}, maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        this.ruleForm.icon_url = res
      })
    },
    getData() {
      let id = this.$route.query.id
      console.log(this.$route.query)

      getSectionsDetail(id).then(v => {
        this.ruleForm = {
          icon_url: v.data.iconUrl,
          en: v.data.title.en,
          "zhHant": v.data.title.zhHant,
          "zhCn": v.data.title.zhCn,
          templates: v.data.templates,
          page: v.data.page,
          pages: v.data.pages
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .img {
    width: 100px;
    height: 100%;
    position: relative;
    background-color: #EBEBEB;
    margin-right: 40px;

    img {
      width: 100%;
      height: 100%;
    }

    .edit {
      width: 24px;
      height: auto;
      position: absolute;
      bottom: -3px;
      right: -3px;
      cursor: pointer;
    }
  }

  .icon-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    * {
      display: block;
    }
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>