import textVersion from '@/views/template/WSA/TextVersion/text-version'
import textVersionEdit from '@/views/template/WSA/TextVersion/textVersionEdit'
import textVersionAdd from '@/views/template/WSA/TextVersion/textVersionAdd'
import textVersionDetail from '@/views/template/WSA/TextVersion/textVersionDetail'
import animation from '@/views/template/WSA/Animation/animation'
import animationEdit from '@/views/template/WSA/Animation/animationEdit'
import animationAdd from '@/views/template/WSA/Animation/animationAdd'
import ia from '@/views/template/IA'
import iaAdd from '@/views/template/IA/add'
import iaEdit from '@/views/template/IA/edit'
import iaPreview from '@/views/template/IA/preview'
import systemicSafetyAlert from '@/views/template/SSA/systemicSafetyAlert'
import systemicSafetyAlertEdit from '@/views/template/SSA/systemicSafetyAlertEdit'
import systemicSafetyAlertAdd from '@/views/template/SSA/systemicSafetyAlertAdd'
import systemicSafetyAlertDetail from '@/views/template/SSA/systemicSafetyAlertDetail'
import csa from '@/views/template/CSA/index'
import csaEdit from '@/views/template/CSA/edit'
import csaAdd from '@/views/template/CSA/add'
import osh from '@/views/template/OSHTraining/index'
import folder from '@/views/template/OSHTraining/folder'
import fileEdit from '@/views/template/OSHTraining/file/edit'
import fileAdd from '@/views/template/OSHTraining/file/add'
import pageEdit from '@/views/template/OSHTraining/page/edit'
import pageAdd from '@/views/template/OSHTraining/page/add'
import insertFolder from '@/views/template/OSHTraining/page/insertFolder'
import editPage from '@/views/template/OSHTraining/folder/editPage'
import folderEdit from '@/views/template/OSHTraining/folder/edit'
import folderAdd from '@/views/template/OSHTraining/folder/add'
import notifyYourWorkplace from '@/views/template/NotifyYourWorkplace/notifyYourWorkplace'
export default [
  // WSA
  // Text version
  {
    path: '/template/wsa/textVersion/:pageId', component: textVersion
  },
  {
    path: '/template/wsa/textVersion/:pageId/:id/edit', component: textVersionEdit
  },
  {
    path: '/template/wsa/textVersion/:pageId/add', component: textVersionAdd
  },
  {
    path: '/template/wsa/textVersion/:pageId/:id/preview', component: textVersionDetail
  },

  // Animation
  {
    path: '/template/wsa/animation/:pageId', component: animation
  },
  {
    path: '/template/wsa/animation/:pageId/:id/edit', component: animationEdit
  },
  {
    path: '/template/wsa/animation/:pageId/add', component: animationAdd
  },

  // IA
  {
    path: '/template/ia/:pageId', component: ia
  },
  {
    path: '/template/ia/add/:pageId', component: iaAdd
  },
  {
    path: '/template/ia/:pageId/:id/edit', component: iaEdit
  },
  {
    path: '/template/ia/:pageId/:id/preview', component: iaPreview
  },

  // SSA
  {
    path: '/template/ssa/:pageId', component: systemicSafetyAlert
  },
  {
    path: '/template/ssa/:pageId/:id/edit', component: systemicSafetyAlertEdit
  },
  {
    path: '/template/ssa/:pageId/add', component: systemicSafetyAlertAdd
  },
  {
    path: '/template/ssa/systemicSafetyAlert/:pageId/:id/preview', component: systemicSafetyAlertDetail
  },

  // CSA
  {
    path: '/template/csa/:pageId', component: csa
  },
  {
    path: '/template/csa/:pageId/:id/edit', component: csaEdit
  },
  {
    path: '/template/csa/:pageId/add', component: csaAdd
  },

  // OSHT
  {
    path: '/template/osh/:pageId', component: osh
  },
  {
    path: '/template/osh/:pageId/folder/:id', component: folder
  },
  {
    path: '/template/osh/file/:pageId/:id/edit', component: fileEdit
  },
  {
    path: '/template/osh/file/:pageId/add', component: fileAdd
  },
  {
    path: '/template/osh/page/:pageId/:id/edit', component: pageEdit
  },
  {
    path: '/template/osh/page/:pageId/add', component: pageAdd
  },
  {
    path: '/template/osh/page/:pageId/add/:id', component: insertFolder
  },
  {
    path: '/template/osh/folderpage/:pageId/edit', component: editPage
  },
  {
    path: '/template/osh/folder/:pageId/:id/edit', component: folderEdit
  },
  {
    path: '/template/osh/folder/:pageId/add', component: folderAdd
  }, 
 

  // NWP
  {
    path: '/template/NotifyYourWorkplace/:pageId', component: notifyYourWorkplace
  },
]