<template>
  <div>
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <el-button type="primary" icon="el-icon-plus" @click="add">New</el-button>
    </Header-Back>

    <div class="search-func">
      <div class="search">
        <div class="label">Search</div>
        <div class="value">
          <el-input prefix-icon="el-icon-search" v-model="listQ.like_keywords"  @input="getData" placeholder="Keywords"></el-input>
        </div>
      </div>
      <div class="date">
        <div class="label">Subtitles</div>
        <div class="value">
          <el-select v-model="listQ.subtitle" placeholder="- Please select -" style="width: 100%" @change="getData" clearable>
            <el-option 
              v-for="item in options" 
              :key="item.label"
              :label="item.label" 
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="page-list" v-for="(its, idx) in tableData" :key="idx">
      <div class="left">
        <!-- <img class="page-img" :src="its.img"> -->
        <img class="page-img" :src="its.thumbnail">
      </div>
      <div class="center">
        <!-- {{ its.content }} -->
        <span v-if="its.title">{{ its.title.en }}</span>
      </div>
      <div class="right">
        <el-button type="info"  v-if="its.status == 0" @click="onDisable(its)">Disable</el-button>
        <el-button type="primary" v-else @click="onDisable(its)">Enable</el-button>
        <img class="page-img-button" src="@/assets/images/Edit - large.png" @click="edit(its)"/>  
        <img class="page-img-button" @click="onDelete(its.id)" src="@/assets/images/mingcute_delete-2-fill.png"/>  
      </div>
    </div>
    <Pagination v-if="tableData.length != 0"
      :total="total"
      :autoScroll='false'
      :page.sync="listQ._start"
      :limit.sync="listQ._limit"
      layout="total, prev, pager, next"
      @pagination="getData"
    />
    <disclaimer v-if="pageId" :id="pageId"></disclaimer>

  </div>
</template>

<script>
import disclaimer from '@/components/disclaimer'

import { getAnimations, deleteAnimations, disableAnimations, enableAnimations } from '@/api/material.js'

export default {
  components:{disclaimer},
  data() {
    return {
      formLabelAlign: {
        english: '',
        traditionalChinese: '',
        simplifiedChinese: ''
      },
      form: {
        subtitles: '',
      },

      subtitle: '0',
      options: [
        { value: 'English', label: 'English' },
        { value: '1', label: 'Chinese' },
        // { value: '2', label: 'Hindi' },
        // { value: '2', label: 'Nepali' },
        // { value: '2', label: 'Tagalog' },
        // { value: '2', label: 'Urdu' },
      ],

      date: '',
      keyword: '',
      tableData: [
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'}, 
        // {id: 1, img: require('@/assets/images/Rectangle 40.png'), content: 'Catering Safety Tips ~Burn Prevention~'},
      ],
      total: 0,

      listQ:{
        like_keywords: '',
        subtitle: '',
        _start: 1,
        _limit: 20,
      },
      pageId: null,
      pageTitle: 'Catering Safety Animation',
    };
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId
      this.listQ = {
        like_keywords: '',
        accident_date: '',
        _start: 1,
        _limit: 20,
      }

      this.getData()  
    }
  },
  methods: {
    onDelete(id){
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }
      ).then(() => {
        //刪除
        deleteAnimations(id).then(()=>{
             this.$message({
              type: 'success',
              message: 'Successfully deleted!'
            })
            this.getData()
        })
      })
    },
    onDisable(row){
      let {status, id} = row
      if(status == 0) {
        disableAnimations(id).then(() => {
          this.getData()
        })
      } else {
        enableAnimations(id).then(() => {
          this.getData()
        })
      }
    },
    add() {
      this.$router.push(`/template/csa/${this.pageId}/add`)
    },
    edit(row) {
      this.$router.push(`/template/csa/${this.pageId}/${row.id}/edit`)
    },

    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName
      let {listQ} = this, 
      form = {
        page_id: this.pageId,
        like_keywords: listQ.like_keywords,
        subtitle: listQ.subtitle,
        _start: listQ._start - 1,
        _limit: listQ._limit,
      };

      getAnimations(form).then((value) => {
        value.data.filter(its => {
          its.content = its.title.zhHant
        })
        this.tableData = value.data
        console.log(value)
        this.total = Number(value.headers['x-total-count'])
      })

      console.log(form)
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.search-func {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .search {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .date {
    width: 35%;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}

.page-list{
  margin-bottom: 20px;
  padding: 20px;
  background: var(--Light-Grey, #F5F5F5);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  $bl: 4.16%;
  .left {
    width: $bl * 3;
    
    .page-img {
      width: 100%;
      // height: 120px;
      object-fit: contain;
      flex-shrink: 0;
    }
  }

  .center {
    width: $bl * 15;

  }

  .right {
    width: $bl * 5;
    display: flex;
    align-items: center;
  }
}

.page-img-button{
  margin: 0 20px;
}

.disclaimer-box{
  padding-top:30px;
  border-top: 1px solid #D9D9D9;
}

.save-button {
  padding: 20px 150px;
  background-color: #184c84;
}
</style>