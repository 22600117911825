import editHistory from '@/views/report/edit-history'
import linkCheck from '@/views/report/link-check'
export default [
    {
        path:'/editHistory', component: editHistory
    },
    {
        path:'/linkCheck', component: linkCheck
    },
  
]