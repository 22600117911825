import http from '@/utils/http'

export function login(data) {
  return http.post('/sessions', data)
}
export function logout(params) {
  return http.delete('/sessions', { params })
}
export function resetPassword(data) {
  return http.post('/reset_password', data)
}
export function refresh(params) {
  return http.get('/access_token', { params })
}