<template>
  <div class="print">
    <form method="post" name="form1">
      <table border="1" cellspacing="0" align="center" valign="center" width="100%" style="table-layout:fixed;">
        <tr>
          <td colspan="3" align="center"><h3>(ID: {{ form.id }}) What's New</h3></td>
        </tr>
        <tr>
          <td colspan="3" align="center">
            <input type="button" @click="print" value="Print This Page">
          </td>
        </tr>

        <!-- title -->
        <tr class="module-name">
          <td colspan="3"> Title </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value" colspan="2"><span v-if="form.title">{{ form.title.en }}</span> </td>
          
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value" colspan="2"><span v-if="form.title">{{ form.title.zhHant }}</span>  </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value" colspan="2"><span v-if="form.title">{{ form.title.zhCn }}</span>  </td>
        </tr>

        <!-- Thumbnail -->
        <tr class="module-name">
          <td colspan="3"> Thumbnail </td>
        </tr>
        <tr>
          <td class="label" style="text-align:center;"> EN :</td>
          <td class="label" style="text-align:center;"> TC :</td>
          <td class="label" style="text-align:center;"> SC :</td>
        </tr>
       
        <tr>
          <td class="value"> <el-image v-if="form.thumbnail" :src="form.thumbnail.en"></el-image> </td>
          <td class="value"> <el-image form.thumbnail :src="form.thumbnail.zhHant"></el-image> </td>
          <td class="value"> <el-image form.thumbnail :src="form.thumbnail.zhCn"></el-image> </td>
        </tr>

        <!-- Date of Accident -->
        <tr class="module-name">
          <td colspan="3"> Schedule Publish Date & Time </td>
        </tr>
        <tr>
          <td class="label"> Schedule Publish Date & Time :</td>
          <td class="value" colspan="2"><span>{{form.schedulePublishDateTime | formatDateTime}}</span>  </td>
        </tr>

        <!-- Place of Accident -->
        <tr class="module-name">
          <td colspan="3"> Content </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value" colspan="2"> <span v-if="form.content" class="span-text" v-html="form.content.en"></span> </td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value" colspan="2"> <span v-if="form.content" class="span-text" v-html="form.content.zhHant"></span> </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value" colspan="2"> <span v-if="form.content" class="span-text" v-html="form.content.zhCn"></span> </td>
        </tr>

        <!-- Push Notification and Share Message -->
        <tr class="module-name" style="--color: #ddecd5">
          <td colspan="3"> Push Notification and Share Message </td>
        </tr>
        <tr>
          <td class="label"> Push to user :</td>
          <td class="value" colspan="2">
            <label>
              <input type="checkbox" id="checkbox1">
              <span class="custom-checkbox" :style="`--cte: ${form.isPushToUser ? '\'\\2713\'' : ''}`"></span> 
            </label>
          </td>
        </tr>
        <tr>
          <td class="label"> EN :</td>
          <td class="value" colspan="2"> <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.en"></span> </td>
        </tr>
        <tr>
          <td class="label"> TC :</td>
          <td class="value" colspan="2"> <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.zhHant"></span> </td>
        </tr>
        <tr>
          <td class="label"> SC :</td>
          <td class="value" colspan="2"> <span v-if="form.pushMessage" class="span-text" v-html="form.pushMessage.zhCn"></span> </td>
        </tr>

        <!-- Links -->
        <tr class="module-name" style="--color: #fff0c7">
          <td colspan="3"> Link(s) </td>
        </tr>
        <tbody v-for="(its, index) in form.links" :key="index">
          <tr>
            <td class="label"> Type of Link :</td>
            <td class="value" colspan="2"> {{ types[its.type] }} </td>
          </tr>
          <tr>
            <td class="label"> EN :</td>
            <td class="value" colspan="2"> <edit-div v-model="its.link.en"></edit-div> </td>
          </tr>
          <tr>
            <td class="label"> TC :</td>
            <td class="value" colspan="2"> <edit-div v-model="its.link.zhHant"></edit-div> </td>
          </tr>
          <tr>
            <td class="label"> SC :</td>
            <td class="value" colspan="2"> <edit-div v-model="its.link.zhCn"></edit-div> </td>
          </tr>
        </tbody>

      </table>
        <div style="width: 854px; text-align: right; margin: auto">Print date and time: {{ time }} </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      types: ['PDF', 'External Link', 'Photo', 'Video'],
      form: {
        title: {},
        summary: {}
      },

      time: ''
    }
  },
  methods: {
    getCurTime() {
      let date = new Date(),
      Y = date.getFullYear(),
      M = date.getMonth() + 1,
      D = date.getDate(),
      h = date.getHours(),
      m = date.getMinutes()

      M = this.singleConvertDouble(M)
      D = this.singleConvertDouble(D)
      h = this.singleConvertDouble(h)
      m = this.singleConvertDouble(m)

      this.time = `${D}/${M}/${Y} ${h}:${m}`;
    },
    listenerTime() {
      setInterval(() => {
        this.getCurTime()
      }, 1000)
    },
    singleConvertDouble(char) {
      char = String(char)
      if(char.length == 1) {
        return '0' + char
      }
      return char
    },
    print() {
      window.print()
    }
  },
  created() {
    this.form = JSON.parse(localStorage.getItem('printNew'))
  },
  mounted() {
    this.getCurTime()
    this.listenerTime()
  }
}
</script>

<style lang="scss" scoped>
.print {
  padding-bottom: 50px;
}
table {
  margin-top: 40px; 
  margin-bottom:20px;
  width: 854px;
}
.module-name {
  background-color: var(--color, #c7c2c2);
}

.label {
  width: 150px;
}

.colon {
  width: 10px;
}

.value {
  // width: 717px;
  overflow-wrap: anywhere;
}

.order {
  color: #f00;
}

/* 隐藏默认复选框 */
input[type="checkbox"] {
  display: none;
}

/* 自定义复选框的外观 */
.custom-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  // border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000;
  box-sizing: border-box;
  text-align: center;
}

/* 复选框选中状态的样式 */
.custom-checkbox.checked {
  background-color: #007bff;
}

/* 复选框内的勾选标记 */
.custom-checkbox::after {
  content: var(--cte);
  font-size: 16px;
  color: #333;
  display: block;
  text-align: center;
  line-height: 18px;
}

.hide-icon:after {
  content: '';
}
</style>