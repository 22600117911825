import http from '@/utils/http'

export function getExportNews(params){
    return http.get('/export_news',{params})
}
export function getNews(params){
    return http.get('/news',{params})
}
export function addNews(data){
    return http.post('/news',data)
}
export function editNews(id,data){
    return http.put(`/news/${id}`,data)
}
export function deleteNews(id){
    return http.delete(`/news/${id}`)
}
export function getNewsDetail(id){
    return http.get(`/news/${id}`)
}
