import moment from 'moment'
/* 时间过滤器 - 日/月/年 */
export function formatDate(value) {
  return value ? moment(value).format('YYYY-MM-DD') : ''
}

export function formatDateTime(value) {
  return value ? moment(value).format('YYYY/MM/DD HH:mm') : ''
}

export function formatTime(value) {
  return value ? moment(value).format('HH:mm:ss') : ''
}

///html标签替换
export function richText(value){
  return  value !==''? value.replace(/<.+?>/g, ''): '';
}

///获取文件名称
export function getFileName(filePath) {
  let arr = filePath.split('/')
  let fileName = arr[arr.length - 1]
  return fileName
}
export function getZipFileName(filePath) {
  let arr = filePath.split('/')
  let fileName = arr[arr.length - 2]
  return fileName + '.zip'
}

export function typeFilters(v){
  switch(v){
    case 0:
      return 'PDF';
    case 1:
      return 'External Link';
    case 2:
      return 'Photo';
    case 3:
      return 'Video';
  }
}

export function adminPermissionStatus(v){
  switch (v) {
    case 0:
      return 'NO Suspend';
    case 1:
      return 'Suspend';
    default:
      break;
  }
}
export function linkCheckStatus(v){
  switch (v) {
    case 0:
      return 'Idle';
    case 1:
      return 'Completed';
    case 2:
      return 'Failed';
    case 3:
      return 'Unexpected errors';
    default:
      break;
  }
}
export function materialType(value) {
  switch (value) {
    case 0:
      return 'important_announcement';
    case 1:
      return 'wsa';
    case 2:
      return 'osh_training';
    case 3:
      return 'news';
    case 4:
      return 'notify_your_workplace';
    case 5:
      return 'systemic_safety_alert';
    default:
      break;
  }
}
export function position(value) {
  switch (value) {
    case 0:
      return 'Admin ';
    case 1:
      return 'Master Admin';
    default:
      break;
  }
}

export function historyType(value) {
  switch (value) {
    case 0:
      return 'WSA - Text Version';
    case 1:
      return 'WSA - Animation';
    case 2:
      return 'SSA';
    case 3:
      return "CSA";
    case 4:
      return "OSH Training";
    case 5:
      return "IA";
    case 6:
      return "Notify Your Workplace";
    case 7:
      return "What's New";
    default:
      break;
  }
}
export function pushNotificationListType(value) {
  switch (value) {
    case 0:
      return 'WSA - Text Version';
    case 1:
      return 'SSA';
    case 2:
      return 'IA';
    case 3:
      return "What's New";
    default:
      break;
  }
}
export function newType(value) {
  switch (value) {
    case 0:
      return 'pdf';
    case 1:
      return 'external-link';
    case 2:
      return 'photo';
    case 3:
      return 'video';
    default:
      break;
  }
}
export function noticesType(value) {
  switch (value) {
    case 0:
      return 'Disclaimer';
    case 1:
      return 'Copyright Notice';
    case 2:
      return 'Privacy Policy';
    case 3:
      return 'Accessibility Statement';
    default:
      break;
  }
}
export function interstitialAdsType(value) {
  switch (value) {
    case 0:
      return 'Landing';
    case 1:
      return 'Important Announcement';
    case 2:
      return 'Work Safety Alert';
    
    case 3:
      return 'Online OSH Complaint Form';
    
    case 4:
      return 'Systemic Safety Alert';
    
    case 5:
      return 'Catering Safety Animation';
    
    case 6:
      return 'OSH Training';
    
    default:
      break;
  }
}

export function editHistoryType(value) {
  switch (value) {
    case 0:
      return 'WSA - Text Version';
    case 1:
      return 'WSA - Animation';
    case 2:
      return 'SSA';
    
    case 3:
      return 'CSA';
    
    case 4:
      return 'OSH Training';
    
    case 5:
      return 'IA';
    
    case 6:
      return 'Notify Your Workplace';
    case 7:
      return 'What’s New';
    
    default:
      break;
  }
}

export function isPushedToUser(value) {
  return value?'Yes':'No'
}


///判断输入值是否为正整数
export function isPositiveInteger(s){
  
  if((/(^[1-9]\d*$)/.test(s))) {
     return `+${s}`
  } else {
    return s
  }
  
}   

//// 全字母大写
export function toUpperCase(value) {
   return   value.slice(0,1).toUpperCase() +value.slice(1).toLowerCase(); 
}
//// 全字母大写
export function replace(value) {
   return value !==''? value.replace(/zh/g,''): ''
}

///设置绝对值

export function abs(value){
   return Math.abs(value); 
}

export function ellipsis(value){
  if (!value) return '';
  if (value.length > 100) {
    return value.slice(0,100) + '...'
  }
  return value
}

export function toFixed(value){
  return value.toFixed(1)
}