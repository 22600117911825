<template>
  <div class="edit-page">
    <Header-Back :pageTitle="pageTitle"></Header-Back>

    <PageForm ref="PageForm"></PageForm>

    <div class="">
      <el-button type="primary" @click="save"> {{ id ? 'Save' : 'Create' }} </el-button>
    </div>
  </div>
</template>

<script>
import PageForm from '../compoments/pageForm.vue'
import { mapState, mapMutations } from 'vuex'
import { addAshTrainings, getAshTrainingsDetail, editAshTrainings } from '@/api/material'

export default {
  props: ['id'],
  components: {
    PageForm
  },
  data() {
    return {
      form: {
        page_id: 0,
        parent_id: 0,
        type: 0,
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        description_en: '',
        description_zhHant: '',
        description_zhCn: '',
        documents: [
          {
            title_en: '',
            title_zhHant: '',
            title_zhCn: '',
            subtitle_en: '',
            subtitle_zhHant: '',
            subtitle_zhCn: '',
            type: 0,
            link_en: '',
            link_zhHant: '',
            link_zhCn: '',
            sorting_order: 0
          }
        ],
        sub_pages_ids: []
      },
      pageTitle: 'New Page',
      pageId: null
    }
  },
  computed: {
    ...mapState(['TempOSHpage'])
  },
  methods: {
    ...mapMutations(['setTempOSHpage']),
    save() {
      let child = this.$refs.PageForm,
      { ruleForm } = child,
      form = {
        id: ruleForm.id,
        "page_id": Number(this.pageId),
        "type": 1,
        "title": {
          "en": ruleForm.title_en,
          "zh_hant": ruleForm.title_zhHant,
          "zh_cn": ruleForm.title_zhCn
        },
        "description": {
          "en": ruleForm.description_en,
          "zh_hant": ruleForm.description_zhHant,
          "zh_cn": ruleForm.description_zhCn
        },
        "documents": [...ruleForm.documents.map(its => {
          return {
            "title": {
              "en": its.title_en,
              "zh_hant": its.title_zhHant,
              "zh_cn": its.title_zhCn
            },
            "subtitle": {
              "en": its.subtitle_en,
              "zh_hant": its.subtitle_zhHant,
              "zh_cn": its.subtitle_zhCn
            },
            "type": Number(its.type),
            "link": {
              "en": its.link_en,
              "zh_hant": its.link_zhHant,
              "zh_cn": its.link_zhCn
            },
            "sorting_order": its.sorting_order
          }
        })].sort((a, b) => a.sorting_order - b.sorting_order),
        "sub_pages_ids": ruleForm.sub_pages_ids || []
      }

      if(ruleForm.id) {
        child.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            editAshTrainings(ruleForm.id, form).then(() => {
              this.$emit('tabPage', 'page', form)
            })
          }
        })
      } else {
        child.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            addAshTrainings(form).then((v) => {
              form.id = v.data.id
              this.$emit('tabPage', 'page', form)
            })
          }
        })
      }
    },
    getData() {
      let id = this.id
      getAshTrainingsDetail(id).then(v => {
        this.form = {
          id: v.data.id,
          title_en: v.data.title.en,
          title_zhHant: v.data.title.zhHant,
          title_zhCn: v.data.title.zhCn,
          description_en: v.data.description.en,
          description_zhHant: v.data.description.zhHant,
          description_zhCn: v.data.description.zhCn,
          documents: [...v.data.documents.map(its => {
            return {
              title_en: its.title.en,
              title_zhHant: its.title.zhHant,
              title_zhCn: its.title.zhCn,
              subtitle_en: its.subtitle.en,
              subtitle_zhHant: its.subtitle.zhHant,
              subtitle_zhCn: its.subtitle.zhCn,
              type: its.type,
              link_en: its.link.en,
              link_zhHant: its.link.zhHant,
              link_zhCn: its.link.zhCn,
              sorting_order: its.sortingOrder
            }
          })],
          sub_pages_ids: v.data.subPagesIds
        }

        this.pageTitle = v.data.title.en

        this.$refs.PageForm.ruleForm = JSON.parse(JSON.stringify(this.form))
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
    console.log(this.id)
    if(this.id != undefined) {
      this.getData()
    } else {
      this.$refs.PageForm.ruleForm = JSON.parse(JSON.stringify(this.form))
    }
  }
}
</script>

<style lang="scss" >

</style>