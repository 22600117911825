<template>
  <div>
    <Header-Back pageTitle="Edit New"> 
       <span class="last-lint">Last Updated {{ form.updatedAt }}</span>
    </Header-Back>
    <newFrom :form="form" ref="form"></newFrom>
    <div class="btn-group">
      <el-button type="success" @click="onSave">Save</el-button>
      <el-button type="primary" @click="onPreview">Preview</el-button>
      <el-button type="danger" icon="el-icon-delete-solid" @click="onDelete">Delete</el-button>
    </div>
    
  </div>
</template>

<script>
import { getNewsDetail, editNews,deleteNews } from "@/api/news";
import newFrom from "./components/newFrom";
export default {
  components: { newFrom },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        title: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        // thumbnail: null,
        schedulePublishDateTime: null,
        isInstant: false,
        thumbnail: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        isPushToUser: false,
        pushMessage: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        type:0,
        links:[
          // {
          //     type: null, 
          //     link: {
          //         en: null, 
          //         zhHant: null, 
          //         zhCn: null
          //     }
          // }
        ]
      },
    };
  },
  created() {
    this.getNewsDetail()
    
  },
  methods: {
    onPreview(){
      this.$router.push({path:'/new/'+this.id} )
    },
    onDelete(){
      this.deleteNews()
    },
    onSave(){
       this.$refs.form.$refs.form.validate((valid) => {
          if (valid) {
           this.editNews()
          } else {
           this.$nextTick(() => {
            const isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
            return false;
          }
        });
     
    },
    formattedForm() {
      this.languages.forEach((key) => {
        this.form.title[key] = this.form[`title${key}`];
        this.form.content[key] = this.form[`content${key}`];
        this.form.thumbnail[key] = this.form[`thumbnail${key}`];
        this.form.pushMessage[key] = this.form[`pushMessage${key}`];
        // this.form.link[key] = this.form[`link${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(this.form, `title${key}`, this.form.title[key]);
        this.$set(this.form, `content${key}`, this.form.content[key]);
        this.$set(this.form, `thumbnail${key}`, this.form.thumbnail[key]);
        this.$set(this.form, `pushMessage${key}`, this.form.pushMessage[key]);
        // this.$set(this.form, `link${key}`, this.form.link[key]);
      });
    },
    deleteNews(){
      deleteNews(this.id).then(() => {
        this.$router.push({path:'/new'} )
      })
    },
    getNewsDetail() {
      getNewsDetail(this.id).then((v) => {
        this.form = v.data
        this.initializeForm();
      });
    },
    editNews() {
      this.formattedForm();
      editNews(this.id, this.form).then(() => {
        this.$router.push({path:'/new'} )
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-group{
  margin-top:20px;
}
</style>