import account from '@/views/setting/account'
import adminPermission from '@/views/setting/admin-permission'
import adminPermissionAdd from '@/views/setting/admin-permission-add'
import adminPermissionEdit from '@/views/setting/admin-permission-edit'
import shareMessage from '@/views/setting/share-message'
export default [
    {
        path:'/account', component: account
    },
    {
        path:'/adminPermission', component: adminPermission
    },
    {
        path:'/adminPermission/adminPermissionAdd', component: adminPermissionAdd
    },
    {
        path:'/adminPermission/:id/edit',props: (route) => ({ id: Number(route.params.id) }), component: adminPermissionEdit
    },
    {
        path:'/shareMessage', component: shareMessage
    },
]