import {languages} from '@/biz/static-data' 
import { mapMutations } from 'vuex'
import { getSections } from '@/api/section.js'
import { setUserInfo } from "@/utils/auth";
import { getAdminsDetail } from '@/api/user.js'

export const mixin = {
  data() {
    return {
      languages:[],
      historyType:[
        {
          label: 'WSA - Text Version',
          value: 0
        },
        {
          label: 'WSA - Animation',
          value: 1
        },
        {
          label: 'SSA',
          value: 2
        },
        {
          label: "CSA",
          value: 3
        },
        {
          label: "OSH Training",
          value: 4
        },
        {
          label: "IA",
          value: 5
        },
        {
          label: "Notify Your Workplace",
          value: 6
        },
        {
          label: "What's New",
          value: 7
        },
      ],
      pushNotificationListType:[
        {
          label: 'WSA - Text Version',
          value: 0
        },
        {
          label: 'SSA',
          value: 1
        },
        {
          label: 'IA',
          value: 2
        },
        {
          label: "What's New",
          value: 3
        },
      ],
      TemplateType: [
        {id: -1, label: '- Internel page -'},
        {id: 0, label: 'WSA'},
        // {id: 1, label: 'WSA - Animation'},
        // {id: 1, label: 'SSA'}, 
        // {id: 2, label: 'CSA'},
        // {id: 3, label: 'OSH Training'},
        {id: 4, label: 'IA'},
        // {id: 5, label: 'Notify Your Workplace'},
      ],
    }
  },
  created(){
    this.languages = languages
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  methods: {
    ...mapMutations(['setSection']),
    onBack() {
      this.$router.go(-1)
    },
    // 查询栏目
    getSection() {
      getSections().then(v => {
        let res = v.data
        // 过滤栏目
        // console.log(this.adminUserInfo)
        if(this.adminUserInfo.position == 2) {
          let roleSections = this.adminUserInfo.roleSections
          res = v.data.filter(its => roleSections.includes(its.id) && its)
        }
        res.sort((cur, next) => cur.sort - next.sort)
        this.setSection(res)
      })
    },
    // 获取管理员权限
    getAdminRole(callBack = null, row = null) {
      let id
      if(!callBack) {
        id = this.adminUserInfo.id
        row = JSON.parse(JSON.stringify(this.adminUserInfo))
      } else {
        id = row.id
      }
      
      getAdminsDetail(id).then(v => {
        row.position = v.data.roleId
        row.roleSections = v.data.sections.map(its => its.id)
        this.$store.dispatch("setAdminUserInfo", row);
        setUserInfo('userInfo', row)
        if(callBack != null) {
          callBack(v)
        } else {
          this.$router.push({ path: "/overview" });
        }
      })
    }
  }
}
