<template>
  <div>
    <Header-Back pageTitle="New"></Header-Back>

    <animationFrom :form="form" ref="animationFrom"></animationFrom>

    <div class="page-button">
      <el-button type="primary" @click="save">Save</el-button>
    </div>
  </div>
</template>

<script>
import animationFrom from "./components/animationFrom";
import { addAnimations } from "@/api/material";

export default {
  components: {
    animationFrom,
  },
  data() {
    return {
      form: {
        en: '',
        zhHant: '',
        zhCn: '',
        thumbnail: '',
        english: '',
        chinese: '',
        hindi: '',
        nepali: '',
        tagalog: '',
        urdu: '',
      },
    };
  },
  methods: {
    formattedForm() {
      this.languages.forEach((key) => {
        this.form.title[key] = this.form[`title${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(this.form, `title${key}`, this.form.title[key]);
      });
    },

    save() {
      let animationFrom = this.$refs.animationFrom,
      { ruleForm } = animationFrom,
      form = {
        "page_id": this.$route.params.pageId,
        "title": {
          "en": ruleForm.en,
          "zh_hant": ruleForm.zhHant,
          "zh_cn": ruleForm.zhCn
        },
        "thumbnail": ruleForm.thumbnail,
        "video_upload": {
          "english": ruleForm.english,
          "chinese": ruleForm.chinese,
          "hindi": ruleForm.hindi,
          "nepali": ruleForm.nepali,
          "tagalog": ruleForm.tagalog,
          "urdu": ruleForm.urdu
        }
      }

      animationFrom.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addAnimations(form).then(() => {
            this.$message.success('success')
            this.$router.go(-1)
          })
        }
      })
    }
  },
  mounted() {
    console.log(this.form)
  }
};
</script>

<style lang="scss" scoped>
.page-button {
  margin-top: 60px;
}
</style>