import exportToExcel from "@/utils/exportExcel";

let tHeader = [
  'ID', 
  'Title_EN', 'Title_TC', 'Title_SC',
  'Status', 'Date of accident', 'Push to user',
  'Place of Accident_EN', 'Place of Accident_TC', 'Place of Accident_SC',
  'Summary_EN', 'Summary_TC', 'Summary_SC',
  'WSA for employers_EN', 'WSA for employers_TC', 'WSA for employers_SC',
];

export default function exportExcel(tableData) {
  let referLeng = 0, linkLeng = 0
  let pushList = []
  tableData.filter((its) => {
    let { references, links } = its;
    // 计算references, link最大量
    referLeng = Math.max(referLeng, references.length)
    linkLeng = Math.max(linkLeng, links.length)
  })

  // 按顺序追加
  // 根据referLeng, linkLeng追加表头 
  for(let i = 0; i < referLeng; i++) {
    pushList = ['Reference_EN', 'Reference_TC', 'Reference_SC', 
          'Reference Link_EN', 'Reference Link_TC', 'Reference Link_SC', 'Sorting Order']
    if(i == 0) {
      tHeader.push(...pushList)
    } else {
      let index = ` ${i + 1}`
      pushList = pushList.map(row => row + index)
      tHeader.push(...pushList)
    }
  }
  tHeader.push('Push message_EN', 'Push message_TC', 'Push message_SC')
  for(let i = 0; i < linkLeng; i++) {
    pushList = ['Link Type', 'Link_EN', 'Link_TC', 'Link_SC']
    if(i == 0) {
      tHeader.push(...pushList)
    } else {
      let index = ` ${i + 1}`
      pushList = pushList.map(row => row + index)
      tHeader.push(...pushList)
    }
  }

  // 处理data
  let data = handleData(tableData, referLeng, linkLeng),
  title = 'WSA Text-version'

  exportToExcel(data, title, tHeader, tHeader, () => {})
}

function handleData(tableData, referLeng, linkLeng) {
  let data = [], referObj = {}, linkObj = {}

  tableData.filter(its => {
    //references
    for(let i = 0; i < referLeng; i++) {
      let referRow = its.references[i]

      if(i == 0) {
        // 若不存在数据 默认值为'-'
        if(referRow) {
          referObj['Reference_EN'] = referRow.title.en
          referObj['Reference_TC'] = referRow.title.zhHant
          referObj['Reference_SC'] = referRow.title.zhCn
          referObj['Reference Link_EN'] = referRow.link.en || '-'
          referObj['Reference Link_TC'] = referRow.link.zhHant || '-'
          referObj['Reference Link_SC'] = referRow.link.zhCn || '-'
          referObj['Sorting Order'] = referRow.sortingOrder
        } else {
          referObj['Reference_EN'] = '-'
          referObj['Reference_TC'] = '-'
          referObj['Reference_SC'] = '-'
          referObj['Reference Link_EN'] = '-'
          referObj['Reference Link_TC'] = '-'
          referObj['Reference Link_SC'] = '-'
          referObj['Sorting Order'] = '-'
        }
      } else {
        let index = ` ${i + 1}`
        // 若不存在数据 默认值为'-'
        if(referRow) {
          referObj['Reference_EN' + index] = referRow.title.en || '-'
          referObj['Reference_TC' + index] = referRow.title.zhHant || '-'
          referObj['Reference_SC' + index] = referRow.title.zhCn || '-'
          referObj['Reference Link_EN' + index] = referRow.link.en || '-'
          referObj['Reference Link_TC' + index] = referRow.link.zhHant || '-'
          referObj['Reference Link_SC' + index] = referRow.link.zhCn || '-'
          referObj['Sorting Order' + index] = referRow.sortingOrder || '-'
        } else {
          referObj['Reference_EN' + index] = '-'
          referObj['Reference_TC' + index] = '-'
          referObj['Reference_SC' + index] = '-'
          referObj['Reference Link_EN' + index] = '-'
          referObj['Reference Link_TC' + index] = '-'
          referObj['Reference Link_SC' + index] = '-'
          referObj['Sorting Order' + index] = '-'
        }
      }
    }

    // links
    for(let i = 0; i < linkLeng; i++) {
      let linkRow = its.links[i],
      types = ['PDF', 'External Link', 'Photo', 'Video']
      console.log(linkRow)
      if(i == 0) {
        // 若不存在数据 默认值为'-'
        if(linkRow) {
          linkObj['Link Type'] = types[linkRow.type] || '-'
          linkObj['Link_EN'] = linkRow.link.en || '-'
          linkObj['Link_TC'] = linkRow.link.zhHant || '-'
          linkObj['Link_SC'] = linkRow.link.zhCn || '-'
        } else {
          linkObj['Link Type'] = '-'
          linkObj['Link_EN'] = '-'
          linkObj['Link_TC'] = '-'
          linkObj['Link_SC'] = '-'
        }
      } else {
        let index = ` ${i + 1}`
        // 若不存在数据 默认值为'-'
        if(linkRow) {
          linkObj['Link Type' + index] = types[linkRow.type] || '-'
          linkObj['Link_EN' + index] = linkRow.link.en || '-'
          linkObj['Link_TC' + index] = linkRow.link.zhHant || '-'
          linkObj['Link_SC' + index] = linkRow.link.zhCn || '-'
        } else {
          linkObj['Link Type' + index] = '-'
          linkObj['Link_EN' + index] = '-'
          linkObj['Link_TC' + index] = '-'
          linkObj['Link_SC' + index] = '-'
        }
      }
    }

    let obj = {
      'ID': its.id,
      'Title_EN': its.title.en,
      'Title_TC': its.title.zhHant,
      'Title_SC': its.title.zhCn,
      'Status': its.isPublish ? 'Published' : 'Draft',
      'Date of accident': new Date(its.accidentDate).format('yyyy-MM'),
      'Push to user': its.isPushToUser ? 'Yes' : 'No',
      'Place of Accident_EN': its.placeOfAccident.en || '-',
      'Place of Accident_TC': its.placeOfAccident.zhHant || '-',
      'Place of Accident_SC': its.placeOfAccident.zhCn || '-',
      'Summary_EN': its.summary.en || '-',
      'Summary_TC': its.summary.zhHant || '-',
      'Summary_SC': its.summary.zhCn || '-',
      'WSA for employers_EN': its.workSafetyAlertForContractorsProprietorsEmployers.en || '-',
      'WSA for employers_TC': its.workSafetyAlertForContractorsProprietorsEmployers.zhHant || '-',
      'WSA for employers_SC': its.workSafetyAlertForContractorsProprietorsEmployers.zhCn || '-',
      'Push message_EN': its.pushMessage.en || '-',
      'Push message_TC': its.pushMessage.zhHant || '-',
      'Push message_SC': its.pushMessage.zhCn || '-',
      ...referObj,
      ...linkObj
    }

    data.push(obj)
  })
  console.log(data)
  return data
}