<template>
  <div>
    <Header-Back :hideBack="true" pageTitle="Interstitial Ads">
    </Header-Back>
    <div class="page-list" v-for="(item,index) in list" :key="index">
       <interstitialAdsItem :key="index" @Cancel="onCancel" :item="item" ></interstitialAdsItem>
    </div>
  </div>
</template>

<script>
import {getInterstitialAds} from "@/api/interstitialAds";
import interstitialAdsItem from "./components/interstitialAdsItem";
export default {
  components: {
    interstitialAdsItem,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getInterstitialAds();
  },

  methods:{
    onCancel(){
      this.getInterstitialAds();
    },
    getInterstitialAds(){
      getInterstitialAds().then(res=>{
         if(res.data.length>0)
         this.list = res.data
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-list {
  margin: 40px 0;
}
.page-label {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}
.page-content {
  background: #f5f5f5;
  padding: 20px;
}
.image-slot {
  display: flex;
  width: 100%;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #6d6d6d;
  background: #6d6d6d;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.page-content-navigate{
color: #000;
font-size: 18px;
font-style: normal;
font-weight: 700;
margin-bottom: 10px;
}
.page-content-lint{
color: #1A74B0;
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 400;
}
.page-content-button{
  margin-top: 40px;
}
.page-content-image{
  width: 100%;
}
.page-content-col{
position: relative;
}
.page-content-img{
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>