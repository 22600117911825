<template>
  <div
    class="edit-div"
    :class="[{ 'isEdit-div': !canEdit }]"
    v-paste
    v-html="innerText"
    :contenteditable="canEdit"
    @focus="isLocked = true"
    @blur="isLocked = false"
    @input="changeText"
  ></div>
</template>
<script type="text/ecmascript-6">
export default {
  name: "editDiv",
  props: {
    value: {
      type: String,
      default: ""
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerText: this.value,
      isLocked: false
    };
  },
  watch: {
    value() {
      if (!this.isLocked || !this.innerText) {
        this.innerText = this.value;
      }
    }
  },
  methods: {
    changeText() {
      this.$emit("input", this.$el.innerHTML);
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss">
.edit-div {
  width: 100%;
  //   height: 100%;
  min-height: 40px;
  overflow: auto;
  word-break: break-all;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  text-align: left;
  line-height: 1.5rem;
  background-color: #fff;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);

  &:focus {
    border-color: #409EFF;
  }

  &[contenteditable="true"] {
    user-modify: read-write-plaintext-only;
    &:empty:before {
      content: attr(placeholder);
      display: block;
      color: #ccc;
    }
  }
}
.isEdit-div{
 
  border-color: rgba(245, 245, 250, 1);
}
</style>