<template>
  <div>
     <Header-Back  pageTitle="Create New">
      
    </Header-Back>

    <newFrom :form="form" ref="form"></newFrom>
    <el-button type="success" class="success-button" @click="onCreate">Create</el-button>
  </div>
</template>

<script>
import {addNews} from "@/api/news";
import newFrom from "./components/newFrom";
export default {
  components: { newFrom },
  data() {
    return {
      form: {
        title: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        schedulePublishDateTime: null,
        isInstant: false,
        thumbnail: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        isPushToUser: false,
        pushMessage: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
        type:0,
       
        links:[
          // {
          //     type: null, 
          //     link: {
          //         en: null, 
          //         zhHant: null, 
          //         zhCn: null
          //     }
          // }
        ]
      },
    };
  },
  created() {
    this.initializeForm();
  },
  methods: {
    onCreate(){
       this.$refs.form.$refs.form.validate((valid) => {
          if (valid) {
           this.addNews()
          } else {
            this.$nextTick(() => {
            const isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: 'center',
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: 'smooth'
            })
          })
            return false;
          }
        });
    },
    formattedForm() {
      this.languages.forEach((key) => {
        this.form.title[key] = this.form[`title${key}`];
        this.form.content[key] = this.form[`content${key}`];
        this.form.thumbnail[key] = this.form[`thumbnail${key}`];
        this.form.pushMessage[key] = this.form[`pushMessage${key}`];
        // this.form.link[key] = this.form[`link${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(this.form, `title${key}`, this.form.title[key]);
        this.$set(this.form, `content${key}`, this.form.content[key]);
        this.$set(this.form, `thumbnail${key}`, this.form.thumbnail[key]);
        this.$set(this.form, `pushMessage${key}`, this.form.pushMessage[key]);
        // this.$set(this.form, `link${key}`, this.form.link[key]);
      });
    },
    addNews(){
      this.formattedForm();
      addNews(this.form).then(()=>{
          this.$router.push({path:'/new'} )
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.success-button{
  margin-top: 20px;
}
</style>