import overview from '@/views/overview'
import bannerEdit from '@/views/overview/banner/edit'
import firstRowSectionEdit from '@/views/overview/firstRowSection/edit'
import secondRowSectionEdit from '@/views/overview/secondRowSection/edit'
import addNewSection from '@/views/overview/secondRowSection/addNewSection'
import newSectionDetail from '@/views/overview/secondRowSection/newSectionDetail'
import externalLink from '@/views/overview/secondRowSection/externalLink'
import newSectionTitle from '@/views/overview/secondRowSection/newSectionTitle'
import addNewSectionTitle from '@/views/overview/secondRowSection/newSectionTitle/add'
export default [
  { path: '/overview', component: overview },
  { path: '/overview/bannerEdit', component: bannerEdit },
  { path: '/overview/firstRowSectionEdit', component: firstRowSectionEdit },
  { path: '/overview/secondRowSectionEdit', component: secondRowSectionEdit },
  { path: '/overview/secondRowSection/addNewSection', component: addNewSection },
  { path: '/overview/secondRowSection/newSectionDetail', component: newSectionDetail },
  { path: '/overview/secondRowSection/externalLink', component: externalLink },
  { path: '/overview/secondRowSection/newSectionTitle', component: newSectionTitle },
  { path: '/overview/secondRowSection/newSectionTitle/add', component: addNewSectionTitle },
]