<template>
  <div>
     <Header-Back :hideBack="true" pageTitle="About Us">
    </Header-Back>
    <div class="disclaimer-box">
      <div class="column-title">Content</div>
      <el-form label-position="top" label-width="80px" :model="formLabelAlign">
        <template v-for="(item, index) in languages">
          <el-form-item :label="setLabel(item)" :key="index">
            <Tinymce v-model="formLabelAlign[`content${item}`]"></Tinymce>
            <!-- <el-input
              type="textarea"
              :rows="6"
              v-model="formLabelAlign[`content${item}`]"
            ></el-input> -->
          </el-form-item>
        </template>
      </el-form>
    </div>
    <el-button type="success" @click="onSave">Save</el-button>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import { getAboutUs, editAboutUs } from "@/api/setting";
export default {
   components: {
    Tinymce
  },
  data() {
    return {
      formLabelAlign: {
        id: null,
        content: {
          en: null,
          zhHant: null,
          zhCn: null,
        },
      },
    };
  },
  created() {
    this.getAboutUs()
  },
  methods: {
    onSave(){
       this.editAboutUs()
    },
    setLabel(lan) {
      switch (lan) {
        case "en":
          return "English";
        case "zhHant":
          return "Traditional Chinese";
        case "zhCn":
          return "Simplified Chinese";
        default:
          return "";
      }
    },
    formattedForm() {
      this.languages.forEach((key) => {
        this.formLabelAlign.content[key] = this.formLabelAlign[`content${key}`];
      });
    },
    initializeForm() {
      this.languages.forEach((key) => {
        this.$set(
          this.formLabelAlign,
          `content${key}`,
          this.formLabelAlign.content[key]
        );
      });
    },
    getAboutUs() {
      getAboutUs().then((value) => {
        this.formLabelAlign = value.data[0];
        this.initializeForm();
      });
    },
    editAboutUs() {
      this.formattedForm()
      editAboutUs(this.formLabelAlign.id, this.formLabelAlign).then(() => {
        this.$message({
          message: "Edit successfully",
          type: "success",
        });
         this.getAboutUs()
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-box {
  padding-top: 30px;
}
.page-img-button {
  margin: 0 1px;
}
.el-pagination {
  padding-top: 20px;
  padding-block: 20px;
  text-align: center;
}
.save-button {
  padding: 20px 150px;
  background-color: #184c84;
}
.input-class {
  width: 490px;
}
</style>