<template>
  <div class="content">
    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English">
        <div class="content">{{ ruleForm.title_en }}</div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div class="content">{{ ruleForm.title_zhHant }}</div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content">{{ ruleForm.title_zhCn }}</div>
      </el-form-item>

      <div class="line">Date</div>
      <el-form-item>
        <div class="content">{{ ruleForm.date }}</div>
      </el-form-item>

      <div class="line">Content</div>
      <el-form-item label="English">
        <div class="content" v-html="ruleForm.content_en"></div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div class="content" v-html="ruleForm.content_zhHant"></div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content" v-html="ruleForm.content_zhCn"></div>
      </el-form-item>

      <div class="line">Push Notification and Share Message</div>
      <el-form-item>
        <el-checkbox v-model="ruleForm.push_message" :disabled="true">Push to user (Send push notification to user)</el-checkbox>
      </el-form-item>
      <el-form-item label="English">
        <div class="content" v-html="ruleForm.push_message_en"></div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div class="content" v-html="ruleForm.push_message_zhHant"></div>
      </el-form-item>
      <el-form-item label="Simplified Chinese">
        <div class="content" v-html="ruleForm.push_message_zhCn"></div>
      </el-form-item>

      <div class="line">Link(s)</div>
      <div class="group" v-for="(its, idx) in ruleForm.documents" :key="idx">
        <div class="value">
          <div class="type">
            <div class="content">{{ types[its.type] }}</div>
          </div>
          <el-form-item label="Link - English">
            <div class="content">{{ its.link_en }}</div>
          </el-form-item>
          <el-form-item label="Link - Traditional Chinese">
            <div class="content">{{ its.link_zhHant }}</div>
          </el-form-item>
          <el-form-item label="Link - Simplified Chinese">
            <div class="content">{{ its.link_zhCn }}</div>
          </el-form-item>
        </div>
        
      </div>

    </el-form>
  </div>
</template>

<script>

export default {
  props: {
    form: {
      type: Object,
    }
  },
  data() {
    return {
      types: ['PDF', 'External Link', 'Photo', 'Video'],
      ruleForm: {},
      rules: {}
    }
  },
  methods: {
    add() {
      this.ruleForm.documents.push({
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
      })
    }
  },
  created() {
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
  },
  beforeDestroy() {
    this.$emit('unifiedData', 'content', this.ruleForm)
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  word-break: break-all;
}

.group {
  width: 100%;
  margin-bottom: 20px;
  
  .label {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 10px;
  }

  .value {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: #F5F5F5;
    flex-wrap: wrap;

    & > * {
      width: 32%;
    }

    .type {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

</style>