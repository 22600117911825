import http from '@/utils/http'

export function getTextVersions(params){
    return http.get('/text_versions',{params})
}
export function addTextVersions(data){
    return http.post('/text_versions',data)
}
export function editTextVersions(id,data){
    return http.put(`/text_versions/${id}`,data)
}
export function deleteTextVersions(id){
    return http.delete(`/text_versions/${id}`)
}
export function getTextVersionsDetail(id){
    return http.get(`/text_versions/${id}`)
}
export function getExportTextVersions(params){
    return http.get(`/export_text_versions`, {params})
}

export function getAnimations(params){
    return http.get('/animations',{params})
}
export function addAnimations(data){
    return http.post('/animations',data)
}
export function editAnimations(id,data){
    return http.put(`/animations/${id}`,data)
}
export function deleteAnimations(id){
    return http.delete(`/animations/${id}`)
}
export function getAnimationsDetail(id){
    return http.get(`/animations/${id}`)
}
export function disableAnimations(id){
    return http.post(`/animations/${id}/disable`)
}
export function enableAnimations(id){
    return http.post(`/animations/${id}/enable`)
}
export function getExportAnimations(params){
    return http.get(`/export_animations`, {params})
}

export function getSystemicSafetyAlerts(params){
    return http.get('/systemic_safety_alerts',{params})
}
export function addSystemicSafetyAlerts(data){
    return http.post('/systemic_safety_alerts',data)
}
export function editSystemicSafetyAlerts(id,data){
    return http.put(`/systemic_safety_alerts/${id}`,data)
}
export function deleteSystemicSafetyAlerts(id){
    return http.delete(`/systemic_safety_alerts/${id}`)
}
export function getSystemicSafetyAlertsDetail(id){
    return http.get(`/systemic_safety_alerts/${id}`)
}

export function getAshTrainings(params){
    return http.get('/osh_trainings',{params})
}
export function addAshTrainings(data){
    return http.post('/osh_trainings',data)
}
export function editAshTrainings(id,data){
    return http.put(`/osh_trainings/${id}`,data)
}
export function deleteAshTrainings(id){
    return http.delete(`/osh_trainings/${id}`)
}
export function getAshTrainingsDetail(id){
    return http.get(`/osh_trainings/${id}`)
}
export function getAshTrainingsSorts(data){
    return http.post(`/osh_trainings_sorts`, data)
}


export function getImportantAnnouncements(params){
    return http.get('/important_announcements',{params})
}
export function addImportantAnnouncements(data){
    return http.post('/important_announcements',data)
}
export function editImportantAnnouncements(id,data){
    return http.put(`/important_announcements/${id}`,data)
}
export function deleteImportantAnnouncements(id){
    return http.delete(`/important_announcements/${id}`)
}
export function getImportantAnnouncementsDetail(id){
    return http.get(`/important_announcements/${id}`)
}
export function getExportImportantAnnouncementsDetail(params){
    return http.get(`/export_important_announcements`, {params})
}

export function getNotifyYourWorkplaces(params){
    return http.get('/notify_your_workplaces',{params})
}
export function addNotifyYourWorkplaces(data){
    return http.post('/notify_your_workplaces',data)
}
export function deleteNotifyYourWorkplaces(id){
    return http.delete(`/notify_your_workplaces/${id}`)
}

