<template>
    <div class="content">
        <Header-Back :pageTitle="ruleForm.title_en">
            <div class="last-updated">Last Updated {{ ruleForm.updated_at }}</div>
        </Header-Back>

        <el-form label-position="top" :rules="rules" v-model="ruleForm"
        ref="ruleForm" label-width="100px" class="ruleForm">
            <div class="line">Title</div>
            <el-form-item label="English" style="width: 100%" >
                <!-- <el-input v-model="ruleForm.name"></el-input> -->
                <div class="content">{{ruleForm.title_en}}</div>
            </el-form-item>
            <el-form-item label="Traditional Chinese" style="width: 100%" >
                <div class="content">{{ruleForm.title_zhHant}}</div>
            </el-form-item>
            <el-form-item label="Simplified Chinese" style="width: 100%">
                <div class="content">{{ruleForm.title_zhCn}}</div>
            </el-form-item>

            <div class="line">Document No.</div>
            <el-form-item style="width: 40%">
                <div class="content">{{ruleForm.document_number}}</div>
            </el-form-item>
                
            <div class="line">Date</div>
            <el-form-item>
                <div class="content">{{ruleForm.date}}</div>
            </el-form-item>

            <div class="line">Push Notification and Share Message</div>
            <el-form-item>
                <el-checkbox v-model="ruleForm.push_message" :disabled="true">
                    Push to user (Send push notification to user)
                </el-checkbox>
            </el-form-item>
            <el-form-item label="English" style="width: 100%">
                <div class="content">{{ruleForm.push_message_en}}</div>
            </el-form-item>
            <el-form-item label="Traditional Chinese" style="width: 100%">
                <div class="content">{{ruleForm.push_message_zhHant}}</div>
            </el-form-item>
            <el-form-item label="Simplified Chinese" style="width: 100%">
                <div class="content">{{ruleForm.push_message_zhCn}}</div>
            </el-form-item>

            <div class="line">PDF</div>
            <div class="group">
                <el-form-item label="Link - English">
                    <div class="content">{{ruleForm.pdf_en}}</div>
                </el-form-item>
                <el-form-item label="Link - Traditional Chinese">
                    <div class="content">{{ruleForm.pdf_zhHant}}</div>
                </el-form-item>
                <el-form-item label="Link - Simplified Chinese">
                    <div class="content">{{ruleForm.pdf_zhCn}}</div>
                </el-form-item>
            </div>

            <div class="page-button">
                <el-button type="success" @click="edit">Edit</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { getSystemicSafetyAlertsDetail } from "@/api/material.js"

export default {
    props: {
        form: {
            type: Object,
        }
    },
    data() {
        return {
            ruleForm: {},
            rules: {}
        }
    },
    
    methods: {
        edit() {
            let {pageId, id} = this.$route.params
            this.$router.push(`/template/ssa/${pageId}/${id}/edit`);
        },
        getData() {
            let id = this.$route.params.id
            getSystemicSafetyAlertsDetail(id).then(v => {
                this.ruleForm= {
                    id: v.data.id,
                    title_en: v.data.title.en,
                    title_zhHant: v.data.title.zhHant,
                    title_zhCn: v.data.title.zhCn,
                    document_number: v.data.documentNumber,
                    date: v.data.date,
                    push_message: v.data.isPushToUser,
                    push_message_en: v.data.pushMessage.en,
                    push_message_zhHant: v.data.pushMessage.zhHant,
                    push_message_zhCn: v.data.pushMessage.zhCn,
                    pdf_en: v.data.pdf.en,
                    pdf_zhHant: v.data.pdf.zhHant,
                    pdf_zhCn: v.data.pdf.zhCn,
                    updated_at: v.data.updatedAt,
                }
                console.log(this.ruleForm)
                // console.log(this.tableData)
            })
        },
    },
    mounted() {
        this.getData()
    },
}
</script>

<style lang="scss" scoped>
.ruleForm {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  position: relative;
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #F5F5F5;
  padding: 20px;
  box-sizing: border-box;

  & > * {
    width: 32%;
  }
}

.page-button {
  margin-top: 60px;
}

.content {
  width: 100%;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  word-break: break-all;
}
</style>