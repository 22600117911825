<template>
  <div>
    <el-dialog title="" :visible.sync="isCenterDialogVisible" :show-close="false"  width="60%">
      <div class="dialog-title">
         <div class="column-title">Change Password</div>
         <i class="el-icon-close" @click="handleClose"></i>
      </div>
      <!-- <div class="dialog-container">
      <slot></slot>
      </div> -->
    <!-- <div class="column-title">Change Password</div> -->
    <div class="box">
      <el-form ref="form" label-position="top" :model="form"  label-width="80px">
        <!-- <el-form-item label="Current Password">
          <el-input v-model="form.currentPassword" placeholder="Please enter current password"></el-input>
        </el-form-item> -->
        <el-form-item label="New Password">
          <el-input show-password auto-complete="new-password" v-model="form.newPassword" placeholder="Please enter new password"></el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password">
          <el-input show-password  auto-complete="new-password" v-model="form.confirmNewPassword" placeholder="Please re-enter new password"></el-input>
        </el-form-item>
        <el-button type="success" @click="onSubmit">Submit</el-button>
      </el-form>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import { setToken, setUserInfo,getUserInfo } from "@/utils/auth";
import {changePassword} from '@/api/user'
export default {
  props: {
    slotDialogTitle: {
      type: String,
      default: ''
    },
    centerDialogVisible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
    }
  },
  computed:{
    isCenterDialogVisible:{
      get() {
        return this.centerDialogVisible
      },
      set(val) {
        this.$emit('cancel', val)
      }
    }
  },
  methods:{
    handleClose(){
      setUserInfo("refreshToken", null);
      setUserInfo("userInfo", null);
      setToken();
      this.form = {
          currentPassword: null,
          newPassword: null,
          confirmNewPassword: null,
        }
      this.$emit('handleClose', false)
    },
     onSubmit(){
       this.changePassword()
     },
     changePassword(){
      changePassword(getUserInfo('userInfo').id,this.form).then(()=>{
         this.$message({
          type: 'success',
          message: 'change Successfully !'
        });
        this.form = {
          currentPassword: null,
          newPassword: null,
          confirmNewPassword: null,
        }
        this.$emit('submit', false)
      })
    }
  }
};
</script>

<style lang="scss" scoped>

.dialog-title{
    display: flex;
    color: #000;
    align-items: center;
    justify-content: space-between;
    padding: 0.4em 1em;
    // background: linear-gradient(#3f3f3f 0,#323232 70%,#2b2b2b 100%);
}
.dialog-container{
    padding: 0.5em 1em;
}
.box {
  padding: 30px;
  background: #f5f5f5;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  
}
.column-title {
  margin: 15px 0;
  text-align: center;

}
::v-deep{
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body{
    padding: 0;
    border-radius: 5px;
  }
  .el-form--label-top .el-form-item__label {
    color: #000 !important;
  }
}
</style>