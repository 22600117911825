<template>
  <div>
    <el-form ref="form" label-position="top"  :rules="rules" :model="form" label-width="80px">
      <el-form-item label="Name">
        <el-input v-model="form.name" placeholder="Please enter name"></el-input>
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="form.email" placeholder="Please enter email"></el-input>
      </el-form-item>
      <el-form-item label="Role">
          <el-radio-group v-model="form.roleId">
                <el-radio v-for="(item,index) in roles" :key="index" :label="item.id">{{item.name}}</el-radio>
          </el-radio-group>
      </el-form-item> 
      <template v-if="!editIs">
        <el-form-item label="New Password" prop="newPassword">
          <el-input show-password auto-complete="new-password" v-model="form.newPassword" placeholder="Please enter new password"></el-input>
        </el-form-item>
        <el-form-item label="Confirm New Password" prop="confirmNewPassword">
          <el-input show-password auto-complete="new-password" v-model="form.confirmNewPassword" placeholder="Please re-enter new password"></el-input>
        </el-form-item>
      </template>
       <el-form-item v-else label="Password">
        <el-button type="primary" @click="onReset">Reset</el-button>
      </el-form-item>
       <el-form-item label="Admin Rights:" v-if="form.roleId !== 1">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">all</el-checkbox>
        <el-checkbox-group v-model="form.sectionIds">
            <el-checkbox v-for="(item,index) in sectionList" :label="item.id" :key="index">{{item.title.en}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {getRoles} from "@/api/roles";
import {getSections} from '@/api/section';
export default {
  props: {
    isEdit:{
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
     var validatePass = (rule, value, callback) => {
        let newValue = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])(?=.{10,})$/;
        if (!newValue.test(value)) {
          callback(new Error('Composed of at least eight characters, including letters, numbers, and special characters with different capitalization, or composed of at least ten characters, including characters from at least three categories'));
        } else {
          if (this.form.confirmNewPassword !== '') {
            this.$refs.form.validateField('validatePass2');
          }
          callback();
        }
        
      };
    var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('Please enter your password again'));
        } else if (value !== this.form.newPassword) {
          callback(new Error('The passwords entered twice are inconsistent!'));
        } else {
          callback();
        }
      };
    return {
        editIs:this.isEdit,
        checkAll:false,
        checkedCities:[],
        roles:[],
        sectionList:[],
        rules:{
          newPassword:[
            { required: true, message: 'Please enter new password', trigger: 'blur' },
            {  validator: validatePass,  trigger: 'change' }
          ],
          confirmNewPassword:[
            { required: true, message: 'Please re-enter new password', trigger: 'blur' },
            {  validator: validatePass2,  trigger: 'blur' }
          ]
        }
    }
  },
  created(){
    this.getRoles()
    this.getSections()
  },
  methods:{
    onReset(){
      this.editIs = false
    },
     handleCheckAllChange(val) {
      this.form.sectionIds = val ? this.sectionList.map((item)=>{
         return item.id
      }) : [];
    },
    getSections(){
      getSections().then((v)=>{
         this.sectionList = v.data
      })
    },
    getRoles(){
       getRoles().then((v)=>{
          this.roles = v.data
       })
    }
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
}
::v-deep{
    .el-checkbox-group{
        display: flex;
        flex-direction: column;
    }
}
</style>