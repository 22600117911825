import news from '@/views/others/new'
import aboutUs from '@/views/others/about-us'
import privacyPolicy from '@/views/others/privacy-policy'
import accessibilityStatement from '@/views/others/accessibility-statement'
import copyrightNotice from '@/views/others/copyright-notice'
import disclaimer from '@/views/others/disclaimer'
import newAdd from '@/views/others/new-add'
import newEdit from '@/views/others/new-edit'
import newDetail from '@/views/others/new-detail'
export default [
    {
        path:'/new', component: news
    },
    {
        path:'/aboutUs', component: aboutUs
    },
    {
        path:'/privacyPolicy', component: privacyPolicy
    },
    {
        path:'/accessibilityStatement', component: accessibilityStatement
    },
    {
        path:'/copyrightNotice', component: copyrightNotice
    },
    {
        path:'/disclaimer/:type', props: (route) => ({ type: Number(route.params.type) }), component: disclaimer
    },
    {
        path:'/new/newAdd', component: newAdd
    },
    {
        path:'/new/:id/edit',props: (route) => ({ id: Number(route.params.id) }), component: newEdit
    },
    {
        path:'/new/:id',props: (route) => ({ id: Number(route.params.id) }), component: newDetail
    },
]