export function convertLine(obj) {
  return {
    "page_id": Number(obj.pageId),
    "title": {
      "en": obj.titleEn,
      "zh_hant": obj.titleZhHant,
      "zh_cn": obj.titleZhCn
    },
    "accident_date": obj.accidentDate && new Date(obj.accidentDate).format('yyyy-MM-dd'),
    "place_of_accident": {
      "en": obj.placeOfAccidentEn,
      "zh_hant": obj.placeOfAccidentZhHant,
      "zh_cn": obj.placeOfAccidentZhCn
    },
    'is_publish': true,
    "summary": {
      "en": obj.summaryEn,
      "zh_hant": obj.summaryZhHant,
      "zh_cn": obj.summaryZhCn
    },
    "work_safety_alert_for_contractors_proprietors_employers": {
      "en": obj.wsaEn,
      "zh_hant": obj.wsaZhHant,
      "zh_cn": obj.wsaZhCn
    },
    "references": [...obj.references.map(its => {
      return {
        "title": {
          "en": its.titleEn,
          "zh_hant": its.titleZhHant,
          "zh_cn": its.titleZhCn
        },
        "link": {
          "en": its.linkEn,
          "zh_hant": its.linkZhHant,
          "zh_cn": its.linkZhCn
        },
        "sorting_order": Number(its.sortingOrder)
      }
    })],
    "is_push_to_user": obj.isPushToUser,
    "push_message": {
      "en": obj.pushMessageEn,
      "zh_hant": obj.pushMessageZhHant,
      "zh_cn": obj.pushMessageZhCn
    },
    "links": [...obj.link.map(its => {
      return {
        "type": its.type,
        "link": {
          "en": its.en,
          "zh_hant": its.zhHant,
          "zh_cn": its.zhCn
        }
      }
    })]
  }
}

export function convertLocal(obj) {
  return {
    id: obj.id,
    titleEn: obj.title.en,
    titleZhHant: obj.title.zhHant,
    titleZhCn: obj.title.zhCn,
    accidentDate: obj.accidentDate,
    placeOfAccidentEn: obj.placeOfAccident.en,
    placeOfAccidentZhHant: obj.placeOfAccident.zhHant,
    placeOfAccidentZhCn: obj.placeOfAccident.zhCn,
    summaryEn: obj.summary.en,
    summaryZhHant: obj.summary.zhHant,
    summaryZhCn: obj.summary.zhCn,
    updatedAt: new Date(obj.updatedAt).format('yyyy-MM-dd hh:mm:ss'),
    wsaEn: obj.workSafetyAlertForContractorsProprietorsEmployers.en,
    wsaZhHant: obj.workSafetyAlertForContractorsProprietorsEmployers.zhHant,
    wsaZhCn: obj.workSafetyAlertForContractorsProprietorsEmployers.zhCn,
    references: [...obj.references.map(its => {
      return {
        titleEn: its.title.en,
        titleZhHant: its.title.zhHant,
        titleZhCn: its.title.zhCn,
        linkEn: its.link.en,
        linkZhHant: its.link.zhHant,
        linkZhCn: its.link.zhCn,
        sortingOrder: its.sortingOrder,
      }
    })].sort((a, b) => a.sortingOrder - b.sortingOrder),
    isPushToUser: obj.isPushToUser,
    isPublish: obj.isPublish,
    pushMessageEn: obj.pushMessage.en,
    pushMessageZhHant: obj.pushMessage.zhHant,
    pushMessageZhCn: obj.pushMessage.zhCn,
    link: [...obj.links.map(its => {
      return {
        type: its.type,
        en: its.link.en,
        zhHant: its.link.zhHant,
        zhCn: its.link.zhCn,
      }
    })]
  }
}