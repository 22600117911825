import http from '@/utils/http'

export function getAdmins(params){
    return http.get('/admins',{params})
}

export function addAdmins(data){
    return http.post('/admins',data)
}
export function editAdmins(id,data){
    return http.put(`/admins/${id}`,data)
}
export function deleteAdmins(id){
    return http.delete(`/admins/${id}`)
}
export function getAdminsDetail(id){
    return http.get(`/admins/${id}`)
}
export function suspendAdmins(id,data){
    return http.post(`/admins/${id}/suspend`,data)
}
export function changePassword(id,data){
    return http.post(`/admins/${id}/change_password`,data)
}