import http from '@/utils/http'

export function getInterstitialAds(params){
    return http.get('/interstitial_ads',{params})
}

export function editInterstitialAds(id,data){
    return http.put(`/interstitial_ads/${id}`,data)
}
export function disableInterstitialAds(id){
    return http.post(`/interstitial_ads/${id}/disable`)
}
export function enableInterstitialAds(id){
    return http.post(`/interstitial_ads/${id}/enable`)
}