<template>
  <div>
    <Header-Back  pageTitle="Edit">
    
    </Header-Back>

    <adminPermissionFrom :form="form" :isEdit="true"></adminPermissionFrom>
    <el-button type="success" @click="onSave">Save</el-button>
    <el-button type="info" @click="onSuspend">Suspend</el-button>
    <el-button type="danger" icon="el-icon-delete-solid" @click="onDelete">Delete</el-button>
  </div>
</template>

<script>
import {editAdmins,getAdminsDetail,deleteAdmins,suspendAdmins} from '@/api/user'

import adminPermissionFrom from "./components/adminPermissionFrom";
export default {
  components: { adminPermissionFrom },
  props:{
    id:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
       form: {
        name: null,
        email: null,
        roleId: null,
        newPassword: null,
        confirmNewPassword: null,
        sectionIds: [],
      },
    };
  },
  created(){
    this.getAdminsDetail()
  },
  methods:{
    onSuspend(){
      this.suspendAdmins()
    },
    onSave(){
      this.editAdmins()
    },
    onDelete(){
      this.deleteAdmins()
    },
    suspendAdmins(){
      suspendAdmins(this.id).then(()=>{
         this.$message({
          message: 'Suspend success!',
          type: 'success'
        })
        this.$router.push({path:'/adminPermission'})
      })
    },
    deleteAdmins(){
      deleteAdmins(this.id).then(()=>{
           this.$message({
          message: 'delete success!',
          type: 'success'
        })
        this.$router.push({path:'/adminPermission'})
      })
    },
    editAdmins(){
        if(this.form.roleId == 1){
          this.form.sectionIds = []
        }
       editAdmins(this.id,this.form).then(()=>{
         this.$message({
          message: 'edit success!',
          type: 'success'
        })
        this.$router.push({path:'/adminPermission'})
       })
    },
    getAdminsDetail(){
       getAdminsDetail(this.id).then((v)=>{
          this.form = v.data
          let  sectionIds = v.data.sections.map((item)=>{
              return item.id
          })
          this.$set(this.form,'sectionIds',sectionIds)
       })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>