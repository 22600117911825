<template>
  <div>
    <el-form ref="form" label-position="top"   :model="form" label-width="80px">
      <div class="column-title"><span class="span-label-text">*</span>Title</div>
      <template v-for="(item,index) in languages ">
          <el-form-item :label="setLabel(item)" :key="index+'title'" :prop="`title${item}`" :rules="[ { required: true, message: '', trigger: 'blur' },]">
              <el-input  v-model="form[`title${item}`]"></el-input>
          </el-form-item>
      </template>
     
      <el-divider></el-divider>
      <div class="column-title"><span class="span-label-text">*</span>Thumbnail</div>
        <template v-for="(item,index) in languages ">
          <el-form-item :label="setLabel(item)" :key="index+'thumbnail'" :prop="`thumbnail${item}`" :rules="[ { required: true, message: '', trigger: 'blur' },]">
              <el-row type="flex" :gutter="20">
                  <el-col :span="6">
                    <el-image :src="form[`thumbnail${item}`]">
                        <div slot="placeholder" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                  </el-col>
                  <el-col :span="6">
                    <div class="col-list">Type: PNG, JPG or BMP</div>
                    <div class="col-list">Maximum file size:  5MB</div>
                    <upload :isImgDom="false" @childByValue="childByValue(item,$event)" :isSizes="true" :isIcon="true"></upload>
                  </el-col>
              </el-row>
          </el-form-item>
      </template>
   
      <el-divider></el-divider>
      <div class="column-title"><span class="span-label-text">*</span>Schedule Publish Date & Time</div>
      <el-form-item label="" prop="schedulePublishDateTime" :rules="[ { required: true, message: '', trigger: 'blur' },]">
          <el-date-picker
            v-model="form.schedulePublishDateTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="">
            </el-date-picker>
      </el-form-item> 
      <el-form-item label="">
          <el-checkbox v-model="form.isInstant">Instant</el-checkbox>
      </el-form-item> 
       
      <el-divider></el-divider>
      <div class="column-title"><span class="span-label-text">*</span>Content</div>
      <template v-for="(item,index) in languages ">
          <el-form-item :label="setLabel(item)" :key="index+'content'" :prop="`content${item}`" :rules="[ { required: true, message: '', trigger: 'blur' },]">
              <!-- <el-input type="textarea" :rows="6" v-model="form[`content${item}`]"></el-input> -->
              <Tinymce v-model="form[`content${item}`]"></Tinymce>
          </el-form-item>
      </template>

      <el-divider></el-divider>
      <div class="column-title">Push Notification and Share Message</div>
       <el-form-item label="">
          <el-checkbox v-model="form.isPushToUser">Push to user (Send push notification to user)</el-checkbox>
      </el-form-item> 

      <template v-for="(item,index) in languages">
          <el-form-item :label="setLabel(item)" :key="index+'pushMessage'">
            <Tinymce v-model="form[`pushMessage${item}`]"></Tinymce>
              <!-- <el-input type="textarea" :rows="6" v-model="form[`pushMessage${item}`]"></el-input> -->
          </el-form-item>
      </template>
      
      <el-divider></el-divider>
      <div class="column-title">link(s)</div>
      <div class="div-item" v-for="(item,index) in form.links" :key="index+'links'">
        <el-form-item label="">
          <el-radio-group v-model="item.type">
            <el-radio :label="0">PDF</el-radio>
            <el-radio :label="1">External Link</el-radio>
            <el-radio :label="2">Photo</el-radio>
            <el-radio :label="3">Video</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item label="English" :prop="`link[${index}].en`">
              <edit-div v-model="item.link.en"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Traditional Chinese" :prop="`link[${index}].zhHant`">
              <edit-div v-model="item.link.zhHant"></edit-div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Simplified Chinese" :prop="`link[${index}].zhCn`">
              <edit-div v-model="item.link.zhCn"></edit-div>
            </el-form-item>
          </el-col>
        </el-row>
        <i class="el-icon-close btn" @click="onDelete(index)"></i>
      </div>
     <div class="add">
        <div class="add-btn" @click="addLink">
          <img src="@/assets/images/add.png" alt="" />
          <span>Add</span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import upload from "@/components/upload";
export default {
  components: {
    upload,
    Tinymce
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      rules: {}
    }
  },
  methods:{
    onDelete(index){
      this.form.links.splice(index,1);
    },
    childByValue(item,value){
      this.form[`thumbnail${item}`] = value;
      this.$forceUpdate()
    },
    setLabel(lan){

      switch(lan){
        case 'en':
          return 'English';
        case 'zhHant':
          return 'Traditional Chinese';
        case 'zhCn':
          return 'Simplified Chinese';
        default:
          return '';
      }
    },
    addLink() {
      let value = {
              type: null, 
              link: {
                  en: null, 
                  zhHant: null, 
                  zhCn: null
              }
          };
      this.form.links.push(value);
      this.verifyLink()
    },
    onBrowse(){}
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
  position: relative;
}
.btn{
  position: absolute;
  top: 30px;
  right: 30px;
  //手型

  cursor: pointer;
  font-size: 20px;
}

.el-image{
    width: 100%;
}
.image-slot {
  display: flex;
  width: 100%;
  height: 187px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid #6d6d6d;
  background: #6d6d6d;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.column-title{
    margin-bottom: 20px;
}
.col-list{
    margin-bottom: 10px;
    color: var(--black, #000);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.add-btn {
  width: 120px;
  height: 44px;
  border: 1px solid #1a74b0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  cursor: pointer;

  img {
    width: 24px;
    height: auto;
    margin-right: 10px;
  }
}
</style>