import http from '@/utils/http'

export function getAboutUs(params){
    return http.get('/about_us',{params})
}
export function editAboutUs(id,data){
    return http.put(`/about_us/${id}`,data)
}

export function getShareMessages(params){
    return http.get('/share_messages',{params})
}
export function editShareMessages(id,data){
    return http.put(`/share_messages/${id}`,data)
}

export function getNotices(params){
    return http.get('/notices',{params})
}
export function editNotices(id,data){
    return http.put(`/notices/${id}`,data)
}


export function getAppVersions(params){
    return http.get('/app_versions',{params})
}
export function editAppVersions(id,data){
    return http.put(`/app_versions/${id}`,data)
}



