<template>
  <div class="">
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <div @click="addPage" class="btn-default"> 
        <img src="@/assets/images/charm_cross.png" alt=""> New 
      </div> 
    </Header-Back>

    <div class="pages">
      <div class="page">
        <div class="text">
          <img @click="$router.go(-1)" src="@/assets/images/arrow_left.png" class="arrow-left" alt="">
          <span>{{ folder.title.en }}</span>
        </div>
        <div class="operation">
          <!-- <div class="type">Folder</div> -->
          <div class="order">
            <img src="@/assets/images/order_up.png" alt="">
            <img src="@/assets/images/order_down.png" alt="">
          </div>

          <img src="@/assets/images/Edit - large.png" alt="" @click="editFolder(folder)">
          <img src="@/assets/images/mingcute_delete-2-fill.png" alt="" @click="del(folder)">
        </div>
      </div>
      
      <div class="pages-child" @click.stop="e => 'javascript:;'">
        <div class="page" v-for="(i, index) in folder.subPages" :key="index">
          <div class="text">
            <span>{{ i.title.en }}</span>
          </div>
          <div class="operation">
            <!-- <div class="type">Page</div> -->
            <div class="order">
              <img src="@/assets/images/order_up.png" alt="" @click="up(index)">
              <img src="@/assets/images/order_down.png" alt="" @click="down(index)">
            </div>

            <img src="@/assets/images/Edit - large.png" alt="" @click="editPage(i)">
            <img src="@/assets/images/mingcute_delete-2-fill.png" alt="" @click="del(i)">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getAshTrainingsDetail, deleteAshTrainings, getAshTrainingsSorts } from '@/api/material'

export default {
  data() {
    return {
      folder: {
        title: ''
      },
      pageId: null,
      pageTitle: 'OSH Training',
      add_switch: false
    }
  },
  methods: {
    del(its) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        deleteAshTrainings(its.id).then(() => {
          this.$message.success('Successfully deleted！')
          if(its.type == 0) this.$router.go(-1)
          else this.getData()
        })
      })
    },
    up(idx) {
      let folder = JSON.parse(JSON.stringify(this.folder.subPages))

      if(idx != 0) {
        [folder[idx], folder[idx - 1]] = [folder[idx - 1], folder[idx]]
      }

      this.order(folder)
    },
    down(idx) {
      let folder = JSON.parse(JSON.stringify(this.folder.subPages))

      if(idx != (this.folder - 1)) {
        [folder[idx], folder[idx + 1]] = [folder[idx + 1], folder[idx]]
      }

      this.order(folder)
    },
    order(arr) {
      arr = arr.map((its, idx) => ({id: its.id, sort: idx}))
      getAshTrainingsSorts(arr).then(() => {
        // this.$message.success('')
        this.getData()
      })
    },
    addPage() {
      let id = this.$route.params.id
      this.$router.push(`/template/osh/page/${this.pageId}/add/${id}`)
    },
    editPage(row) {
      this.$router.push(`/template/osh/page/${this.pageId}/${row.id}/edit`)
    },
    editFolder(row) {
      this.$router.push(`/template/osh/folder/${this.pageId}/${row.id}/edit`)
    },
    getData() {
      let id = this.$route.params.id
      getAshTrainingsDetail(id).then(v => {
        console.log(v.data)
        this.folder = v.data
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
    let sectionName = this.$route.query.sectionName || ''
    this.pageTitle = sectionName

    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.btn-default {
  position: relative;
  width: 122px;
  height: 44px;
  padding: 5px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #fff;
  background-color: #1A74B0;
  cursor: pointer;

  img {
    width: auto;
    height: 20px;
    margin-right: 5px;
    vertical-align: sub;
  }

  .add-child {
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    padding: 20px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 44px;
      background-color: #1A74B0;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
    }

    span:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
}

.pages {
  width: 100%;

  .page {
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    color: #000000;
    background-color: #F5F5F5;
    position: relative;

    .text {
      display: flex;
      align-items: center;

      img {
        width: 28px;
        height: auto;
      }

      span {
        max-width: 800px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .arrow-left {
        margin-right: 10px;
      }

      .arrow-right {
        margin-left: 10px;
      }
    }

    .operation {
      display: flex;
      align-items: center;

      .type {
        margin-right: 60px;
        width: 102px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid #D53E19;
        color: #D53E19;
        font-size: 20px;
        font-weight: 700;
      }

      .order {
        width: 110;
        margin-right: 60px;

        img {
          margin-left: 20px;
        }
      }

      img {
        width: 32px;
        height: auto;
        margin-left: 10px;
        cursor: pointer;
      }
    }

  }
  
  .pages-child {
    width: calc(100% - 50px);
    margin: 0 0 20px 50px;
    box-sizing: border-box;
    font-size: 18px;
    color: #000000;
    position: relative;

    .page:after {
      content: '';
      position: absolute;
      top: 0;
      left: -45px;
      bottom: 0;
      margin: auto;
      width: 40px;
      height: 40px;
      background-image: url('../../../assets/images/arrow_next.png');
      background-size: 100% 100%;
    }
  }
}

</style>