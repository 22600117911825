<template>
    <div>
        <Header-Back :hideBack="true" pageTitle="App version"></Header-Back>
        <div class="content">
           <el-row type="flex" :gutter="60">
              <el-col :span="4" class="current">Current version:</el-col>
              <el-col :span="3">
                <div v-for="(item,index) in versions" :key="index" class="list">
                    <span>{{item.platform | platformFilter}} : </span>&nbsp;
                    <span>{{item.version}}</span>
                </div>
              </el-col>
              <el-col :span="4">
                <div v-for="(item,index) in versions" :key="index" class="list">
                    <span>Force update: </span>&nbsp;
                    <span>{{item.isForce | forceFilter}}</span>
                </div>
              </el-col>
           </el-row>
        </div>
        <div class="update">Update Version</div>
        <div class="content">
           <el-form :rules="rules" ref="form" :model="form" label-position="left" label-width="180px">
             <el-form-item label="Platform" prop="id">
                <el-select v-model="form.id" clearable  placeholder="Select">
                <el-option v-for="(item,index) in versions" :key="index" :label="setLabel(item)" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Version Number" prop="version">
                <el-input v-model="form.version"></el-input>
            </el-form-item>
            <el-form-item label="Force Update">
                <el-switch v-model="form.isForce"></el-switch>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onConfirm">Confirm</el-button>
            </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {getAppVersions,editAppVersions} from '@/api/setting'
    export default {
        filters:{
            forceFilter(value){
               switch (value) {
                    case 1:
                        return 'Y'
                    case 0:
                        return 'N'
                
                    default:
                        break;
                }
            },
            platformFilter(value){
                switch (value) {
                    case 1:
                        
                        return 'iOS'
                    case 2:
                        return 'Android'
                    case 3:
                        return 'Harmony'
                
                    default:
                        break;
                }
            }
        },
        data() {
            return {
                rules:{
                    id:[{required:true,message:'Please select platform',trigger:'change'}],
                    version:[{required:true,message:'Please enter version number',trigger:'blur'}]
                },
                form:{
                    id:'',
                    version:'',
                    isForce:false,
                },
                platforms:[
                    {
                        value:1,
                        label:'iOS'
                    },
                    {
                        value:2,
                        label:'Android'
                    },
                    {
                        value:3,
                        label:'Harmony'
                    }
                ],
                versions: []
            }
        },
        created() {
            this.getAppVersions()
        },
        methods:{
            setLabel(item){
               return  this.platformFilter(item.platform)  + ' ' + item.version
            },
            platformFilter(value){
                switch (value) {
                    case 1:
                        
                        return 'iOS'
                    case 2:
                        return 'Android'
                    case 3:
                        return 'Harmony'
                
                    default:
                        break;
                }
            },
            onConfirm(){
                  this.$refs.form.validate((valid) => {
                    if (valid) {
                    this.editAppVersions()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                    });
                // this.editAppVersions()
            },
            getAppVersions(){
                getAppVersions().then(res=>{
                    this.versions = res.data
                })
            },
            editAppVersions(){
                editAppVersions(this.form.id,{...this.form}).then(()=>{
                   this.$message.success('修改成功')
                   this.getAppVersions()
                }) 
            }
        }
    }
</script>

<style lang="scss" scoped>
.content{
    padding: 18px;
    background: #F5F5F5;
}
.current{
    color: var(--Black, #000);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}
.list{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.update{
    color: var(--Main, #124B80);
    /* H2 */
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 20px 0;
}
</style>