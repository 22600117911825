<template>
  <div class="folder-form">
    <el-form :model="ruleForm" label-position="top" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="line">Title</div>
      <el-form-item label="English" prop="title_en">
        <el-input v-model="ruleForm.title_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="title_zhHant">
        <el-input v-model="ruleForm.title_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="title_zhCn">
        <el-input v-model="ruleForm.title_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Description</div>
      <el-form-item label="English" prop="pass">
        <el-input v-model="ruleForm.description_en" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Traditional Chinese" prop="pass">
        <el-input v-model="ruleForm.description_zhHant" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Simplified Chinese" prop="pass">
        <el-input v-model="ruleForm.description_zhCn" autocomplete="off"></el-input>
      </el-form-item>

      <div class="line">Subpages</div>
      <div class="pages" v-for="(its, key) in ruleForm.sub_pages" :key="key">
        <div class="text">{{ its.title.en }}</div>
        <div class="operation">
          <img src="@/assets/images/Edit - large.png" alt="" @click="editPage(key)">
          <img src="@/assets/images/mingcute_delete-2-fill.png" alt="" @click="delPage(key)">
        </div>
      </div>
          
      <div class="add">
        <div class="add-btn" @click="addPage">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>

      <div class="line">Document(s)</div>
      <div v-for="(its, idx) in ruleForm.documents" :key="idx + '-doc'" class="doc">
        <img class="close" src="@/assets/images/close.png" alt="" @click="remove(idx)">

        <el-form-item label="Title - English" :prop="`documents[${idx}].title_en`">
          <el-input v-model="its.title_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Title - Traditional Chinese" :prop="`documents[${idx}].title_zhHant`">
          <el-input v-model="its.title_zhHant" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Title - Simplified Chinese" :prop="`documents[${idx}].title_zhCn`">
          <el-input v-model="its.title_zhCn" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - English" :prop="`documents[${idx}].subtitle_en`">
          <el-input v-model="its.subtitle_en" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - Traditional Chinese" :prop="`documents[${idx}].subtitle_zhHant`">
          <el-input v-model="its.subtitle_zhHant" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Subtitle - Simplified Chinese" :prop="`documents[${idx}].subtitle_zhCn`">
          <el-input v-model="its.subtitle_zhCn" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-radio-group v-model="its.type">
            <el-radio :label="0">PDF</el-radio>
            <el-radio :label="1">External Link</el-radio>
            <el-radio v-model="its.type" :label="2">Photo</el-radio>
            <el-radio v-model="its.type" :label="3">Video</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="group">
          <el-form-item label="Link - English" :prop="`documents[${idx}].link_en`">
            <edit-div v-model="its.link_en"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Traditional Chinese" :prop="`documents[${idx}].link_zhHant`">
            <edit-div v-model="its.link_zhHant"></edit-div>
          </el-form-item>
          <el-form-item label="Link - Simplified Chinese" :prop="`documents[${idx}].link_zhCn`">
            <edit-div v-model="its.link_zhCn"></edit-div>
          </el-form-item>
        </div>

        <el-form-item label="Sorting Order">
          <el-input v-model.number="its.sorting_order" autocomplete="off"></el-input>
        </el-form-item>
      </div>

      <div class="add">
        <div class="add-btn" @click="add">
          <img src="@/assets/images/add.png" alt="">
          <span>Add</span>
        </div>
      </div>
    </el-form>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      ruleForm: {},
      subpages: [],
      rules: {
        title_en: [
          { required: true, message: 'Title English can not be empty'}
        ],
        title_zhHant: [
          { required: true, message: 'Title Traditional Chinese can not be empty'}
        ],
        title_zhCn: [
          { required: true, message: 'Title Simplified Chinese can not be empty'}
        ],
        'documents[0].title_en': [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ],
        'documents[0].title_zhHant': [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ],
        'documents[0].title_zhCn': [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ],
        // 'documents[0].subtitle_en': [
        //   { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        // ],
        // 'documents[0].subtitle_zhHant': [
        //   { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        // ],
        // 'documents[0].subtitle_zhCn': [
        //   { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        // ],
        'documents[0].link_en': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
        'documents[0].link_zhHant': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
        'documents[0].link_zhCn': [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ],
      },
      pageId: null,

    }
  },
  computed: {
    ...mapState(['TempOSHpage'])
  },
  methods: {
    add() {
      this.ruleForm.documents.push({
        title_en: '',
        title_zhHant: '',
        title_zhCn: '',
        subtitle_en: '',
        subtitle_zhHant: '',
        subtitle_zhCn: '',
        type: 0,
        link_en: '',
        link_zhHant: '',
        link_zhCn: '',
        sorting_order: 0
      })

      this.verifyLink()
    },
    verifyLink() {
      let leng = this.ruleForm.documents.length
      for(let i = 0; i < leng; i++) {
        this.rules[`documents[${i}].title_en`] = [
          { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        ]
        this.rules[`documents[${i}].title_zhHant`] = [
          { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        ]
        this.rules[`documents[${i}].title_zhCn`] = [
          { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        ]
        // this.rules[`documents[${i}].subtitle_en`] = [
        //   { required: true, message: 'Title English can not be empty', trigger: 'blur'}
        // ]
        // this.rules[`documents[${i}].subtitle_zhHant`] = [
        //   { required: true, message: 'Title Traditional Chinese can not be empty', trigger: 'blur'}
        // ]
        // this.rules[`documents[${i}].subtitle_zhCn`] = [
        //   { required: true, message: 'Title Simplified Chinese can not be empty', trigger: 'blur'}
        // ]

        this.rules[`documents[${i}].link_en`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
        this.rules[`documents[${i}].link_zhHant`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
        this.rules[`documents[${i}].link_zhCn`] = [
          {type: 'url', message: `Please enter the correct one Link`, trigger: 'blur'},
          // { required: true, message: 'Please enter Link', trigger: 'blur' },
        ]
      }
    },
    remove(idx) {
      let formArr = this.ruleForm.documents
      formArr.splice(idx, 1)
    },
    addPage() {
      this.$emit('tabPage', 'folder', this.ruleForm)
      // this.$router.push(`/template/osh/folderpage/${this.pageId}/edit`)
    },
    editPage(idx) {
      this.$emit('tabPage', 'folder', this.ruleForm, idx)
      // this.$router.push(`/template/osh/folderpage/${this.pageId}/edit?id=${row.id}`)
    },
    delPage(idx) {
      let subpages = this.ruleForm.sub_pages
      subpages.splice(idx, 1)
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId
    this.ruleForm = JSON.parse(JSON.stringify(this.form))
    console.log(this.ruleForm)
    console.log(this.TempOSHpage)
  }
}
</script>

<style lang="scss" scoped>
.doc {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  margin-bottom: 10px;
  position: relative;

  .close {
    width: 28px;
    height: auto;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & > * {
    width: 32%;
  }
}

.add {
  width: 100%;
  margin: 30px 0 40px 0;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}
.pages {
  width: 100%;
  height: 80px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  box-sizing: border-box;
  font-size: 18px;
  color: #000000;
  background-color: #F5F5F5;
  position: relative;

  .text {
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: auto;
    }

    .arrow-left {
      margin-right: 10px;
    }

    .arrow-right {
      margin-left: 10px;
    }
  }

  .operation {
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: auto;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>