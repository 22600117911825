<template>
  <div class="new-section-title">
    <Header-Back :hideBack="true" pageTitle="New Section Title">
      <el-button type="primary"> <img src="@/assets/images/export.png" alt=""> Export </el-button> 
      <el-button type="primary" @click="$router.push('/overview/secondRowSection/newSectionTitle/add')"> <img src="@/assets/images/charm_cross.png" alt=""> New </el-button> 
    </Header-Back>

    <div class="search-func">
      <div class="search">
        <div class="label">Search</div>
        <div class="value">
          <el-input prefix-icon="el-icon-search" v-model="keyword" placeholder="Keywords"></el-input>
        </div>
      </div>
      <div class="date">
        <div class="label">Date</div>
        <div class="value">
          <el-date-picker v-model="date" type="date" style="width: 100%"></el-date-picker>
        </div>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" style="width: 100%" empty-text="No record">
        <el-table-column :prop="its.prop" :label="its.label" :width="its.width" 
          v-for="(its, idx) in tableCate" :key="idx">
        </el-table-column>
        <el-table-column label="Preview" width="100">
          <!-- slot-scope="scope" -->
          <template >
            <img src="@/assets/images/preview.png" class="table-icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="Edit" width="100">
          <!-- slot-scope="scope" -->
          <template >
            <img src="@/assets/images/Edit - large.png" class="table-icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="Delete" width="100">
          <!-- slot-scope="scope" -->
          <template >
            <img src="@/assets/images/mingcute_delete-2-fill.png" class="table-icon" alt="">
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="line">Disclaimer</div>

    <div class="box" v-for="(key, index) in Object.keys(form)" :key="index">
      <div class="label">{{ key }}</div>
      <div class="value">
        <el-input type="textarea" v-model="form[key]" rows="10"></el-input>
      </div>
    </div>

    <el-button type="primary"> Save </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '', 
      date: '',

      tableCate: [
        {label: 'ID', prop: 'id', width: '100'},
        {label: 'Title', prop: 'title', width: ''},
        {label: 'Date', prop: 'date', width: '140'},
        {label: 'Push to user', prop: 'user', width: '150'},
      ],
      tableData: [],

      form: {
        English: `This Work Safety Alert (“the Alert”) is issued at the earliest possible opportunity after a serious accident with a view to drawing the attention of interested parties to the general safety precautionary measures necessary to protect people engaging in similar work activities. The material contained in the Alert constitutes general guidance only. It does not reduce, limit, or replace, any legal obligations upon any person to comply with any statutory duties under relevant legislation. Users such as Managers and Supervisors should make their own evaluation of the information contained in the Alert to determine if it can be applied to their own situations and practices. The Labour Department does NOT accept any responsibilities for any loss or damage resulting from the use of or failure to use of the information on the Alert.\r\n\r\nNote: The material contained in the Alert is not exhaustive, and will be supplemented/ adjusted where necessary if more relevant information comes to light.`,
        Ttraditional: `本職安警示旨在發生嚴重意外後，盡早提醒有關人士採取所需的一般安全預防措施， 以保障從事類似工作人員的安全。本警示內的資料只屬一般指引，不會減輕、限制或取 代任何人須依法履行法定職責的法律責任。資料使用人如管理及督導人員應自行評估 本警示內的資料，按本身情況決定有關資料是否可在作業中應用。如因使用或不使用 本警示內的資料而招致任何損失或損害，勞工處概 不負責。`,
        Simplified: `本職安警示旨在發生嚴重意外後，盡早提醒有關人士採取所需的一般安全預防措施， 以保障從事類似工作人員的安全。本警示內的資料只屬一般指引，不會減輕、限制或取 代任何人須依法履行法定職責的法律責任。資料使用人如管理及督導人員應自行評估 本警示內的資料，按本身情況決定有關資料是否可在作業中應用。如因使用或不使用 本警示內的資料而招致任何損失或損害，勞工處概 不負責。`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-func {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .search {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .date {
    width: 35%;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}

.table {
  width: 100%;
  // height: 400px;
}

.box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;  

  .label {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
  }
}
</style>