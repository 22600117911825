<template>
  <div>
    <Header-Back pageTitle="Edit - Second Row Section"></Header-Back>

    <div class="single" v-for="(item, index) in list" :key="index">
      <div class="left">
        <div class="page-list-img">
          <img :src="item.icon" alt="" />
          <img class="page-list-img-edit" src="@/assets/images/edit.png">
        </div>
      </div>
      <div class="center">
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="5" class="page-list-label">Title</el-col>
          <el-col><el-input v-model="item.title" placeholder="Link"></el-input
          ></el-col>
        </el-row>
        <el-row type="flex" align="center" class="page-list-row" :gutter="20">
          <el-col :span="10" class="page-list-label">Navigate to</el-col>
          <el-col>
            <el-select v-model="value" placeholder="- Internal page -">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-col>
          <el-col>
            <el-input v-model="input" placeholder="Link"></el-input
          ></el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="5" class="page-list-label"></el-col>
          <el-col>
              <el-button type="info">Disable</el-button>
              <el-button type="danger">Delete</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right page-list-button">
        <div class="order">
          <img src="@/assets/images/order_up.png" alt="" @click="order('up', index)">
          <img src="@/assets/images/order_down.png" alt="" @click="order('down', index)">
        </div>
      </div>
    </div>

    <div class="add">
      <div class="add-btn" @click="add">
        <img src="@/assets/images/add.png" alt="">
        <span>Add</span>
      </div>
    </div>

    <el-button type="primary">Save</el-button> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { 
          title: 'Work Safety Alert',
          icon: require('@/assets/images/Frame473.png'),
        },
        { 
          title: 'Online OSH Complaint Form',
          icon: require('@/assets/images/icons.png'),
        },
        { 
          title: 'Important Announcement',
          icon: require('@/assets/images/icons(1).png'),
        },
        { 
          title: 'Notification of Construction Work',
          icon: require('@/assets/images/icons(2).png'),
        },
      ]
    }
  },
  methods: {
    add() {
      this.list.push({ 
        title: 'Work Safety Alert',
        icon: require('@/assets/images/Frame473.png'),
      })
    },
    order(type, idx) {
      let { list } = this,
      leng = list.length
      
      if(type == 'up' && idx != 0) {
        [list[idx], list[idx - 1]] = [list[idx - 1], list[idx]];
      } else if(type == 'down' && idx != leng - 1) {
        [list[idx], list[idx + 1]] = [list[idx + 1], list[idx]];
      }

      this.list = JSON.parse(JSON.stringify(list))
    }
  }
};
</script>

<style lang="scss" scoped>
.single {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  $bl: 4.16%;
  
  .left {
    width: $bl * 3;
    height: 100%;
  }

  .center {
    width: $bl * 16;
    height: 100%;
  }

  .right {
    width: $bl * 3;
    height: 100%;
    display: flex;

    .order {
      width: 100%;
      display: flex;
      justify-content: space-around;

      img {
        width: 48px;
        height: auto;
      }
    }
  }
}

.add {
  width: 100%;
  margin-bottom: 40px;

  .add-btn {
    width: 120px;
    height: 44px;
    border: 1px solid #1A74B0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;

    img {
      width: 24px;
      height: auto;
      margin-right: 10px;
    }
  }
}

.page-list {
  padding: 20px;
  background: #f5f5f5;
  margin-bottom: 14px;
}
.page-list-img {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #ebebeb;
}
.page-list-button {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.page-list-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.page-list-label {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
   align-items: center;
  display: flex;
}
.page-list-row {
  margin-bottom: 18px;
}
.page-list-img-edit{
  position: absolute;
  bottom: 0px;
  right: -10px;
}
</style>