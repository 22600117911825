<template>
  <div class="ImportAnnoun">
    <Header-Back :hideBack="true" :pageTitle="pageTitle">
      <el-button type="primary" @click="exportData"> <img src="@/assets/images/export.png" alt=""> Export </el-button> 
      <el-button type="primary" @click="add"> <img src="@/assets/images/charm_cross.png" alt=""> New </el-button> 
    </Header-Back>

    <div class="search-func">
      <div class="search">
        <div class="label">Search</div>
        <div class="value">
          <el-input prefix-icon="el-icon-search" v-model="listQ.like_keywords" @input="getData" placeholder="Keywords"></el-input>
        </div>
      </div>
      <div class="date">
        <div class="label">Date</div>
        <div class="value">
          <el-date-picker v-model="listQ.date" type="date" style="width: 100%" @change="getData" placeholder="- Please select -"></el-date-picker>
        </div>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" style="width: 100%" empty-text="No record">
        <el-table-column :prop="its.prop" :label="its.label" :width="its.width" 
          v-for="(its, idx) in tableCate" :key="idx">
        </el-table-column>
        <el-table-column label="Preview" width="100">
          <template slot-scope="scope">
            <img src="@/assets/images/preview.png" @click="preview(scope.row)" class="table-icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="Edit" width="100">
          <template slot-scope="scope">
            <img src="@/assets/images/Edit - large.png" @click="edit(scope.row)" class="table-icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="Delete" width="100">
          <template slot-scope="scope">
            <img src="@/assets/images/mingcute_delete-2-fill.png" @click="del(scope.row.id)" class="table-icon" alt="">
          </template>
        </el-table-column>
      </el-table>
    </div>

    <Pagination
      :total="total"
      :autoScroll='false'
      :page.sync="listQ._start"
      :limit.sync="listQ._limit"
      layout="total, prev, pager, next"
      @pagination="getData"
    />
  </div>
</template>

<script>
import { getImportantAnnouncements, deleteImportantAnnouncements, getExportImportantAnnouncementsDetail } from '@/api/material'
import {getPagesDetail} from '@/api/pages'
import exportExcel from './exportData'

export default {
  data() {
    return {
      tableCate: [
        {label: 'ID', prop: 'id', width: '100'},
        {label: 'Title', prop: 'title_en', width: ''},
        {label: 'Date', prop: 'date', width: '140'},
        {label: 'Push to user', prop: 'isPushToUser', width: '150'},
      ],
      tableData: [],
      
      total: 0,
      listQ:{
        page_id: this.$route.params.id,
        like_keywords: '',
        date: '',
        _start: 1,
        _limit: 20, 
      },
      pageId: null,
      pageTitle: 'Important Announcement'
    }
  },
  watch: {
    $route() {
      this.pageId = this.$route.params.pageId
      this.listQ = {
        page_id: this.pageId,
        like_keywords: '',
        date: '',
        _start: 1,
        _limit: 20,
      }

      this.getData()
    }
  },
  methods: {
    exportData() {
      let obj = {
        page_id: this.pageId,
        _start: this.listQ._start - 1,
        like_keywords: '',
        date: '',
        // _limit: this.listQ._limit
        _limit: 99999
      }

      getExportImportantAnnouncementsDetail(obj)
        .then(({data}) => {
          exportExcel(data)
        })
    },
    add() {
      this.$router.push(`/template/ia/add/${this.pageId}`)
    },
    preview(row) {
      this.$router.push(`/template/ia/${this.pageId}/${row.id}/preview`)
    },
    edit(row) {
      this.$router.push(`/template/ia/${this.pageId}/${row.id}/edit`)
    },
    getIA() {

    },
    del(id) {
      //彈窗
      this.$confirm('Confirm to delete?', '', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //刪除
        deleteImportantAnnouncements(id).then(() => {
          this.$message({ type: 'success', message: 'Successfully deleted!' })
          this.getData()
        })
      })
    },
    getPagesDetail(){
      let id = this.$route.params.id
      getPagesDetail(id).then((re)=>{
        this.pageTitle = re.data.path
      }) 
    },
    getData() {
      let sectionName = this.$route.query.sectionName || ''
      this.pageTitle = sectionName
      let {listQ} = this, 
      form = {
        page_id: this.pageId,
        like_keywords: listQ.like_keywords,
        date: listQ.date ? new Date(listQ.date).format('yyyy-MM-dd') : '',
        _start: listQ._start - 1,
        _limit: listQ._limit,
      }; 

      getImportantAnnouncements(form).then(v => {
        v.data.filter(its => {
          its.title_en = its.title.en
          its.title_zhHant = its.title.zhHant
          its.title_zhCn = its.title.zhCn
          its.isPushToUser = its.isPushToUser ? 'Yes' : 'No'
        })
        this.tableData = v.data
        this.total = Number(v.headers['x-total-count'])
      })
    }
  },
  mounted() {
    this.pageId = this.$route.params.pageId


    // this.getPagesDetail()
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.search-func {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .search {
    width: 65%;
    box-sizing: border-box;
    padding-right: 20px;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .date {
    width: 35%;

    .label {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      margin-bottom: 10px;
    }
  }
}
</style>