<template>
  <div>
    <Header-Back pageTitle="Edit - First Row Section"></Header-Back>

    <el-row type="flex" :gutter="8" class="page-list"
      align="center" justify="space-between"
      v-for="(item, index) in list" :key="index"
    >
      <el-col :span="3">
        <!--  @click="update(item)" -->
        <div class="page-list-img">
          <img :src="item.iconUrl"  alt="" />
        </div>
      </el-col>
      <el-col :span="16" class="page-list-content">
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - English</el-col>
          <el-col>{{item.title.en}}</el-col>
        </el-row>
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - Traditional Chinese </el-col>
          <el-col>
            <el-col>{{item.title.zhHant}}</el-col>
            <!-- <el-input v-model="item.title.zhHant" ></el-input> -->
          </el-col>
        </el-row>
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - Simplified Chinese</el-col>
          <el-col>
            <el-col>{{item.title.zhCn}}</el-col>
            <!-- <el-input v-model="item.title.zhCn"></el-input> -->
          </el-col>
        </el-row>
        <el-row type="flex" align="top" :gutter="20">
          <el-col :span="18" class="page-list-label">Navigate to</el-col>
          <el-col>
            <el-select v-model="item.pages" multiple @change="v => changeTemplate(item, v)"
             :disabled="item.en != '' || item.zhHant != '' || item.zhCn != ''" placeholder="- Internal page -">
              <el-option :disabled="its.disable"
                v-for="(its, idx) in item.tempPages" :key="idx"
                :label="its.path" :value="its.id">
              </el-option> 
            </el-select>
            <el-input v-model="item.en" :disabled="item.pages.length != 0" class="select" placeholder="Link - English"></el-input>
            <el-input v-model="item.zhHant" :disabled="item.pages.length != 0" class="select" placeholder="Link - Traditional Chinese"></el-input>
            <el-input v-model="item.zhCn" :disabled="item.pages.length != 0" class="select" placeholder="Link - Simplified Chinese"></el-input>
          </el-col>
          <el-col></el-col>
        </el-row>
      </el-col>
      <el-col type="flex" :span="4" class="page-list-button">
        <el-button type="primary" @click="isDisable(item)" v-if="item.status == 1">Enable</el-button>
        <el-button type="info" @click="isDisable(item)" v-else>Disable</el-button>
      </el-col>
    </el-row>
    <el-button type="primary" @click="save">Save</el-button>
  </div>
</template>

<script>
import { disableSections, enableSections, editSections } from '@/api/section.js'
import { mapState } from 'vuex'
import { updateFile } from '@/utils/upload'

export default {
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState(['Pages', 'Section']),
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
  },
  watch: {
    Section: function() {
      this.getData()
    }
  },
  methods: {
    update(item) {
      let suffix = [], 
      area = { width: 50, height: 50 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item.iconUrl = res
      })
    },
    updateTempPages(testData) {
      let pages = JSON.parse(JSON.stringify(this.Pages)).map(its => ({...its, disable: false})), 
      [id] = testData

      if(id == undefined) return pages
      let [filterChoice] = pages.filter(its => its.id == id && its)
      if(filterChoice != undefined && filterChoice.type == 0) {
        pages.map(its => {
          if(its.id != id && its.id != (id + 1))
            its.disable = true
        })
      } else if(filterChoice != undefined && filterChoice.type == 1) {
        pages.map(its => {
          if(its.id != id && its.id != (id - 1))
            its.disable = true
        })
      } else {
        pages.map(its => its.id != id && (its.disable = true))
      }
      
      return pages
    },
    changeTemplate(row, v) {
      row.tempPages = this.updateTempPages(v)
    },
    isDisable(its) {
      let {status, id} = its

      if(id == '0') {
        its.status = status == 0 ? 1 : 0
      } else {
        if(status == 0) {
          disableSections(id).then(() => {
            this.getSection()
          })
        } else {
          enableSections(id).then(() => {
            this.getSection()
          })
        }
      }
    },
    save() {
      let obj = this.list,
      newArr = []
      console.log(obj)
      obj.filter((its, ) => {
        newArr.push({
          id: its.id,
          "icon_url": its.iconUrl,
          "title": {
            "en": its.title.en,
            "zh_hant": its.title.zhHant,
            "zh_cn": its.title.zhCn
          },
          "page_ids": its.pages,
          "link": {
            "en": its.en,
            "zh_hant": its.zhHant,
            "zh_cn": its.zhCn
          },
          sort: its.sort,
          status: its.status
        })
      })

      editSections(newArr).then(() => {
        this.$router.go(-1)
      })
    },
    getData() {
      let data = JSON.parse(JSON.stringify(this.Section))
      console.log(data)

      data.filter(its => {
        its.pages = its.pages.map(i => i.id)
        its.tempPages = this.updateTempPages(its.pages)
        its.en = its.link.en
        its.zhHant = its.link.zhHant
        its.zhCn = its.link.zhCn
      })
      let list = data.filter(its => its.displayType == 0 && its)
      // list.filter(its => {
      //   if(its.title.zhHant == '重要公告') its.sort = 3
      //   else if(its.title.zhHant == '職安警示') its.sort = 1
      //   else if(its.title.zhHant == '網上職安健投訴表格') its.sort = 2
      //   else if(its.title.zhHant == '建築工程的呈報') its.sort = 4
      // })
      this.list = list
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.page-list {
  padding: 20px;
  background: #f5f5f5;
  margin-bottom: 14px;
}
.page-list-img {
  display: flex;
  width: 100px;
  height: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #ebebeb;

  img {
    width: 100% ;
    height: 100%;
  }
}
.page-list-button {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.page-list-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.page-list-label {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
   align-items: center;
  display: flex;
}
.page-list-row {
  margin-bottom: 18px;
}
</style>