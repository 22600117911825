<template>
  <div>
      <Header-Back :hideBack="true" pageTitle="Preview">
       <span class="last-lint">Last Updated 2023/12/24 23:11:26</span>
    </Header-Back> 
    <el-form ref="form" label-position="top"  label-width="80px">
      <div class="column-title">Title</div>
      <el-form-item label="English">
        <div v-if="form.title">{{form.title.en}}</div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
       <div v-if="form.title">{{form.title.zhHant}}</div>
      </el-form-item>

      <el-form-item label="Simplified Chinese">
         <div v-if="form.title">{{form.title.zhCn}}</div>
      </el-form-item>
      <el-divider></el-divider>
      <div class="column-title">Message</div>
      <el-form-item label="English">
        <div v-if="form.title">{{form.message.zhCn}}</div>
      </el-form-item>
      <el-form-item label="Traditional Chinese">
        <div v-if="form.title">{{form.message.zhCn}}</div>
      </el-form-item>

      <el-form-item label="Simplified Chinese">
         <div v-if="form.title">{{form.message.zhCn}}</div>
      </el-form-item>

      <div class="page-button">
        <el-button @click="onMoreDetails" type="success">More Details</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {getPushNotificationsDetail} from '@/api/pushNotification'
export default { 
  props: {
    id:{
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {}
    }
  },
  methods:{
    getPushNotificationsDetail(){
      getPushNotificationsDetail(this.id).then(res=>{
         this.form = res.data
      })
    },
    onMoreDetails(){}
  }
};
</script>

<style lang="scss" scoped>
.div-item {
  padding: 30px;
  background: #f5f5f5;
  margin-top: 20px;
  
}
.page-button{
    margin-top: 60px;
}

</style>