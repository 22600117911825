<template>
  <div>
    <Header-Back pageTitle="Edit - Second Row Section"></Header-Back>

    <div class="single" v-for="(item, index) in list" :key="index">
      <div class="left">
        <div class="page-list-img">
          <img :src="item.iconUrl" v-if="item.iconUrl" class="img" alt="" />
          <div class="img" v-else></div>
          <img @click="update(item, )" class="page-list-img-edit" src="@/assets/images/edit.png">
        </div>
      </div>
      <div class="center">
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - English</el-col>
          <el-col><el-input v-model="item.title.en" placeholder="Title - English"></el-input
          ></el-col>
        </el-row>
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - Traditional Chinese </el-col>
          <el-col><el-input v-model="item.title.zhHant" placeholder="Title - Traditional Chinese"></el-input
          ></el-col>
        </el-row>
        <el-row type="flex" align="center" :gutter="20" class="page-list-row">
          <el-col :span="9" class="page-list-label">Title - Simplified Chinese</el-col>
          <el-col><el-input v-model="item.title.zhCn" placeholder="Title - Simplified Chinese"></el-input
          ></el-col>
        </el-row>
        <el-row type="flex" align="top" class="page-list-row" :gutter="20">
          <el-col :span="18" class="page-list-label">Navigate to</el-col>
          <el-col>
            <el-select v-model="item.pages" multiple @change="v => changeTemplate(item, v)"
             :disabled="item.type == 'link'" placeholder="- Internal page -" style="width: 100%">
              <el-option :disabled="its.disable"
                v-for="(its) in item.tempPages" :key="its.id"
                :label="its.path" :value="its.id">
              </el-option> 
            </el-select>
            <el-input v-model="item.en" :disabled="item.pages.length != 0" class="select" placeholder="Link - English"></el-input>
            <el-input v-model="item.zhHant" :disabled="item.pages.length != 0" class="select" placeholder="Link - Traditional Chinese"></el-input>
            <el-input v-model="item.zhCn" :disabled="item.pages.length != 0" class="select" placeholder="Link - Simplified Chinese"></el-input>
          </el-col>
          <el-col></el-col>
        </el-row>
        
        <el-row type="flex" align="top" :gutter="20" class="page-list-row" v-if="item.backShow">
          <el-col :span="9" class="page-list-label">Background Image</el-col>
          <el-col>
            <div class="bgimg">
              <div class="img">
                <img :src="item.backgroundUrl" v-if="item.backgroundUrl" alt="">
              </div>
              <div class="logo-info">
                <span>Type: PNG, JPG </span>
                <span>Maximum file size:  5MB</span>
                <span>Dimension: at least 200px(H) x 112px(W)</span>
                <!--  v-if="ruleForm.img" -->
                <el-button type="primary" @click="isUpdateFile(item)">Browse</el-button>
              </div>

            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20">
          <el-col :span="9" class="page-list-label"></el-col>
          <el-col>
            <el-button type="primary" @click="isDisable(item)" v-if="item.status == 1">Enable</el-button>
            <el-button type="info" @click="isDisable(item)" v-else>Disable</el-button>
            <el-button type="danger" @click="deleteSection(item, index)">Delete</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="right page-list-button">
        <div class="order">
          <img src="@/assets/images/order_up.png" alt="" @click="order('up', index)">
          <img src="@/assets/images/order_down.png" alt="" @click="order('down', index)">
        </div>
      </div>
    </div>

    <div @click.self="openAddBtn" class="btn-default"> 
      <img src="@/assets/images/add.png" alt=""> New 
      <div class="add-child" tabindex="1" @blur="add_switch = false" v-if="add_switch">
        <span @click="$router.push('/overview/secondRowSection/addNewSection')">New Section</span>
        <span @click="addLink">External Link</span>
      </div>
    </div>

    <el-button type="primary" @click="save">Save</el-button> 
  </div>
</template>

<script>
import { deleteSections, disableSections, enableSections, editSections } from '@/api/section.js'
import { updateFile } from '@/utils/upload'
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      list: [],

      add_switch: false,
    }
  },
  computed: {
    ...mapState(['Pages', 'Section', 'user', 'Template'])
  },
  watch: {
    Section: function() {
      this.getData()
    }
  },
  methods: {
    ...mapMutations(['setSection']),
    ifSectionType(item) {
      if(item.templateType == undefined) return false

      let oshId, nywId
      this.Template.filter(its => {
        if(its.title == 'Osh Training') oshId = its.type
        else if(its.title == 'Notify Your Workplace-Text Version') nywId = its.type
      })
      
      if(item.templateType == oshId) {
        return 'osh';
      } else if(item.templateType == nywId) {
        return 'nyw';
      }
      
      return false
    },
    isUpdateFile(item) {
      let suffix = ['image/png', 'image/jpg', 'image/jpeg'], 
      area = { width: 112, height: 200 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item.backgroundUrl = res
        console.log(item)
      })
    },
    update(item) {
      let suffix = [], 
      area = { width: 50, height: 50 }, 
      maxSize = 5
      updateFile(suffix, area, maxSize, res => {
        item.iconUrl = res
      })
    },
    updateTempPages(testData) {
      let pages = JSON.parse(JSON.stringify(this.Pages)).map(its => ({...its, disable: false})), 
      [id] = testData

      if(id == undefined) return pages
      let [filterChoice] = pages.filter(its => its.id == id && its)
      if(filterChoice != undefined && filterChoice.type == 0) {
        pages.map(its => {
          if(its.id != id && its.id != (id + 1))
            its.disable = true
        })
      } else if(filterChoice != undefined && filterChoice.type == 1) {
        pages.map(its => {
          if(its.id != id && its.id != (id - 1))
            its.disable = true
        })
      } else {
        pages.map(its => its.id != id && (its.disable = true))
      }
      
      return pages
    },
    changeTemplate(its, v) {
      its.tempPages = this.updateTempPages(v)
      if(its.pages.length == 0) {
        its.backShow = false
        return 
      }
      its.templateType = this.Pages.filter(item => item.id == its.pages[0] && item)[0].type

      if(this.ifSectionType(its) == 'osh') {
        its.backShow = true
        its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
      } else if(this.ifSectionType(its) == 'nyw') {
        its.backShow = true
        its.defaultBgimg = require('../../../assets/images/section_nyw_bg.jpg')
      }
    },
    openAddBtn() {
      this.add_switch = true
      this.$nextTick(() => {
        let addBtn = document.querySelector('.add-child')
        addBtn.focus()
      })
    },
    deleteSection(its, idx) {
      let id = its.id
      if(id == '0') {
        this.list.splice(idx, 1)
      } else {
        deleteSections(id).then(() => {
          this.list.splice(idx, 1)
          this.getSection()
        })
      }
    },
    isDisable(its) {
      let {status, id} = its

      if(id == '0') {
        its.status = status == 0 ? 1 : 0
      } else {
        if(status == 0) {
          disableSections(id).then(() => {
            console.log('0')
            this.getSection()
          })
        } else {
          enableSections(id).then(() => {
            console.log('1')
            this.getSection()
          })
        }
      }
    },
    save() {
      let obj = this.list,
      newArr = []
      console.log(obj)
      obj.filter((its, idx) => {
        newArr.push({
          id: its.id,
          "icon_url": its.iconUrl,
          "title": {
            "en": its.title.en,
            "zh_hant": its.title.zhHant,
            "zh_cn": its.title.zhCn
          },
          "page_ids": its.pages,
          "link": {
            "en": its.en,
            "zh_hant": its.zhHant,
            "zh_cn": its.zhCn
          },
          background_url: this.ifSectionType(its) ? its.backgroundUrl : '',
          sort: idx + 1,
          status: its.status
        })
      })
      
      console.log(newArr)
      // if(obj.bjja)
      editSections(newArr).then(() => {
        this.getSection()
        // 更新管理员权限
        this.getAdminRole()
        this.$router.go(-1)
      })
    },
    addLink() {
      this.list.push({ 
        id: '0', 
        title: {en: '', zhHant: '', zhCn: ''},
        iconUrl: '',
        "pages": [],
        type: 'link',
        en: '',
        zhHant: '',
        zhCn: '',
        "status": 0
      })
    },

    order(type, idx) {
      let { list } = this,
      leng = list.length
      
      if(type == 'up' && idx != 0) {
        [list[idx], list[idx - 1]] = [list[idx - 1], list[idx]];
      } else if(type == 'down' && idx != leng - 1) {
        [list[idx], list[idx + 1]] = [list[idx + 1], list[idx]];
      }

      this.list = JSON.parse(JSON.stringify(list))
    },
    getData() {
      let data = JSON.parse(JSON.stringify(this.Section))
      data.filter(its => {
        its.tempPages = []
        if(its.pages.length > 0){
          its.templateType = its.pages.map(i => i)[0].type
          its.pages = its.pages.map(i => i.id)
          its.type = 'section'
          its.tempPages = this.updateTempPages(its.pages)
        }
        else its.type = 'link'
        its.en = its.link.en
        its.zhHant = its.link.zhHant
        its.zhCn = its.link.zhCn

        if(this.ifSectionType(its) == 'osh') {
          its.backShow = true
          its.defaultBgimg = require('../../../assets/images/section_osh_bg.jpg')
        } else if(this.ifSectionType(its) == 'nyw') {
          its.backShow = true
          its.defaultBgimg = require('../../../assets/images/section_nyw_bg.jpg')
        }
      })
      this.list = data.filter(its => its.displayType == 1 && its)
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<style lang="scss" scoped>
.single {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;

  $bl: 4.16%;
  .left {
    width: $bl * 3;
    height: 100%;
  }

  .center {
    width: $bl * 21;
    height: 100%;
  }

  .right {
    width: $bl * 3;
    height: 100%;
    display: flex;

    .order {
      width: 100%;
      display: flex;
      justify-content: space-around;

      img {
        width: 48px;
        height: auto;
      }
    }
  }

}

.btn-default {
  position: relative;
  width: 122px;
  height: 44px;
  padding: 5px 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  border: 1px solid #1A74B0;
  cursor: pointer;
  margin-bottom: 40px;

  img {
    width: auto;
    height: 20px;
    margin-right: 5px;
    vertical-align: sub;
  }

  .add-child {
    position: absolute;
    top: 0;
    left: calc(100% + 20px);
    padding: 20px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 44px;
      background-color: #1A74B0;
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
    }

    span:nth-of-type(1) {
      margin-bottom: 20px;
    }
  }
}

.page-list {
  padding: 20px;
  background: #f5f5f5;
  margin-bottom: 14px;
}
.page-list-img {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #ebebeb; 

  .img {
    width: 100%;
    height: 100%;
  }
}
.page-list-button {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.page-list-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.page-list-label {
  color: #000;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  align-items: center;
  display: flex;

}
.bgimg {
  width: 100%;
  display: flex;
  align-items: center;

  .img {
    width: 112px;
    height: 200px;
    border: 1px solid #6D6D6D;
    background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .logo-info {
    flex: 1;
    padding-left: 1.25rem;
    color: #000;
    font-size: 1.125rem;
    line-height: 1.25rem;

    span {
      display: block;
      margin-bottom: .625rem;
    }
  }
}
.page-list-row {
  margin-bottom: 18px;
}
.page-list-img-edit{
  position: absolute;
  bottom: 0px;
  right: -10px;
}
</style>