import http from '@/utils/http'

export function getOperationHistories(params){
    return http.get('/operation_histories',{params})
}
export function startLinkChecks(){
    return http.post('/start_link_checks')
}
export function getLinkChecks(params){
    return http.get('/link_checks',{params})
}
